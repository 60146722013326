.foldable-section {
  &__main {
    overflow-y: hidden;

    height: 0;
    &_animated {
      transition: height 0.5s;
    }
  }
}
