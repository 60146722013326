@use "../../../utils.scss" as *;

.check-box {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 10px;

  cursor: pointer;
  user-select: none;

  &:last-child {
    margin-bottom: 0;
  }

  &__mark {
    width: 24px;
    height: 24px;

    border: 2px solid $gray-dark;
    border-radius: 4px;
    flex-shrink: 0;

    &-active {
      border: 2px solid $yellow-main;
    }

    &-inner {
      width: 100%;
      height: 100%;

      &_checked {
        background: url(~assets/images/checkbox.svg) center no-repeat;
      }
    }
  }
}
