.project-editing-title {
  display: flex;
  align-items: center;

  @media screen and (max-width: 766px) {
    width: 100%;
    flex-direction: column;
    gap: 16px;
  }

  &__button-save {
    margin-left: 8px;
    padding: 4px;
    width: 30px;
    height: 30px;
    background: url(~assets/images/save-edit.svg) center/cover no-repeat;
    border-radius: 4px;
    flex-shrink: 0;

    @media screen and (max-width: 766px) {
      display: none;
    }
  }

  .textinput {
    padding: 0 16px;
    width: 265px;
    height: 30px;
    border-radius: 4px;

    @media screen and (max-width: 766px) {
      width: 100%;
      min-height: 40px;
      background: rgba(249, 249, 251, 0.2);
      border-radius: 8px;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
    }
  }

  @media screen and (max-width: 766px) {
    .button {
      width: 100%;
      height: 54px;
    }

    &__buttons {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &__button-cancel {
      background-color: #F2F1ED;
      border: 1px solid #F2F1ED;
    }
  }
}
