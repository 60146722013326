@use "../../../../../utils.scss" as *;

.subtotal {
  max-width: 700px;
	width: 100%;
	margin-bottom: 24px;
	padding: 32px 40px;
	border-radius: 8px;
	background-color: $gray;

  &-bold {
    margin-bottom: 8px;
		font-size: 20px;
		font-weight: 700;
	}

	&-text {
		font-size: 20px;
		font-weight: 400;
		line-height: 140%;
	}
}

.blocks {
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.block1 {
  border-radius: 8px;
  padding: 24px 18px;
  max-width: 250px;
  width: 100%;
  height: 220px; 
  background: rgba(87, 192, 88, 0.1);
  text-align: center;
  display: flex;
  align-items: center;
}

.block2 {
  border-radius: 8px;
  padding: 24px 18px;
  width: fit-content;
  height: 220px; 
  background: rgba(234, 67, 53, 0.1);
  text-align: center;
  display: flex;
  align-items: center;
}