@use "../../../../../../../utils.scss" as *;

.phrase__tag {
  padding: 4px 13px;
  display: flex;
  align-items: center;
  background-color: $gray;
  border-radius: 5px;

  cursor: default;

  @media screen and (max-width: 766px) {
    padding: 4px 8px;
    height: 40px;
    font-weight: 400;
    border-radius: 7px;
  }

  &-title {
    margin-right: 8px;
    
    @media screen and (max-width: 766px) {
      font-size: 14px;
    }
  }
  

  &-close {
    width: 15px;
    height: 15px;
    background: url(~assets/images/tagclosegray.svg) center no-repeat;
    transition: all 0.4s;
    flex-shrink: 0;

    @media screen and (max-width: 766px) {
      padding: 14px;
      background: url(~assets/images/tagclosered.svg) center no-repeat;
    }

    &:hover {
      transform: scale(1.1);
    }
  }
}