@use "../../../../utils.scss" as *;

.settings-seo-step {
  padding: 24px 16px;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 32px;

  @media screen and (max-width: 766px) {
    padding: 32px 8px;
    border-radius: 16px;
  }

  &__container {
    padding: 32px 8px;
    width: 100%;

    @media screen and (max-width: 766px) {
      padding: 0;
      box-shadow: none;
    }
  }

  &__last {
    padding: 16px;

    @media screen and (max-width: 766px) {
      padding: 32px 8px 24px;
      background: $white;
      border-radius: 16px;
    }

    .settings-seo-last {
      max-width: 500px;
      width: 100%;
    }

    .avito-upload-file {
      &__success {
        gap: 8px;

        &-upload {
          max-width: none;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 40px;

    @media screen and (max-width: 766px) {
      flex-direction: column;
      gap: 8px;

      & p {
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 0.1px;
        color: $gray-dark;
      }
    }

    .avito-upload-file {
      margin-top: 0;
      width: 100%;

      &__success {
        @media screen and (max-width: 766px) {
          flex-direction: row;
        }

        &-upload {
          max-width: 475px;

          @media screen and (max-width: 766px) {
            padding: 16px;

            & p {
              font-size: 14px;
              font-weight: 700;
              color: $lightgreen;
            }
          }
        }
      }

      &__delete-button {
        max-width: 155px;

        @media screen and (max-width: 766px) {
          max-width: 100px !important;
        }
      }
    }
  }

  &__add-screen-button {
    background: #fffcee;
    border: 1px dashed $yellow-dark;

    @media screen and (max-width: 766px) {
      padding-left: 12px;
      padding-right: 12px;
      max-width: 450px;
      height: 40px;
      font-size: 14px;
      background: $yellow-sub;
      border: 1px solid $yellow-sub;
    }
  }

  &__create-project-button {
    max-width: 450px;
    width: 100%;

    @media screen and (max-width: 766px) {
      height: 40px;
      font-size: 14px;
    }
  }

  &__title {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 766px) {
      margin-bottom: 12px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }

    &-container {
      padding: 8px;
      display: flex;
      align-items: center;

      .toggler {
        width: 35px;
        height: 20px;
        flex-shrink: 0;

        @media screen and (max-width: 766px) {
          width: 40px;
          height: 24px;
        }

        &__thumb {
          width: 15px;
          height: 15px;

          @media screen and (max-width: 766px) {
            width: 16px;
            height: 16px;
          }
        }
      }
    }

    &.toggle-button {
      margin-bottom: 8px;
    }
  }

  &__label {
    margin-bottom: 0;
    display: flex;
    align-items: center;

    @media screen and (max-width: 766px) {
      font-size: 14px;
    }

    &::before {
      content: "";
      margin-right: 16px;
      min-width: 8px;
      width: 8px;
      height: 8px;
      display: block;
      background-color: $yellow-main;
      border-radius: 50%;

      @media screen and (max-width: 766px) {
        content: none;
      }
    }

    &.header-settings-seo {
      font-weight: 700;
      color: $light-black;

      @media screen and (max-width: 766px) {
        font-size: 16px;
      }

      &::before {
        content: none;
      }
    }

    &.toggle-button {
      margin-left: 16px;
      flex-direction: column;
      align-items: flex-start;

      @media screen and (max-width: 766px) {
        margin-left: 8px;
      }

      &::before {
        content: none;
      }

      & span {
        margin-top: 8px;
        font-size: 14px;
        font-weight: 400;
        line-height: 120%;
        color: $light-black;
      }
    }

    @media screen and (max-width: 766px) {
      &.settings-seo-last {
        font-size: 16px;
        text-align: center;
      }
    }
  }

  &__sublabel {
    font-size: 14px;
    font-weight: 300;
    color: $gray-dark;

    @media screen and (max-width: 766px) {
      font-size: 12px;
    }

    &.other-color {
      color: $light-black;

      @media screen and (max-width: 766px) {
        font-size: 14px;
        color: $gray-dark;
      }
    }

    & span {
      font-weight: 600;
    }

    @media screen and (max-width: 766px) {
      &.toggle-button {
        margin-bottom: 0;
        text-align: center;
      }

      &.settings-seo-city {
        margin-top: 8px;
      }
    }
  }
}
