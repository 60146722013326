@use "./assets/fonts/fonts.css" as *;
@use "./utils.scss" as *;

/* * {
  outline: 1px solid red;
} */

html {
  -webkit-text-size-adjust: none;
  text-size-adjust: none;

  width: 100vw;

  scroll-behavior: smooth;
}

body {
  margin: 0 auto;

  background-color: $gray;

  font-family: SF;
  font-weight: 400;
  color: $black;
  overflow-x: hidden;

  &.no-scroll {
    overflow: hidden;
  }
}

#root {
  min-width: 100dvw;
}

a,
button,
div,
h1,
h2,
h3,
header,
img,
input,
textarea,
p,
section {
  box-sizing: border-box;
}

h1,
h2,
h3,
p {
  margin: 0;
}

a {
  color: inherit;

  font-family: inherit;
  text-decoration: none;
}

button,
input {
  padding: 0;

  background: none;
  border: none;
  color: inherit;

  outline: none;

  font-family: inherit;
  font-size: inherit;
  -webkit-text-size-adjust: 100%;
}

button,
label {
  cursor: pointer;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

li {
  display: flex;
  column-gap: 8px;

  list-style-type: none;

  &::before {
    content: "";
    margin-top: 6px;
    min-width: 6px;
    width: 6px;
    height: 6px;

    background-color: $yellow-main;
    border-radius: 50%;
  }
}

[class*="__inner"] {
  margin: 0 auto;
  max-width: $screenminwidth;
  width: 100%;
}

input[type='text'],
input[type='number'],
input[type="tel"],
input[type="password"],
input[type="email"],
input[type="date"],
select:focus,
textarea {
  font-size: 16px;
}