@use "../../../../../utils.scss" as *;

.settings-seo-step-bots {
  padding: 32px 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media screen and (max-width: 766px) {
    padding: 32px 8px;
    border-radius: 16px;
    gap: 24px;
  }

  &__label {
    display: flex;
    align-items: center;

    @media screen and (max-width: 766px) {
      font-size: 14px;
    }

    &::before {
      content: "";
      margin-right: 16px;
      min-width: 8px;
      width: 8px;
      height: 8px;
      display: block;
      background-color: #ffe14b;
      border-radius: 50%;
    }
  }

  &__sublabel {
    font-size: 14px;
    font-weight: 300;
    color: #ababab;

    @media screen and (max-width: 766px) {
      font-size: 12px;
    }

    & span {
      font-weight: 600;
    }
  }

  &__mark {
    margin-top: 8px;
    font-size: 12px;
    line-height: 120%;
    color: #ababab;
  }

  &__params {
    padding-bottom: 32px;
    display: flex;
    align-items: flex-start;
    gap: 24px;
    border-bottom: 1px solid #d9d9d9;

    @media screen and (max-width: 766px) {
      padding-bottom: 0;
      flex-direction: column;
      border-bottom: none;
    }

    &-item {
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 16px;

      @media screen and (max-width: 766px) {
        gap: 8px;
      }

      .check-box {
        &__title {
          font-size: 14px;
        }
      }
    }
  }

  &__request {
    display: flex;
    align-items: center;
    gap: 16px;

    @media screen and (max-width: 766px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
    }

    &.another-position {
      flex-direction: column;
      align-items: flex-start;
      gap: 0;
    }

    &-title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-left {
      flex: 1;

      @media screen and (max-width: 766px) {
        width: 100%;
      }

      &-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &-description {
        @media screen and (max-width: 766px) {
          margin-top: 8px;
          display: flex;
          justify-content: space-between;

          .settings-seo-step-bots__mark {
            margin-top: 0;
          }
        }
      }
    }

    /* &-right {
      padding: 16px;
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 16px;

      @media screen and (max-width: 766px) {
        padding: 0;
        gap: 8px;
      }

      & p {
        &:first-child {
          & span {
            font-weight: 600;
            line-height: 140%;
          }
        }
      }

      & button {
        padding: 16px 0px;
        height: 54px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        background: #fffcee;
        border: 1px dashed #eeb010;
        border-radius: 16px;

        @media screen and (max-width: 766px) {
          padding: 6px;
          height: 40px;
          font-size: 14px;
          border-radius: 12px;
        }
      }
    } */
  }

  &__textarea {
    margin-top: 16px;
    padding: 12px 16px;
    width: 100%;
    max-height: 100px;
    min-height: 100px;
    background: #f9f9fb;
    outline: none;
    border: none;
    border-radius: 8px;

    @media screen and (max-width: 766px) {
      margin-top: 8px;
    }

    &::placeholder {
      font-size: 14px;
      font-weight: 300;
      color: #939393;
    }
  }
}
