@use "../../../../../../utils.scss" as *;

.formstep__phrase {
  margin-top: 16px;

  @media screen and (max-width: 766px) {
    margin-top: 8px;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &-container {
    display: flex;
    column-gap: 10px;
    position: relative;

    @media screen and (max-width: 766px) {
      padding-top: 0;
      column-gap: 8px;
    }
  }

  &-button {
    width: 54px;
    height: 54px;
    background: url(~assets/images/plus.svg) center/20px $yellow-main no-repeat;
    border-radius: 10px;
    flex-shrink: 0;

    @media screen and (max-width: 766px) {
      width: 42px;
      height: 42px;
      border-radius: 8px;
      background: url(~assets/images/plus.svg) center/20px $yellow-sub no-repeat;
    }

    &.disabled {
      background-color: $gray-disabled;
    }
  }

  &-tags {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 16px;

    @media screen and (max-width: 766px) {
      grid-gap: 8px;
    }
  }
}
