@use "../../../../../utils.scss" as *;

.list-block {
  &__list {
    & p {
      margin-bottom: 10px;

      font-size: 20px;
      font-weight: 700;
    }
    &-item {
      text-align: left;
      font-size: 20px;
      line-height: 160%;

      &::before {
        content: "";
        margin-left: 12px;
        margin-top: 12px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: $black;
      }
    }
  }
}

.ol {
  margin-left: 20px;
}

.ol-li {
  display: list-item;
  list-style-type: decimal;

  & span {
    font-weight: 700;
  }

  &::before {
    margin-left: 2px;
    margin-top: 0;
  }
}
