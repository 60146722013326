@use "../../../../../utils.scss" as *;

.main__services_activity {
  padding: 0 8px 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  transition: margin-top 0.3s ease;

  @media screen and (max-width: 766px) {
    padding: 0;
  }

  &.animated {
    margin-top: -16px;
  }

  &-title {
    font-weight: 300;
  }

  &-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
    transition: background-color 0.3s ease;

    @media screen and (max-width: 524px) {
      grid-template-columns: repeat(2, 1fr);

      & > *:last-child {
        grid-column: span 2;
      }
    }

    &.animated {
      background: rgba(255, 255, 255, 0.05);
    }
  }

  &-order {
    padding: 0 18px 0 28px;
    min-height: 81px;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 700;
    line-height: 120%;
    border-radius: 30px;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.16);
  
    @media screen and (max-width: 766px) {
      padding: 12px 22px;
      height: 140px;
      gap: 2px;
      font-size: 16px;
      line-height: 140%;
      border-radius: 16px;
    }
    
    .button-with-icon__icon {
      order: 2;
      width: 25px;
      height: 12px;
      flex-shrink: 0;
    }
  }
}
