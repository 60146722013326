@use "../../utils.scss" as *;

.intro {
  padding: 0 0 40px;
  min-height: 535px;
  display: grid;
  justify-items: center;
  grid-template-columns: 603px 515px;
  gap: 50px;
  overflow-y: clip;

  @media screen and (max-width: 766px) {
    margin-top: 16px;
    padding: 0;
    min-height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  &__info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 766px) {
      width: 100%;
    }
  }

  &__title {
    margin-bottom: 16px;
    font-size: 56px;
    font-weight: 800;
    line-height: 56px;

    @media screen and (max-width: 766px) {
      margin-bottom: 12px;
      font-size: 24px;
      font-weight: 700;
      line-height: 120%;
      text-align: center;
    }
  }

  &__container-markets {
    position: relative;

    @media screen and (max-width: 766px) {
      width: 100%;
    }

    &_big-circle {
      position: absolute;
      top: 12px;
      left: 60%;
      z-index: -1;
      width: 373px;
      height: 373px;
      background: linear-gradient(135deg, #d4d4d4 0%, #f8f8f8 100%);
      border-radius: 50%;

      @media screen and (max-width: 766px) {
        display: none;
      }
    }

    &_small-circle {
      position: absolute;
      left: -20%;
      bottom: -10px;
      z-index: -1;
      width: 181px;
      height: 181px;
      background: linear-gradient(268deg, #c5c5c5 1%, #f2f2f2 98%);
      border-radius: 50%;
      opacity: 0.4;

      @media screen and (max-width: 766px) {
        display: none;
      }
    }
  }
}
