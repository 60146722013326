@use "../../../../../../utils.scss" as *;

.instructions-screen {
  padding: 0;
  max-width: 800px;
  display: grid;
  grid-template-columns: 480px 320px;

  @media screen and (max-width: 766px) {
    margin: auto 16px;
  }

  &__left {
    padding: 40px 29px 40px 40px;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;

    &-header {
      margin-bottom: 16px;
      padding-bottom: 16px;
      border-bottom: 2px solid $gray-disabled;

      &-title {
        margin-bottom: 8px;
        font-size: 24px;
        font-weight: 700;
      }

      &-text {
        font-size: 14px;
        line-height: 19px;
      }
    }

    &-os {
      margin-bottom: 16px;
      padding: 4px;
      max-width: 410px;
      width: 100%;

      display: flex;
      gap: 8px;

      border: 1px solid $gray-dark;
      border-radius: 8px;
    }

    &-button {
      &-windows {
        height: 42px;

        background-color: $gray;
        border: none;
        border-radius: 4px;
        transition: none;

        &.selected {
          font-weight: 700;
          color: $white;
          background-color: #00adef;
        }
      }

      &-mac {
        height: 42px;

        background-color: $gray;
        border: none;
        border-radius: 4px;
        transition: none;

        &.selected {
          font-weight: 700;
          color: $white;
          background: linear-gradient(267deg, #1e7bf3 0.2%, #1ad0fd 99%);
        }
      }
    }

    .button-with-icon {
      &__icon {
        width: 24px;
        height: 24px;
      }
    }

    &-instruction {
      margin-bottom: 32px;
      padding-bottom: 32px;
      border-bottom: 2px solid $gray-disabled;
      line-height: 19px;

      & span {
        font-weight: 700;
      }
    }

    &-buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;
    }

    &-support {
      display: block;
      text-align: center;
      text-decoration: underline;
      font-size: 15px;

      @media screen and (max-width: 766px) {
        font-size: 14px;
      }

      &-link {
        max-width: 245px;
        width: 100%;
        height: 54px;

        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        background-color: $yellow-sub;
        border: none;
        border-radius: 16px;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  &__right {
    background: linear-gradient(180deg, $gray 0%, #e8e8e8 100%);
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;

    &-close-icon {
      margin-top: 8px;
      margin-left: auto;
      margin-right: 8px;
      padding: 6px;
      width: 34px;
      height: 34px;
      background: url(~assets/images/tagclosegray.svg) center/cover no-repeat;
      background-size: 21px;
      cursor: pointer;

      @media screen and (max-width: 766px) {
        width: 22px;
        height: 22px;
      }
    }

    &-instruction {
      margin: 20px 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-combination-windows {
      width: 260px;
      height: 48px;
      background: url(~assets/images/instructionsscreen/combination-windows.svg)
        center no-repeat;
    }

    &-combination-mac {
      width: 290px;
      height: 82px;
      background: url(~assets/images/instructionsscreen/combination-mac.svg)
        center no-repeat;
    }

    &-screen {
      margin-top: 24px;
      display: block;
      max-width: 290px;
      width: 100%;
      height: 170px;
    }

    &-list {
      padding: 16px;
      max-width: 170px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
      background-color: $white;
      border-radius: 16px;

      & li {
        color: $lightgreen;
        align-items: center;

        &::before {
          content: "";
          margin-top: 0;
          margin-right: 8px !important;
          width: 16px !important;
          height: 16px !important;
          background: url(~assets/images/checkgreen.svg) center/contain
            no-repeat;
          flex-shrink: 0;
        }
      }
    }
  }

  .button {
    padding: 0;
    max-width: 150px;
    width: 100%;
    height: 54px;
    border-radius: 16px;
  }

  .popup__header {
    display: none;
  }
}
