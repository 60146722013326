@use "../../../utils.scss" as *;

.stopmodal {
  padding: 32px 40px;
  max-width: 570px;

  @media screen and (max-width: 766px) {
    padding: 16px 8px 32px;
    border-radius: 16px 16px 0 0;
  }

  &__header {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 32px;

    @media screen and (max-width: 766px) {
      margin-top: 16px;
      margin-bottom: 16px;
      padding-left: 8px;
      flex-direction: row-reverse;
      justify-content: space-between;
      gap: 8px;
      border-radius: 16px 16px 0 0;
    }

    &-icon {
      width: 97px;
      height: 97px;
      background: url(~assets/images/stop.svg) center/cover no-repeat;

      @media screen and (max-width: 766px) {
        width: 62px;
        height: 62px;
      }
    }

    &-message {
      margin-bottom: 8px;
      font-size: 24px;
      font-weight: 700;
      color: $pink-dark;
    }

    &-comment {
      max-width: 220px;
      font-weight: 700;
      color: $gray-dark;

      @media screen and (max-width: 766px) {
        font-size: 14px;
        line-height: 120%;
      }
    }
  }

  &__info_points {
    padding: 16px;
    background: $gray;
    border-radius: 16px;

    @media screen and (max-width: 766px) {
      padding: 14px 10px;
      border-radius: 24px;
    }
  }

  &__buttons {
    margin-top: 10px;
    padding-top: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-top: 2px solid $gray-disabled;

    @media screen and (max-width: 766px) {
      margin-top: 16px;
      width: 100%;
      flex-direction: column;
    }
  }

  &__button {    
    &-cancel {
      max-width: 243px;
      width: 100%;
      background-color: $yellow-sub;
      border: 2px solid $yellow-sub;

      @media screen and (max-width: 766px) {
        max-width: none;
      }
    }

    &-ok {
      max-width: 243px;
      gap: 4px;
      font-weight: 700;
      color: $pink-dark;
      background-color: rgba(255, 115, 115, 0.1);
      border: none;

      @media screen and (max-width: 766px) {
        max-width: none;
        height: 44px;
      }

      .button-with-icon {        
        &__icon {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}
