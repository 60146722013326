@use "../../../../utils.scss" as *;

.main__platforms {
  position: relative;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 766px) {
    padding-bottom: 0;
  }

  &_banner {
    position: relative;
    padding: 40px 32px;
    max-width: 520px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 110px;
    color: $white;
    background: #4f4f4f;
    border-radius: 24px;
    flex-shrink: 0;
    overflow: hidden;

    @media screen and (max-width: 766px) {
      padding: 26px 16px;
      max-width: none;
      gap: 200px;
    }

    &-title {
      font-size: 32px;
      font-weight: 600;
      line-height: 130%;

      @media screen and (max-width: 766px) {
        font-size: 24px;
      }

      &-span {
        padding: 6px 10px 6px 8px;
        line-height: 130%;
        color: #4f4f4f;
        background-color: $yellow-main;
        border-radius: 16px;

        @media screen and (max-width: 766px) {
          padding: 2px 10px 4px 8px;
          font-size: 24px;
          font-style: italic;
          line-height: 120%;
          border-radius: 12px;
        }
      }
    }

    &-text {
      font-weight: 300;
      line-height: 110%;

      @media screen and (max-width: 766px) {
        font-size: 14px;
        line-height: 120%;
      }

      &-span {
        font-weight: 700;
      }
    }

    &-bg {
      position: absolute;
      width: 100px;
      height: 100px;
      border-radius: 100%;
      opacity: 0.2;

      &:nth-child(3) {
        right: 59px;
        bottom: -50px;
        background: linear-gradient(
          206deg,
          #fff 0%,
          rgba(255, 244, 149, 0) 100%
        );
      }

      &:nth-child(4) {
        top: -30px;
        right: -50px;
        background: linear-gradient(
          108deg,
          #fff 0%,
          rgba(255, 244, 149, 0) 100%
        );
      }

      &:nth-child(5) {
        right: -50px;
        top: 30%;
        height: 50px;
        border-radius: 0 0 0 100px;
        background: linear-gradient(
          323deg,
          #fff 0%,
          rgba(255, 244, 149, 0) 100%
        );
      }

      &:nth-child(6) {
        top: 48%;
        right: -50px;
        height: 50px;
        border-radius: 100px 0 0 0;
        background: linear-gradient(
          248deg,
          #fff 0%,
          rgba(255, 244, 149, 0) 100%
        );
      }

      &:nth-child(7) {
        right: -50px;
        bottom: 0;
        background: linear-gradient(
          108deg,
          #fff 0%,
          rgba(255, 244, 149, 0) 100%
        );
      }

      &:nth-child(8) {
        top: 92px;
        right: 62px;
        background: linear-gradient(
          148deg,
          #fff 0%,
          rgba(255, 244, 149, 0) 100%
        );
      }

      &:nth-child(9) {
        right: 62px;
        bottom: 75px;
        height: 50px;
        border-radius: 0 0 100px 100px;
        background: linear-gradient(
          22deg,
          #fff 0%,
          rgba(255, 244, 149, 0) 100%
        );
      }
    }
  }

  &-container {
    display: flex;
    flex-direction: column;
  }

  &_text {
    margin-top: 12px;
    padding: 4px 8px;
    min-width: 370px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 766px) {
      display: none;
    }

    & p {
      font-size: 14px;
    }
  }

  &_icon {
    width: 26px;
    height: 26px;
    background: url(~assets/images/main/play.svg) center/contain no-repeat;
  }

  &_items {
    position: absolute;
    top: 90px;
    right: 0;
    width: 100%;
    height: 70%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      display: none;
    }

    @media screen and (max-width: 766px) {
      top: 100px;
    }
  }

  &_markets-container {
    margin-left: 40%;
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-auto-flow: column;
    grid-auto-columns: 340px;
    gap: 8px;

    @media screen and (max-width: 766px) {
      margin-left: 16px;
      grid-auto-columns: 220px;
    }
  }

  &_item {
    position: relative;
    width: 100%;
    background: $white;
    border-radius: 40px;
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.16);
    overflow: hidden;

    @media screen and (max-width: 766px) {
      border-radius: 24px;
    }

    &:hover {
      background: $gray-disabled;

      .main__platforms_item-info-arrow {
        background-image: url(~assets/images/arrowRightYellow.svg);
      }
    }

    &:nth-child(odd) {
      margin-left: 40%;
    }

    &-container {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    &-logo {
      width: 56px;
      height: 56px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      flex-shrink: 0;

      @media screen and (max-width: 766px) {
        width: 44px;
        height: 44px;
        border-radius: 14px;
      }
    }

    &-label {
      font-size: 24px;
      font-weight: 600;
      line-height: 110%;

      @media screen and (max-width: 766px) {
        font-size: 16px;
        line-height: 120%;
      }
    }

    &-info {
      padding: 24px 24px 24px 26px;
      min-width: 340px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 40px;
      cursor: pointer;

      @media screen and (max-width: 766px) {
        padding: 8px 12px 22px;
        min-width: auto;
        gap: 4px;
      }

      &-container {
        display: flex;
        align-items: center;
        gap: 24px;

        @media screen and (max-width: 766px) {
          padding-top: 8px;
          gap: 12px;
        }
      }

      &-arrow {
        width: 25px;
        height: 12px;
        background: url(~assets/images/arrowRightGray.svg) center/contain
          no-repeat;
        flex-shrink: 0;

        @media screen and (max-width: 766px) {
          margin-top: 8px;
        }
      }
    }

    .badge {
      padding: 3px 16px 4px 32px;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 16px;
      font-size: 9px;
      font-style: italic;
      color: $white;
      text-align: left;
      text-transform: uppercase;
      border-radius: 0px 8px 7px 0px;

      @media screen and (max-width: 766px) {
        margin-left: 0;
        border-radius: 0px 8px 0px 0px;
      }

      &__new {
        background: linear-gradient(87deg, #de43b1 0.7%, #ef6193 85%);
      }
    }
  }

  .selected-line {
    display: none;
  }
}
