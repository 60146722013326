@use "../../../../utils.scss" as *;

.form-step4 {
  &__title {
    margin-bottom: 24px;
    font-weight: 700;

    @media screen and (max-width: 766px) {
      margin-bottom: 16px;
      text-align: center;
    }
  }

  &__data {
    @media screen and (max-width: 766px) {
      font-size: 14px;
    }
  }

  &__services {
    margin-top: 32px;
    gap: 8px;

    @media screen and (max-width: 766px) {
      margin-top: 24px;
    }

    &-title {
      margin-bottom: 16px;
      font-weight: 700;
    }

    &-item {
      padding: 5px 8px;
      display: flex;
      align-items: center;
      gap: 8px;

      @media screen and (max-width: 766px) {
        font-size: 14px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-icon {
      width: 17px;
      height: 17px;
      background: url(~assets/images/checkbox.svg) center/contain no-repeat;
    }
  }
}

.form-step__title {
  margin-bottom: 8px;
  color: #939393;

  @media screen and (max-width: 766px) {
    margin-bottom: 4px;
    font-size: 12px;
  }
}
