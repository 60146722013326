@use "../../../utils.scss" as *;

.info-point {
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-bottom: 2px solid $gray-disabled;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }

  &__icon {
    width: 40px;
    height: 40px;
    flex-shrink: 0;

    @media screen and (max-width: 766px) {
      width: 32px;
      height: 32px;
    }
  }

  &__text {
    line-height: 120%;

    @media screen and (max-width: 766px) {
      font-size: 12px;
    }
  }

  &__link {
    line-height: 120%;
    color: #EEB010;

    @media screen and (max-width: 766px) {
      font-size: 14px;
    }
  }
}
