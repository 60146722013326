@use "../../../utils.scss" as *;

.hint {
  padding: 10px 16px;

  position: absolute;

  max-width: 360px;
  min-width: 290px;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 8px;
  box-shadow: $box-shadow-main;

  &__title {
    margin-bottom: 8px;
    font-weight: 700;
  }

  &__button {
    margin-top: 8px;
    width: 100%;
    border-radius: 16px;
    font-weight: 600;
  }
}
