@use "../../../../../../utils.scss" as *;

.react-dadata__container {
  position: relative;
}

.react-dadata__input {
  display: block;
  box-sizing: border-box;
  height: 54px;
  background-color: $gray;
  border: 2px solid $gray;
  border-radius: 10px;
  width: 100%;
  font-size: 16px;
  padding: 0 28px;
  outline: none;
  transition: border-color 0.3s;
  touch-action: pan-y;

  @media screen and (max-width: 766px) {
    padding: 0 16px;
    height: 42px;
    border-radius: 8px;
  }
}

.react-dadata__input:focus {
  border: 2px solid $yellow-main;
}

.react-dadata__input::placeholder {
  opacity: 0.5;

  @media screen and (max-width: 766px) {
    font-size: 14px;
    opacity: 0.4;
    color: inherit;
  }
}

.react-dadata__input.error {
  border: 2px solid $red;
}

.react-dadata__suggestions {
  padding: 0;
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  list-style: none;
  z-index: 10;
  background-color: #fff;
  box-shadow: 0 1px 6px 3px rgba(0,0,0,.1);
  top: calc(100% + 8px);
  border-radius: 10px;
  overflow: hidden;
  text-align: left;
}

.react-dadata__suggestion-note {
  font-size: 14px;
  color: #828282;
  padding: 10px 10px 5px 10px;
}

.react-dadata__suggestion {
  font-size: 16px;
  padding: 14px 28px;
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
  display: block;
  background: none;
  border: none;
  text-align: left;

  @media screen and (max-width: 766px) {
    padding: 12px 14px;
    font-size: 14px;
  }
}

.react-dadata__suggestion--line-through {
  text-decoration: line-through;
}

.react-dadata__suggestion-subtitle {
  font-size: 14px;
  margin-top: 4px;
  color: #777777;
}

.react-dadata__suggestion-subtitle-item {
  display: inline-block;
  margin-right: 16px;
  margin-bottom: 4px;
}

.react-dadata__suggestion-subtitle-item:last-child {
  margin-right: 0;
}

.react-dadata__suggestion--current {
  background-color: rgba(0, 124, 214, 0.15);
}

.react-dadata__suggestion:hover {
  background-color: rgba(0, 124, 214, 0.1);
}

.react-dadata mark {
  background: none;
}

.react-dadata--highlighted {
  color: $yellow-dark;
}
