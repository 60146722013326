@use "../../../../utils.scss" as *;

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.statistics {
  padding: 0 0 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 52px;
  font-weight: 700;
  line-height: 140%;
  text-align: center;

  @media screen and (max-width: 766px) {
    padding: 0 24px 50px;
    align-items: flex-start;
    font-size: 32px;
    text-align: left;
  }

  &__digits-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;

    @media screen and (max-width: 766px) {
      margin-bottom: 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
    }
  }

  &__digits-wrapper {
    display: flex;
    align-items: center;
    gap: 4px;

    @media screen and (max-width: 766px) {
      gap: 3px;
    }
  }

  &__digit {
    position: relative;
    padding: 0 10px;
    min-width: 56px;
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(0deg, #fff 0%, #fff 100%), #ffe14b;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    transition: transform 0.3s ease-in-out;
    opacity: 0.7;
    animation: slideUp 0.5s ease forwards;

    @media screen and (max-width: 766px) {
      min-width: 48px;
      height: 56px;
      font-size: 38px;
    }

    &::before {
      position: absolute;
      top: 50%;
      left: 5px;
      z-index: -1;
      content: "";
      width: 49px;
      height: 2px;
      border-bottom: 1px solid #f4f4f4;

      @media screen and (max-width: 766px) {
        width: 40px;
      }
    }
  }
}
