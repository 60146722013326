@use "../../utils.scss" as *;

.how-it-works {
  margin: 72px 0;
  padding: 12px 32px;
  border-radius: 16px;

  @media screen and (max-width: 766px) {
    margin: 0;
    padding: 0;
    display: flex;
    background: rgba(255, 255, 255, 0.3);
    box-shadow: none;
  }

  &.other-padding {
    padding: 12px 32px 24px;
    background: rgba(255, 255, 255, 0.3);

    @media screen and (max-width: 766px) {
      padding: 0;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &-title {
      font-size: 24px;
      font-weight: 700;
      line-height: 120%;
    }

    &-button-more {
      max-width: 200px;
      width: 100%;
      height: 38px;
      background: $yellow-sub;
      border: 2px solid $yellow-sub;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__advices {
    &-container {
      margin-top: 16px;
      padding-bottom: 24px;
      border-bottom: 1px solid $blue-dirty;

      @media screen and (max-width: 766px) {
        margin-top: 24px;
      }
    }

    &-content {
      display: flex;
      align-items: stretch;
      gap: 6px;

      @media screen and (max-width: 766px) {
        flex-direction: column;
      }
    }

    &-text {
      margin-top: 16px;
      font-size: 12px;
      line-height: 120%;
      color: $gray-dark;
    }

    &-link,
    &-button {
      color: $orange-light;
      text-decoration: underline;
    }
  }

  &__mobile-row {
    display: flex;
    align-items: stretch;
    gap: 6px;
  }

  &__mobile-single {
    width: 100%;

    @media screen and (max-width: 766px) {
      .how-it-works-steps {
        max-width: 506px;
        height: auto;
      }
    }
  }

  &__additional-content {
    @media screen and (max-width: 766px) {
      margin-top: 16px;
      margin-left: 0;
    }
  }

  &__advice {
    margin-left: 18px;
    font-size: 14px;

    @media screen and (max-width: 766px) {
      margin-left: 0;
      font-weight: 400;
      text-align: left;
    }

    &-item {
      margin-bottom: 8px;
      align-items: center;
      column-gap: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        margin-top: 0;
        width: 8px;
        height: 8px;
        flex-shrink: 0;

        @media screen and (max-width: 766px) {
          width: 5px;
          height: 5px;
        }
      }
    }
  }

  &__create-project {
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    border-bottom: 1px solid $blue-dirty;

    @media screen and (max-width: 766px) {
      padding-bottom: 0;
      gap: 16px;
      border-bottom: none;
    }

    &-title {
      font-size: 24px;
      font-weight: 300;
      line-height: 140%;

      @media screen and (max-width: 766px) {
        font-size: 18px;
      }
    }

    &-points {
      margin-bottom: -45px;
      display: flex;
      align-items: flex-start;
      gap: 36px;

      @media screen and (max-width: 766px) {
        margin-bottom: 0;
        flex-direction: column;
        align-items: center;
        gap: 0;
      }

      &-wrapper {
        display: flex;
        gap: 6px;

        @media screen and (max-width: 766px) {
          padding: 16px;
          flex-direction: column;
          gap: 8px;
          background: $white;
          border-radius: 8px;
          box-shadow: $box-shadow-main;
        }

        .info-point {
          margin-bottom: 16px;
          padding: 24px 16px;
          max-width: 372px;
          gap: 4px;
          background: $white;
          border: none;
          border-radius: 8px;
          box-shadow: $box-shadow-main;

          @media screen and (max-width: 766px) {
            margin: 0;
            padding: 0;
            box-shadow: none;
          }

          &__icon {
            width: 24px;
            height: 24px;

            @media screen and (max-width: 766px) {
              width: 20px;
              height: 20px;
            }
          }

          &__text h2 {
            font-size: 14px;
            font-style: italic;
            font-weight: 500;
            line-height: 140%;

            & span {
              font-weight: 300;
            }
          }

          &__text p {
            font-size: 14px;
            font-weight: 300;
            line-height: 140%;

            & span {
              font-style: italic;
              font-weight: 500;
            }
          }
        }
      }

      .icon-mobile-dino-step-first,
      .icon-mobile-dino-step-second {
        width: 312px;
        height: 80px;
      }
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 16px;

    @media screen and (max-width: 766px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }

    & a {
      width: 100%;

      @media screen and (max-width: 766px) {
        height: 44px;
        order: 2;
      }
    }

    @media screen and (max-width: 766px) {
      & button {
        padding: 0;
        height: 44px;
      }
    }
  }

  &__button-support {
    width: 100%;
    background-color: $yellow-sub;
    border: 2px solid $yellow-sub;

    @media screen and (max-width: 766px) {
      padding: 0;
      height: 44px;
    }
  }
}
