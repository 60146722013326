@use "../../../utils.scss" as *;

.projects-drafts {
  &-row {
    margin-bottom: 8px;

    &:first-child {
      margin-top: 24px;

      @media screen and (max-width: 766px) {
        margin-top: 16px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  & > div:first-child {
    .projects-drafts__row {
      margin-top: 0;
    }
  }

  &__row {
    margin-top: 30px;
    padding-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $blue-dirty;
    cursor: pointer;

    &-total-project {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    &-title {
      font-size: 16px;
      font-weight: 700;
      color: $gray-dark;

      @media screen and (max-width: 766px) {
        font-size: 14px;
      }
    }

    &-accordion-icon {
      padding: 5px;
      width: 22px;
      height: 22px;
      background: rgba(242, 241, 237, 0.3);
      border-radius: 8px;
      box-shadow: $box-shadow-light;
      flex-shrink: 0;
      transition: transform 0.2s;

      @media screen and (max-width: 766px) {
        padding: 8px;
        width: 24px;
        height: 24px;
      }

      &.expanded {
        transform: rotate(180deg);
      }
    }

    &-total-sum {
      padding-right: 16px;
      font-size: 18px;
      font-weight: 700;
      color: #ababab;

      @media screen and (max-width: 766px) {
        margin-top: 16px;
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        line-height: 140%;
      }

      &.other-color {
        color: $black;
      }
    }

    &-reports {
      height: 0;
      overflow: hidden;

      &.expanded {
        height: auto;
      }
    }
  }
}
