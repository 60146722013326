@use "../../../utils.scss" as *;

.button-with-icon {
  width: 100%;
  height: 54px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  background-color: $yellow-main;
  border: 2px solid $yellow-main;
  border-radius: 16px;
  transition: background-color 0.5s;

  &:hover {
    background-color: $white;
  }

  &:disabled {
    background-color: $gray-disabled;
    border-color: $gray-disabled;
    opacity: .6;
    cursor: default;
  }

  &__link {
    display: contents;
  }

  &__icon {
    display: inline-block;
    flex-shrink: 0;
  }
}
