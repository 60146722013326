@use "../../utils.scss" as *;

.dropdown-links {
  &__blur {
    position: fixed;
    inset: 0;
    width: 100vw;
    height: 100vh;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    z-index: -1;

    @media screen and (max-width: 766px) {
      display: none;
    }
  }

  &__container {
    max-width: 920px;
    width: 100%;
    min-height: 100%;
    background-color: $white;
    transition: all 0.8s;
    overflow-y: auto;

    @media screen and (max-width: 766px) {
      max-width: none;
      background-color: $gray;
    }
  }

  &__wrapper {
    padding: 16px 16px 68px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media screen and (max-width: 766px) {
      padding: 0;
    }

    &-search {
      display: flex;
      align-items: center;
      gap: 10px;

      @media screen and (max-width: 766px) {
        padding: 16px 8px 0;
        gap: 8px;
      }
    }

    &-button-close {
      width: 45px;
      height: 45px;
      background: url(~assets/images/accordion.svg) center no-repeat;
      background-color: $gray;
      background-size: 17px;
      border-radius: 11px;
      transform: rotate(90deg);
      flex-shrink: 0;

      @media screen and (max-width: 766px) {
        width: 32px;
        height: 32px;
        background-size: 13px;
      }
    }

    &-button-cancel {
      padding: 0;
      max-width: 55px;
      width: 100%;
      height: 40px;
      font-size: 12px;
      font-weight: 500;
      color: $yellow-dark;
      background-color: inherit;
      border: none;
    }

    &-items {
      max-width: 880px;
      width: 100%;
      display: flex;
      gap: 16px;

      @media screen and (max-width: 766px) {
        flex-direction: column;
      }
    }
  }
}
