@use "../../../../../../utils.scss" as *;

.avito-step2-activities-calculator {
  @media screen and (max-width: 766px) {
    margin-top: 0;
    border-radius: 16px;

    & li {
      &::before {
        content: none;
      }
    }
  }

  .activity-row {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
