@keyframes loading {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.skeleton {
  &-image {
    width: 100%;
    height: 160px;

    background-image: linear-gradient(90deg, #1b1b1b 0%, #5e5e5e 100%);
    background-size: 200% 200%;
    border-radius: 16px;
    animation: loading 2s ease infinite;

    opacity: 0.7;
  }

  &-container {
    padding: 8px 16px 16px;

    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &-type {
    width: 50px;
    height: 30px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 7px;
    background-color: #f9f9fb;
  }

  &-title {
    height: 90px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  &-text.title {
    height: 25px;
  }

  &-text {
    width: 90%;
    height: 12px;
    background-image: linear-gradient(90deg, #1b1b1b 0%, #5e5e5e 100%);
    background-size: 200% 200%;
    animation: loading 2s ease infinite;
    border-radius: 8px;
  }

  &-info {
    display: flex;
    justify-content: space-between;
  }

  &-text.info {
    max-width: 75px;
    width: 100%;
  }

  &-time {
    position: absolute;
    top: 0;
    right: 0;

    margin-top: 8px;
    margin-right: 8px;
    max-width: 100px;
    width: 100%;
    height: 30px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 10px;
    background: rgba(255, 255, 255, 0.8);
  }
}
