@use "../../utils.scss" as *;

.blog {
  max-width: $screenminwidth;
  margin: 0 auto;

  &__header {
    margin: 32px auto 56px;
    max-width: 420px;
    width: 100%;
    text-align: center;

    &-title {
      margin-bottom: 8px;
      font-size: 32px;
      font-weight: 900;
      line-height: 120%;
    }

    &-subtitle {
      line-height: 140%;
    }
  }

  &__articles {
    margin-bottom: 110px;
    min-height: 340px;
    display: grid;
    grid-template-columns: repeat(3, minmax(380px, 1fr));
    gap: 15px;

    &-empty {
      margin-top: 50px;
      grid-column: 2;
      text-align: center;
    }
  }

  &__button {
    position: fixed;
    bottom: 0;
    right: 15%;

    margin-bottom: 220px;
    width: 72px;
    height: 72px;
    
    background: url(~assets/images/scrollToUp.svg) center no-repeat;
    background-color: #4F4F4F;
    border-radius: 50%;
    transition: background-color 0.5s;

    &:hover {
      background-color: #303030;
    }
  }
}

.skeleton-wrapper {
  position: relative;

  background-color: #fff;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    transform: scale(1.01);
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
  }
}