@use "../../../../../../../utils.scss" as *;

.avito-select-tag {
  padding: 6px 10px;

  border: 1px solid $yellow-main;
  border-radius: 36px;

  position: relative;

  &__title {
    color: $black;
    font-size: 14px;

    @media screen and (max-width: 766px) {
      font-size: 12px;
    }
  }

  &__button-close {
    position: absolute;
    top: -4px;
    right: -4px;
    width: 16px;
    height: 16px;
    background: url(~assets/images/tagclose.svg) center/contain no-repeat;

    @media screen and (max-width: 766px) {
      width: 14px;
      height: 14px;
    }
  }
}
