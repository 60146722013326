@use "../../../utils.scss" as *;

.withdrawing {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  background-color: $white;
  border-radius: 24px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);

  @media screen and (max-width: 766px) {
    margin: 24px 16px 0;
    padding: 16px;
    gap: 24px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media screen and (max-width: 766px) {
      gap: 16px;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 120%;

    @media screen and (max-width: 766px) {
    }
  }

  &__sum {
    display: flex;
    align-items: center;
    gap: 24px;

    @media screen and (max-width: 766px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    }
  }

  &__history {
    min-height: 300px;

    &.other-height {
      min-height: auto;
    }

    @media screen and (max-width: 766px) {
      min-height: auto;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
}
