@use "../../../utils.scss" as *;

.how-it-works-steps {
  padding: 16px 24px 24px;
  max-width: 255px;
  width: 100%;
  border-radius: 8px;

  @media screen and (max-width: 766px) {
    padding: 16px;
  }

  &.other-params {
    max-width: none;
  }

  &__icons {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-icon {
      padding: 3px;
      width: 26px;
      height: 26px;

      @media screen and (max-width: 766px) {
        width: 18px;
        height: 18px;
      }
    }

    &-step {
      width: 54px;
      height: 32px;

      @media screen and (max-width: 766px) {
        width: 40px;
        height: 24px;
      }
    }

    &-border {
      margin: 16px 0;
      width: 62px;
      border: none;
      border-bottom: 1px solid $blue-dirty;

      &.other-params {
        margin: 4px 0 25px 0;
      }

      @media screen and (max-width: 766px) {
        margin: 12px 0;
        width: 32px;
      }
    }
  }

  &__description {
    &-title {
      margin-bottom: 8px;
      font-size: 18px;
      font-weight: 700;
      line-height: 120%;

      @media screen and (max-width: 766px) {
        font-size: 12px;
      }
    }

    &-text {
      font-size: 12px;
      line-height: 140%;

      @media screen and (max-width: 766px) {
        font-size: 12px;
        line-height: 120%;
      }
    }
  }
}
