@use "../../../utils.scss" as *;

.badge {
  margin-left: 8px;
  width: fit-content;
  height: 21px;
  text-align: center;
  border-radius: 4px;

  &__beta {
    padding: 4px 12px;
    font-size: 12px;
    color: $gray-dark;
    text-transform: lowercase;
    background-color: $gray-disabled;
  }

  &__new {
    padding: 3px 4px;
    font-size: 12px;
    font-style: italic;
    color: #a78ecf;
    background-color: #ebe1fc;
  }

  &__exclusive {
    position: relative;
    padding: 4px 8px;

    font-size: 12px;
    font-style: italic;
    text-transform: capitalize;
    border-radius: 8px;

    // бэкграунд
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        45deg,
        rgba(255, 115, 115, 0.1) 0%,
        rgba(254, 212, 0, 0.1) 100%
      );
      border-radius: inherit;
    }

    & {
      background: linear-gradient(
        45deg,
        #ff7373 57.5%,
        rgba(254, 212, 0, 0.97) 100%
      );
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }
  }

  &__popular {
    background: linear-gradient(88deg, #0ea7d8 0.8%, #5bc6cc 99%);
  }

  &__soon {
    background: linear-gradient(85deg, #9580BB 2%, #AEBFEB 90%);
  }

  &__hit {
    background: linear-gradient(56deg, #fc6d26 0%, #ef9d61 100%);
  }
}
