@use "../../../../../utils.scss" as *;

.empty-section {
  padding-top: 60px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;
  border-bottom: 1px solid $gray-disabled;

  &__title {
    font-size: 24px;
    font-weight: 700;
    color: #939393;

    @media screen and (max-width: 766px) {
      font-size: 18px;
    }
  }

  &__text {
    margin: 0 auto;
    max-width: 520px;
    width: 100%;
    color: #939393;

    @media screen and (max-width: 766px) {
      font-size: 14px;
      line-height: 120%;
    }
  }
}
