@use "../../utils.scss" as *;

.accept-rules {
  max-width: 520px;
  
  &__list {
    margin-top: 16px;
    margin-bottom: 20px;
    padding-bottom: 16px;
    font-size: 14px;
    line-height: 140%;
    border-bottom: 2px solid $gray-disabled;

    @media screen and (max-width: 766px) {
      margin-bottom: 15px;
    }

    & li {
      margin-bottom: 6px;
      margin-left: 16px;
      display: list-item;
      list-style-type: decimal;

      @media screen and (max-width: 766px) {
        margin-bottom: 4px;
        font-size: 14px;
        line-height: 140%;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        margin-right: 0 !important;
      }
    }
  }

  &__subinfo {
    margin-top: 4px;
    display: flex;
    justify-content: center;
    text-align: center;

    &-container {
      max-width: 300px;
      width: 100%;

      @media screen and (max-width: 766px) {
        max-width: none;
      }
    }

    &-title {
      margin-bottom: 4px;
      font-weight: 500;
    }

    @media screen and (max-width: 766px) {
      &-text {
        line-height: 115%;
      }
    }

    &-link {
      color: $yellow-dark;
      text-decoration: underline;
    }
  }

  &__check {
    margin-bottom: 16px;

    @media screen and (max-width: 766px) {
      align-items: center;
      font-size: 14px;
    }

    &-chose {
      margin-bottom: 8px;
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  &__support {
    max-width: 200px;
    width: 100%;

    @media screen and (max-width: 766px) {
      font-size: 14px;
    }

    .button {
      background-color: $yellow-sub;
      border: 2px solid $yellow-sub;
    }
  }

  .popup__header {
    flex-direction: column;
    align-items: flex-start;

    &-title {
      text-align: start;
    }

    &-subtitle {
      margin-top: 8px;
      font-weight: 400;
      color: $gray-dark;
    }
  }

  .button {
    padding-left: 30px;
    padding-right: 30px;
    max-width: 260px;
    width: 100%;

    @media screen and (max-width: 766px) {
      padding: 0;
      height: 40px;
      font-size: 14px;
    }
  }
}
