@use "../../../../../../utils.scss" as *;

.projects-active-report-auction {
  .faq-report-modal {
    padding: 24px 8px 8px;
    background-color: inherit;

    @media screen and (max-width: 766px) {
      padding: 24px 0 0;
    }
  }
}