@use "../../../utils.scss" as *;

.form-layout {
  margin: 24px auto;
  padding: 32px 24px;
  max-width: 650px;
  width: 100%;

  background-color: $white;
  border-radius: 32px;
  box-shadow: $box-shadow-main;

  @media screen and (max-width: 766px) {
    margin: 0;
    padding: 0;
    max-width: none;
    border-radius: 0;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 32px;

    @media screen and (max-width: 766px) {
      gap: 24px;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media screen and (max-width: 766px) {
      padding: 24px 8px;
      background-color: $gray;
      border-radius: 0;
      border-bottom-left-radius: 24px;
      border-bottom-right-radius: 24px;
    }

    &-title {
      font-size: 32px;
      font-weight: 700;

      @media screen and (max-width: 766px) {
        font-size: 18px;
        text-align: center;
      }
    }
  }

  &__steps {
    display: flex;
    gap: 16px;

    @media screen and (max-width: 766px) {
      justify-content: center;

      &-0 {
        width: 90px;
      }

      &-1 {
        width: 140px;
      }

      &-2 {
        width: 90px;
      }
    }

    &-indicator {
      width: 190px;
      height: 8px;
      background-color: #ebebeb;
      border-radius: 24px;

      @media screen and (max-width: 766px) {
        width: 100%;
      }

      &.active {
        background-color: #ffe14b;
      }

      &.done {
        background-color: #57c0584d;
      }
    }
  }

  &__step {
    margin-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;

    p {
      &.current {
        color: inherit;

        @media screen and (max-width: 766px) {
          font-size: 10px;
        }
      }

      &.inactive {
        color: #939393;

        @media screen and (max-width: 766px) {
          font-size: 9px;
        }
      }
    }

    &-icon {
      width: 12px;
      height: 12px;
      background: url(~assets/images/checkgreen.svg) center/contain no-repeat;

      @media screen and (max-width: 766px) {
        width: 8px;
        height: 8px;
      }
    }
  }

  @media screen and (max-width: 766px) {
    &__content {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &__buttons {
    border-top: 1px solid $blue-dirty;

    @media screen and (max-width: 766px) {
      padding-left: 16px;
      padding-right: 16px;
    }

    &-container {
      margin-top: 24px;
      margin-bottom: 24px;
      display: flex;
      gap: 16px;

      @media screen and (max-width: 766px) {
        margin-top: 16px;
        margin-bottom: 32px;
        flex-direction: column;
        gap: 8px;
      }
    }

    & p {
      font-size: 14px;
      color: #939393;
      text-align: center;

      & a {
        color: $yellow-dark;
      }
    }
  }

  &__button-cancel {
    max-width: 212px;
    width: 100%;
    background-color: $yellow-sub;
    border: 2px solid $yellow-sub;

    @media screen and (max-width: 766px) {
      order: 2;
      max-width: none;
      height: 44px;
    }
  }

  &__button-ok {
    max-width: 374px;
    width: 100%;

    @media screen and (max-width: 766px) {
      order: 1;
      max-width: none;
      height: 44px;
    }
  }
}

.form-layout-mobile {
  margin: 16px auto 100px;
  font-size: 14px;
  color: #939393;
  text-align: center;

  & a {
    color: $yellow-dark;
  }
}
