@use "../../../../../utils.scss" as *;

.withdrawing__amount-section {
  width: 100%;
  display: flex;
  gap: 16px;

  @media screen and (max-width: 766px) {
    flex-direction: column;
  }

  &_balance {
    max-width: 460px;
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(255, 225, 75, 0.1);
    border-radius: 16px;

    @media screen and (max-width: 766px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    }

    &-text {
      margin-bottom: 4px;
      font-size: 14px;
      line-height: 120%;
    }

    &-count {
      @include hold-height-if-empty;

      display: flex;
      align-items: center;
      gap: 6px;
      font-size: 24px;
      font-weight: 700;
      line-height: 120%;

      &::after {
        content: "";
        display: inline-block;
        width: 30px;
        height: 30px;
        background: url(~assets/images/bonus.svg) center/contain no-repeat;
      }
    }
  }

  &_total {
    flex: 1;
    display: flex;
    gap: 8px;

    @media screen and (max-width: 766px) {
      flex-wrap: wrap;
    }
  }
}
