@use "../../utils.scss" as *;

.signin {
  margin-top: 125px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 766px) {
    margin: 100px 15px 0;
  }

  &__form {
    max-width: 615px;
    width: 100%;
  }

  &__plate {
    padding: 50px 57px;
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 30px;

    @media screen and (max-width: 766px) {
      padding: 24px 34px;
      gap: 24px;
    }
  }

  &__title {
    font-size: 32px;
    font-weight: 400;

    @media screen and (max-width: 766px) {
      font-size: 24px;
    }
  }

  &__input {
    @media screen and (max-width: 766px) {
      height: 40px;

      &::placeholder {
        font-size: 14px;
      }
    }
  }

  &__links-container {
    display: flex;
    gap: 32px;

    @media screen and (max-width: 766px) {
      justify-content: space-around;
      gap: 0;
    }
  }

  &__link {
    text-decoration: underline;
    text-underline-offset: 0.2em;

    @media screen and (max-width: 766px) {
      font-size: 12px;
      color: $gray-dark;
    }
  }

  .textinput {
    border: 1px solid rgba(30, 30, 30, 0.3)
  }

  .button {
    width: 100%;

    @media screen and (max-width: 766px) {
      padding: 0;
    }
  }
}
