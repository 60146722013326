.messagebox {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0;
  visibility: hidden;

  &_opened {
    display: flex;
    opacity: 1;
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.07);
    z-index: 11;
  }

  &__plate {
    width: 360px;
    padding: 32px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;

    @media screen and (max-width: 766px) {
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__close-icon {
    width: 32px;
    height: 32px;
    background: url(~assets/images/closeIcon.svg) center/cover no-repeat;
    margin-left: auto;
    cursor: pointer;
  }

  &__icon {
    margin-bottom: 10px;
    position: relative;
    width: 112px;
    height: 112px;

    &_info {
      background: url(~assets/images/messageinfo.svg) center/cover no-repeat;
    }
    &_warning {
      background: url(~assets/images/warning.svg) center/cover no-repeat;
    }
    &_error {
      background: url(~assets/images/messageerror.svg) center/cover no-repeat;
    }
    &_success {
      width: 140px;
      height: 140px;
      background: url(~assets/images/dino/dino-success.svg) center/contain no-repeat;
      
      @media screen and (max-width: 766px) {
        width: 324px;
        height: 135px;
        background: url(~assets/images/dino/dino-success-mobile.svg) center/contain no-repeat;
      }
    }
  }

  &__message {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
  }

  &__comment {
    font-size: 16px;
    text-align: center;
  }

  .check-box {
    margin-bottom: 0;
  }

  .button {
    min-width: 160px;
    height: 40px;
  }
}
