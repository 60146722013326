@use "../../../utils.scss" as *;

.market__interactive {
  display: flex;
  flex-flow: column;
  gap: 32px;

  @media screen and (max-width: 766px) {
    margin: 24px 8px 0;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &-title {
    display: flex;
    gap: 4px;

    & p, h2 {
      font-size: 24px;
      font-weight: 700;
      line-height: 120%;

      @media screen and (max-width: 766px) {
        min-width: 60px;
        font-size: 14px;
      }
    }

    & span, h2 {
      font-weight: 300;
      color: $gray-dark;
    }

    & h2 {
      margin-left: 8px;

      @media screen and (max-width: 766px) {
        margin-left: 0;
      }
    }
  }

  li {
    gap: 0;

    &::before {
      min-width: 8px;
      width: 8px;
      height: 8px;
      margin-right: 16px;
    }
  }
}

.how-it-works {
  @media screen and (max-width: 766px) {
    &__button-open {
      margin: 32px 8px;
      padding: 24px 8px;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.30) 100%), #F6F7F6;
      border-radius: 16px;
      box-shadow: $box-shadow-main;

      &-text {
        padding: 0 8px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        font-weight: 700;
        cursor: pointer;
        transition: all 0.5s;

        &::after {
          content: "";
          width: 15px;
          height: 6px;
          background: url(~assets/images/accordion.svg) center/contain no-repeat;
          transition: all 0.5s;
        }
  
        &.other-color {
          color: #ababab;
  
          &::after {
            background: url(~assets/images/accordiongray.svg) center/contain
              no-repeat;
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
