@use "../../../utils.scss" as *;

.articles {
  position: relative;
  
  border-radius: 16px;
  box-shadow: $box-shadow-light;
  transition: 0.3s;

  &:hover {
    transform: scale(1.01);
    box-shadow: $box-shadow-hover;
  }

  &__container {
    border-radius: 16px;
  }
}
