@use "../../../../../../utils.scss" as *;

.avito-category-input {
  background-color: $gray;
  border-radius: 10px;
  transition: all 0.4s;
  touch-action: pan-y;

  @media screen and (max-width: 766px) {
    border-radius: 8px;
  }

  &:focus-visible {
    outline: $yellow-main auto 1px !important;

    @media screen and (max-width: 766px) {
      outline: $yellow-main auto 2px;
    }

    & {
      outline-color: $yellow-main;
      outline-color: -webkit-focus-ring-color;
      outline-color: -moz-focusring-color;
    }
  }

  & > .avito-category-input__items-container {
    overflow: hidden;
    box-sizing: content-box;
    transition: all 0.4s;

    height: 0;
  }
  &_expanded {
    & .avito-category-input__title-container::after {
      transform: rotate(180deg);
    }
  }

  &__item {
    padding: 8px 28px;
    font-size: 16px;

    cursor: pointer;

    @media screen and (max-width: 766px) {
      font-size: 14px;
    }

    &:first-child {
      margin-top: 12px;

      @media screen and (max-width: 766px) {
        margin-top: 8px;
      }
    }

    &:last-child {
      margin-bottom: 16px;

      @media screen and (max-width: 766px) {
        font-size: 14px;
      }
    }

    &:hover {
      background-color: $white;
    }
  }

  &__title {
    display: flex;
    grid-gap: 15px;
    color: $black;

    @media screen and (max-width: 766px) {
      font-size: 14px;
    }

    &-container {
      height: 54px;
      padding: 0 28px;
      display: flex;
      column-gap: 16px;
      justify-content: space-between;
      align-items: center;

      cursor: pointer;

      @media screen and (max-width: 766px) {
        padding: 0 16px;
        height: 42px;
      }

      &::after {
        content: "";
        min-width: 14px;
        max-width: 14px;
        height: 8px;
        background: center/contain url(~assets/images/accordion.svg) no-repeat;
        transition: all 0.4s;
        opacity: 0.35;
      }

      &.error {
        border-radius: 10px;
        border: 2px solid $red;
        &:focus {
          border-color: $red;
        }
      }
    }
  }
}
