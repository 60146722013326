@use "../../../../../utils.scss" as *;

.project-setup__screen {
  position: relative;
  max-width: 410px;
  width: 100%;
  height: 185px;
  flex-shrink: 0;
  cursor: pointer;

  &.no-fullscreen {
    cursor: default;
    pointer-events: none;
  }

  &.other-size {
    max-width: 590px;
    height: 290px;
  }

  &-icon {
    position: absolute;
    right: 12px;
    bottom: 8px;
    z-index: 1;
    width: 37px;
    height: 37px;
    background: url(~assets/images/replacementscreengray.svg)
      rgba(255, 255, 255, 0.6) center no-repeat;
    border-radius: 6px;

    &.no-fullscreen {
      display: none;
    }
  }

  &-placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(147, 147, 147, 1);
    background-color: $gray-disabled;
    border-radius: 8px;
    cursor: default;
  }

  &-image-wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    & img {
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 12px;
      -o-object-fit: contain;
      object-fit: contain;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
      border-radius: 12px;
    }
  }
}
