@use "../../utils.scss" as *;

/* * {
  outline: 1px solid red;
} */

.projects {
  margin: 0 auto 70px;
  min-height: 100%;
  max-width: $screenminwidth;
  width: 100%;

  @media screen and (max-width: 766px) {
    margin: 0 auto 48px;
  }

  &__header {
    padding: 36px 0 24px;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 766px) {
      padding: 32px 8px 16px;
    }

    &-title {
      font-size: 32px;
      font-weight: 700;

      @media screen and (max-width: 766px) {
        font-size: 24px;
      }
    }

    &-add-button {
      max-width: 210px;

      @media screen and (max-width: 766px) {
        position: fixed;
        right: 16px;
        bottom: 124px;
        z-index: 10;
        width: 44px;
        height: 44px;
      }

      &:hover {
        background-color: $white;
      }
    }

    &-scroll-button {
      @media screen and (max-width: 766px) {
        position: fixed;
        right: 16px;
        bottom: 174px;
        z-index: 10;
        margin: 0 6px;
        width: 32px;
        height: 32px;
        background: url(~assets/images/accordion.svg) $white center/16px no-repeat;
        border-radius: 85px;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
        transform: rotate(180deg);
      }
    }

    .button-with-icon {
      &__icon {
        width: 18px;
        height: 18px;
      }
    }
  }

  &__tabs-container {
    width: 100%;
  }

  &__tabs {
    max-width: 720px;
    display: flex;
    background: $gray-disabled;
    border-radius: 16px 16px 0 0;

    @media screen and (max-width: 766px) {
      position: fixed;
      left: 0;
      bottom: 64px;
      z-index: 10;
      width: 100%;

      .projects__tab:nth-child(1) {
        order: 1;
      }
      
      .projects__tab:nth-child(2) {
        order: 0;
      }
      
      .projects__tab:nth-child(3) {
        order: 2;
      }
    }
  }

  &__tab {
    padding: 16px;
    flex: 1;
    color: #939393;
    cursor: pointer;

    &-active {
      font-weight: 700;
      color: $black;
      background: $white;
      border-radius: 16px 16px 0 0;
    }

    &-content {
      padding: 32px 16px;
      background: $white;
      border-radius: 0 32px 32px 32px;
      box-shadow: $box-shadow-main;

      @media screen and (max-width: 766px) {
        margin: 0 8px;
        padding: 16px 8px 24px;
        width: auto;
        border-radius: 16px;
      }
    }

    &-executor {
      margin-bottom: 24px;

      & p {
        margin-bottom: 12px;
        font-weight: 700;
        line-height: 140%;
        color: #ababab;

        @media screen and (max-width: 766px) {
          margin-bottom: 10px;
          font-size: 14px;
          line-height: 120%; 
        }
      }

      &-buttons {
        display: flex;
      }

      &-button {
        &-humans,
        &-bots {
          max-width: 130px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 1 0 0;
          font-size: 14px;
          line-height: 120%;
          background: #f9f9fb;
          border: 1px solid #f0f0f0;

          @media screen and (max-width: 766px) {
            max-width: none;
          }

          &.active {
            border: 1px solid #ffe14b;
            background: #fff3b4;
          }
        }

        &-humans {
          border-right: none;
          border-radius: 12px 0 0 12px;
        }

        &-bots {
          border-left: none;
          border-radius: 0 12px 12px 0;
        }
      }
    }
  }
}
