.standard-columns {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 52px;

  @media screen and (max-width: 766px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px 8px;
  }
}
