@use "../../../../../utils.scss" as *;

.header-article {
  height: 386px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 16px;
  box-shadow: $box-shadow-light;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.95) 100%
  );
  background-size: cover;
  background-position: center;

  &__container {
    max-width: 495px;
    width: 100%;
  }

  &__info {
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    color: $gray;
  }

  &__title {
    font-size: 32px;
    font-weight: 900;
    line-height: 120%;
    text-align: center;
    color: $white;
  }

  &__subcontainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__description {
    padding: 0 15px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    &-title {
      font-size: 24px;
      font-weight: 700;
    }
    &-text {
      line-height: 140%;
    }
  }

  &__param {
    margin-top: 8px;
    padding: 16px 32px;
    border-radius: 16px;

    background-color: $yellow-sub;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    &-text {
      font-weight: 700;
    }
    &-time {
      font-weight: 400;
    }
  }

  &__tags {
    display: flex;
    gap: 8px;

    &-tag {
      padding: 5px 12px;
      width: fit-content;
      height: 25px;

      background-color: $black;
      border-radius: 7px;

      font-size: 12px;
      color: $white;
    }
  }
}

.with-description {
  display: grid;
  grid-template-columns: 543px 619px;
  gap: 8px;
  height: 386px;

  &-subcontainer {
    padding: 32px 32px 24px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    border-radius: 16px;
    background-color: $yellow-sub;
  }

  &-param {
    margin: 0;
    padding: 0;
    border-radius: 0;
    background-color: inherit;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
}

.dot::after {
  content: "";
  margin: 0 8px;

  width: 6px;
  height: 6px;
  display: flex;
  border-radius: 50%;
  background-color: #fff;
}
