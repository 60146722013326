@use "../../../../utils.scss" as *;

.selected-line {
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 38px;
  border-bottom: 3px solid $yellow-main;
  border-radius: 16px;

  @media screen and (max-width: 766px) {
    bottom: -6px;
    height: auto;
  }
}
