@use "../../../../utils.scss" as *;

.projects-active-row {
  &__title {
    &-container {
      padding: 3px 10px;
      display: flex;
      align-items: center;
      gap: 16px;
      background-color: $gray;
      border-radius: 12px;

      @media screen and (max-width: 766px) {
        padding: 8px;
        flex-wrap: wrap;
        gap: 8px;
      }
    }

    &-section {
      display: flex;
      align-items: center;
      gap: 8px;

      &-icon {
        padding: 15.5px 11.5px;
        width: 17px;
        height: 9px;
        background: rgba(242, 241, 237, 0.3);
        border-radius: 8px;
        box-shadow: $box-shadow-light;
        flex-shrink: 0;
        transition: transform 0.5s;
        cursor: pointer;

        &.expanded {
          transform: rotate(180deg);
        }

        &.disabled {
          opacity: 0.1;
        }
      }
    }
  }

  &__button-edit {
    padding: 4px;
    width: 30px;
    height: 30px;
    background: url(~assets/images/button-edit.svg) rgba(242, 241, 237, 0.3)
      center/cover no-repeat;
    background-size: 22px;
    border-radius: 4px;
    box-shadow: $box-shadow-light;
  }

  &__button-config {
    margin-left: 8px;
    padding: 4px;
    width: 30px;
    height: 30px;
    background: url(~assets/images/cogicon.svg) rgba(242, 241, 237, 0.3)
      center/cover no-repeat;
    background-size: 22px;
    border-radius: 4px;
    box-shadow: $box-shadow-light;
  }

  &__config {
    display: flex;
    &-icon {
      padding: 11px 18px;
      width: 4px;
      height: 18px;
    }
  }

  &__no-config {
    margin-left: 8px;
    padding: 4px;
    width: 30px;
    height: 30px;
  }

  &__info {
    display: flex;
    align-items: center;
    gap: 16px;

    @media screen and (max-width: 766px) {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-items: center;
      gap: 8px 12px;
    }

    &-status {
      padding: 6px 10px;
      max-width: 180px;
      width: 100%;
      height: 30px;
      font-size: 14px;
      font-weight: 700;
      color: $red-failed;
      text-align: center;
      background: rgba(255, 115, 115, 0.1);
      border-radius: 42px;

      @media screen and (max-width: 766px) {
        padding: 6px 4px;
        height: auto;
        font-size: 13px;
      }
    }

    &-strategy {
      width: 50px;
      font-size: 14px;
    }

    &-city {
      max-width: 100px;
      min-width: 100px;
      font-size: 14px;
      font-weight: 500;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @media screen and (max-width: 766px) {
        max-width: none;
        min-width: auto;
      }
    }

    &-price {
      width: 65px;
      font-size: 14px;
      font-weight: 700;

      @media screen and (max-width: 766px) {
        width: max-content;
      }
    }

    &_equipment {
      display: flex;
      align-items: center;
      gap: 16px;

      @media screen and (max-width: 766px) {
        margin-top: 16px;
        padding-top: 16px;
        width: 100%;
        border-top: 1px solid #d7e2e4;
      }
    }
  }

  &__extend-button {
    padding: 0 16px;
    height: 34px;
    font-size: 14px;
    line-height: 120%;
    border-radius: 8px;
  }

  &__buttons {
    margin-left: auto;

    .tooltips {
      &__description {
        padding: 3px 10px;
        top: 0;
        left: -90%;
        transform: translateX(-90%);
        width: 340px;
        white-space: normal;

        &::after {
          display: none;
        }
      }
    }
  }

  &__stop-icon {
    padding: 6px;
    width: 40px;
    height: 40px;
    background: url(~assets/images/stop.svg) center no-repeat;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  .tooltips {
    font-weight: 400;
    pointer-events: all;
  }
}
