@use "../../../utils.scss" as *;

.invited {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: $white;
  border-radius: 24px;
  box-shadow: $box-shadow-main;

  @media screen and (max-width: 766px) {
    margin: 24px 16px 0;
    padding: 24px 16px;
    gap: 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__sort {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    line-height: 120%;

    &_other {
      display: flex;
      align-items: center;
      gap: 8px;

      &-params {
        padding: 6px 16px;
        max-width: fit-content;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 6px;
        background: $gray;
        border-radius: 10px;
        cursor: pointer;

        &::after {
          content: "";
          width: 10px;
          height: 6px;
          background: url(~assets/images/accordion.svg) center/contain no-repeat;
        }
      }
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 120%;
  }

  &__history {
    min-height: 300px;

    &.other-height {
      min-height: auto;
    }

    @media screen and (max-width: 766px) {
      min-height: auto;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }

  &__empty {
    padding-top: 60px;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: center;
    border-bottom: 1px solid $gray-disabled;

    &-title {
      font-size: 24px;
      font-weight: 700;
      color: #939393;

      @media screen and (max-width: 766px) {
        font-size: 18px;
      }
    }

    &-text {
      margin: 0 auto;
      max-width: 470px;
      width: 100%;
      color: #939393;

      @media screen and (max-width: 766px) {
        font-size: 14px;
        line-height: 120%;
      }
    }
  }
}
