@use "../../../../utils.scss" as *;

.referral__information {
  position: relative;
  padding: 72px 32px;
  max-width: 700px;
  width: 100%;
  background: url(~assets/images/referral/bg.svg) center/cover no-repeat;
  border-radius: 32px;
  flex-shrink: 0;

  @media screen and (max-width: 766px) {
    padding: 56px 8px 30px 8px;
    display: flex;
    flex-direction: column;
    gap: 26px;
    background: url(~assets/images/referral/bg-mobile.svg) center/cover
      no-repeat;
    border-radius: 0px 0px 24px 24px;
  }

  &-title {
    font-size: 40px;
    font-weight: 400;
    line-height: 100%;

    @media screen and (max-width: 766px) {
      font-size: 32px;
      line-height: 110%;
      text-align: center;
    }
  }

  &-text {
    position: absolute;
    left: 0;
    bottom: 42px;
    padding: 24px 42px 32px 32px;
    max-width: 446px;
    width: 100%;
    font-size: 22px;
    font-weight: 300;
    line-height: 100%;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 0px 40px 40px 0px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);

    @media screen and (max-width: 766px) {
      margin: 0 auto;
      position: static;
      padding: 16px 24px;
      font-size: 18px;
      line-height: 140%;
      text-align: center;
      border-radius: 32px;
    }

    & span {
      display: flex;
      align-items: flex-start;
      font-size: 56px;
      font-style: italic;
      font-weight: 500;
      line-height: 100%;

      .faq {
        font-style: normal;
        background: url(~assets/images/faqEmpty.svg) center/contain no-repeat;

        @media screen and (max-width: 766px) {
          width: 24px;
          height: 24px;
        }
      }

      @media screen and (max-width: 766px) {
        justify-content: center;
        font-size: 48px;
        text-align: center;
      }
    }
  }
}
