@use "../../utils.scss" as *;

.dropdownmenu {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  z-index: 15;
  transition: all 0.4s;

  @media screen and (max-width: 766px) {
    padding-top: 16px;
    padding-bottom: 16px;
    background: $white;
    overflow-y: scroll;
    overscroll-behavior: none;
  }

  &::after {
    content: "";
    position: absolute;
    top: -8px;
    right: 32px;
    width: 18px;
    height: 9px;
    background: url(~assets/images/triangle.svg) center/cover no-repeat;
    transform: rotate(180deg);

    @media screen and (max-width: 766px) {
      display: none;
    }
  }

  @media screen and (max-width: 766px) {
    &__user {
      padding: 8px 16px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: $gray;
      box-shadow: none;

      &-info {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      &-avatar {
        position: relative;
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $gray-disabled;
        border-radius: 16px;
        flex-shrink: 0;

        &::before {
          content: attr(data-initials);
          position: absolute;
          font-size: 28.8px;
          font-weight: 700;
          line-height: 140%;
          color: rgba(0, 0, 0, 0.2);
        }
      }

      &-name {
        max-width: 212px;
        width: 100%;
        font-size: 16px;
        font-weight: 700;
        line-height: 140%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  &__balance {
    padding: 16px 8px;
    width: 100% !important;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 16px;

    @media screen and (max-width: 766px) {
      padding: 24px 16px;
      background: $gray;
      box-shadow: none;
    }

    &-total {
      padding: 12px 16px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      background-color: $gray;
      border-radius: 24px;

      @media screen and (max-width: 766px) {
        padding: 0;
        gap: 16px;
      }
    }

    &-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: 766px) {
        gap: 8px;
      }
    }

    @media screen and (max-width: 766px) {
      &-amount {
        padding: 12px 24px;
        flex: 1;
        text-align: center;
        background-color: $gray-disabled;
        border-radius: 24px;
      }
    }

    &-text {
      font-size: 12px;
      line-height: 140%;

      @media screen and (max-width: 766px) {
        color: rgba(0, 0, 0, 0.3);
      }
    }

    &-digit {
      margin-top: 2px;
      font-size: 24px;
      font-weight: 700;
      line-height: 110%;

      @media screen and (max-width: 766px) {
        margin-top: 0;
      }
    }
  }

  &__button {
    padding: 8px 24px;
    max-width: 160px;
    width: 100%;
    height: 44px;

    @media screen and (max-width: 766px) {
      max-width: none;
      height: 38px;
      font-size: 16px;
    }
  }

  &__link {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 766px) {
      padding: 16px;
      width: 100%;
      background: $gray;
      box-shadow: none;
    }

    &-item {
      margin-top: 8px;
      padding: 8px 8px 16px;
      display: flex;
      align-items: flex-end;
      border-bottom: 1px solid $gray-disabled;
      transition: all 0.3s;

      @media screen and (max-width: 766px) {
        padding: 8px 8px 12px;
        gap: 8px;
        font-size: 15px;
      }

      &:hover {
        color: $yellow-main;
        background-color: $gray;
        border-radius: 8px;
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        padding: 8px;
        border-bottom: none;
      }

      .badge {
        margin-left: auto;
      }
    }

    @media screen and (max-width: 766px) {
      &-icon {
        width: 18px;
        height: 18px;
        flex-shrink: 0;

        &.projects-icon {
          background: url(~assets/images/header/projectsIcon.svg) center/contain
            no-repeat;
        }

        &.referral-icon {
          background: url(~assets/images/header/referral.svg) center/contain
            no-repeat;
        }
      }
    }
  }

  &__notifications {
    padding: 12px 16px;
    width: 100% !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 16px !important;

    @media screen and (max-width: 766px) {
      padding: 24px;
      gap: 8px;
      border-radius: 24px;
      background: $gray;
      box-shadow: none;
    }

    &-container {
      padding: 12px 0;
      display: flex;
      align-items: center;
      gap: 8px;

      @media screen and (max-width: 766px) {
        padding: 0;

        & p {
          font-size: 15px;
        }
      }
    }

    &-icon {
      width: 22px;
      height: 22px;
      background: url(~assets/images/header/bell-on.svg) center no-repeat;
      flex-shrink: 0;
    }

    .toggler {
      width: 47px;
      height: 28px;

      &__thumb {
        width: 23px;
        height: 23px;
      }

      &._active .toggler__thumb {
        transform: translateX(17px);
      }
    }
  }

  &__exit {
    padding: 16px 8px;
    width: 100% !important;
    border-radius: 16px !important;

    @media screen and (max-width: 766px) {
      padding: 12px 24px;
      background: inherit;
      border-radius: 0;
      box-shadow: none;
    }

    &-container {
      padding: 8px 16px;
      display: flex;
      align-items: center;
      gap: 8px;

      cursor: pointer;
      transition: all 0.3s;

      @media screen and (max-width: 766px) {
        padding: 0;
      }

      &:hover {
        background-color: $gray;
        border-radius: 10px;
      }

      & p {
        font-size: 14px;
        color: $pink-dark;
      }
    }

    &-icon {
      width: 22px;
      height: 22px;
      background: url(~assets/images/exit.svg) center no-repeat;
      flex-shrink: 0;
    }
  }
}
