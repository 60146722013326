@use "../../../../../utils.scss" as *;

.definition {
	max-width: 700px;
	width: 100%;
	margin-bottom: 8px;
	padding: 40px 30px;
	border-radius: 8px;
	background-color: $blue-light;

	&-bold {
		font-size: 20px;
		font-weight: 700;
	}

	&-text {
		font-size: 20px;
		font-weight: 400;
		line-height: 140%;
	}
}