.status-equipment {
  display: flex;
  align-items: center;
  gap: 8px;

  @media screen and (max-width: 766px) {
    width: 100%;
  }

  &__info {
    padding: 8px 12px;
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 14px;
    line-height: 120%;
    background: rgba(66, 180, 89, 0.08);
    border-radius: 16px;

    @media screen and (max-width: 766px) {
      padding: 8px;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
    }

    &.not-paid {
      background: rgba(244, 67, 54, 0.08);
    }

    &-paid {
      display: flex;
      gap: 4px;
    }
  }

  & span {
    color: #939393;
  }

  & button {
    padding: 0 16px;
    max-height: 33px;
    border-radius: 8px;

    & span {
      color: inherit;
    }
  }
}
