.worker-badge {
  padding: 2px 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  line-height: 120%;
  cursor: pointer;

  @media screen and (max-width: 766px) {
    &__bg {
      position: fixed;
      inset: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  &.pro-badge {
    color: #fff;
    background: linear-gradient(
      87deg,
      #1e1e1e 11.29%,
      #1e1e1e 22.87%,
      #474747 96.83%
    );
    border-radius: 32px;
  }

  &.new-badge {
    color: #1175d4;
    background: #bdd5ff;
    border-radius: 32px;

    .hint {
      min-width: 300px;
    }
  }

  .hint {
    top: 80px;
    right: 0;
    max-width: none;
    min-width: 250px;
    color: #1e1e1e;

    @media screen and (max-width: 766px) {
      position: fixed;
      top: auto;
      right: auto;
      bottom: 0;
      left: 0;
      transform: translateX(0);
      padding: 18px;
      min-width: auto;
      max-width: none;
      border-radius: 16px 16px 0px 0px;
      box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.04);
    }

    &__title {
      margin-bottom: 8px;

      @media screen and (max-width: 766px) {
        font-size: 24px;
        line-height: 120%;
        text-align: center;

        & span {
          font-size: 70px;
          line-height: 140%;
        }
      }
    }

    &__text {
      font-weight: 400;

      @media screen and (max-width: 766px) {
        font-size: 14px;
        line-height: 120%;
        text-align: center;
      }
    }

    &__button {
      margin-top: 32px;
      margin-bottom: 24px;
      height: 44px;
      color: #1e1e1e;
      text-align: center;
      background: #ffe14b;
      border-radius: 16px;
    }
  }
}
