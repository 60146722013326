@use "../../../../utils.scss" as *;

.form-step5 {
  margin: 24px auto;
  padding: 32px 40px;
  max-width: 530px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $white;
  border-radius: 24px;
  box-shadow: $box-shadow-main;

  @media screen and (max-width: 766px) {
    margin: 0;
    padding: 16px 16px 44px;
    position: fixed;
    left: 0;
    bottom: 64px;
    max-width: none;
    width: 100%;
    border-radius: 0;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.04);
  }

  &-container {
    margin-bottom: 24px;
    padding-bottom: 24px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    border-bottom: 1px solid $blue-dirty;

    @media screen and (max-width: 766px) {
      margin-bottom: 16px;
      padding-bottom: 16px;
      flex-direction: column;
      gap: 16px;
      text-align: center;
      border-bottom: none;
    }
  }

  &-icon {
    width: 167px;
    height: 147px;
    background: url(~assets/images/dino/dino-success.svg) center/contain no-repeat;
    flex-shrink: 0;

    @media screen and (max-width: 766px) {
      width: 324px;
      height: 135px;
      background: url(~assets/images/dino/dino-success-mobile.svg) center/contain no-repeat;
    }
  }

  &-title {
    margin-bottom: 8px;
    font-size: 24px;
    font-weight: 700;
  }

  &-text {
    font-size: 14px;
  }

  & a {
    padding: 16px 0;
    width: 100%;
    height: 54px;
    background: $yellow-main;
    border-radius: 16px;
    text-align: center;

    @media screen and (max-width: 766px) {
      margin-bottom: 8px;
    }
  }

  &-button-close {
    width: 100%;
    background-color: $yellow-sub;
    border: 2px solid $yellow-sub;
  }
}