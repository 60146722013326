@use "../../utils.scss" as *;

.footer-place-logo {
  position: relative;
  width: 100%;
  max-height: 355px;
  height: 100%;
  background: linear-gradient(125deg, $light-black 50%, #484848 50%);
  border-radius: 16px;
  transform-origin: center;
  color: $white;

  @media screen and (max-width: 766px) {
    min-height: 325px;
    max-height: 325px;
  }

  &__container {
    padding: 32px 0 36px 26px;
  }

  &__title {
    margin-bottom: 4px;
  }

  &__text {
    margin-bottom: 16px;
    max-width: 240px;
    width: 100%;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
  }

  & ul {
    & li {
      margin-left: 8px;
      column-gap: 10px;

      @media screen and (max-width: 766px) {
        margin-left: 6px;
        column-gap: 8px;
        font-size: 14px;
      }

      &::before {
        margin-top: 8px;
        min-width: 5px;
        width: 5px;
        height: 5px;
        background-color: $white;

        @media screen and (max-width: 766px) {
          margin-top: 7px;
          min-width: 4px;
          width: 4px;
          height: 4px;
        }
      }
    }
  }

  &__check {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 190px;
    height: 100%;
    background: url(~assets/images/footer/check.svg) center/contain no-repeat;
  }

  &__icon {
    position: absolute;
    bottom: 0;
    padding: 16px 8px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 4px;
    background-color: $white;
    border-radius: 16px;
    box-shadow: $box-shadow-main;

    @media screen and (max-width: 766px) {
      min-height: 134px;
      max-height: 134px;
    }

    &-first {
      margin-bottom: 4px;
      padding-bottom: 8px;
      width: 100%;
      border-bottom: 1px solid $gray-disabled;

      &-logo {
        width: 304px;
        height: 33px;
      }
    }

    &-logo {
      max-width: 40px;
      width: 100%;
      height: 25px;
      flex-shrink: 0;

      @media screen and (max-width: 766px) {
        max-width: 35px;
      }
    }
  }
}
