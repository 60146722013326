@use "../../utils.scss" as *;

.banner-step {
  &__title {
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 120%;
    color: #939393;

    @media screen and (max-width: 766px) {
      margin-bottom: 12px;
      font-size: 18px;
    }

    & span {
      font-weight: 700;
      color: $black;

      &:first-child {
        font-weight: 400;
        color: #c7c7c7;
      }
    }

    &.banner-third-step {
      font-style: italic;
      font-weight: 700;
      color: $black;
    }

    &.banner-project-left,
    &.banner-project-right {
      margin-bottom: 4px;
      font-size: 16px;
      font-style: italic;
      font-weight: 500;
      line-height: 130%;
      color: $black;
    }
  }

  &__info_points {
    display: flex;
    align-items: center;
    gap: 8px;

    @media screen and (max-width: 766px) {
      flex-direction: column;
    }

    .info-point {
      padding: 0;
      flex: 1;
      gap: 6px;
      border: none;

      @media screen and (max-width: 766px) {
        gap: 4px;
      }

      &__icon {
        width: 24px;
        height: 24px;

        @media screen and (max-width: 766px) {
          width: 20px;
          height: 20px;
        }
      }

      &__text {
        & h2 {
          font-size: 14px;
          font-style: italic;
          font-weight: 500;
          line-height: 140%;

          & span {
            font-size: 14px;
            font-weight: 300;
            line-height: 140%;
          }
        }

        & p {
          font-size: 14px;
          font-weight: 300;
          line-height: 140%;

          & span {
            font-style: italic;
            font-weight: 500;
          }
        }
      }
    }
  }

  &__text {
    font-size: 14px;
    font-weight: 300;
    line-height: 140%;

    & span {
      font-style: italic;
      font-weight: 500;
    }
  }

  .button {
    margin-top: 16px;
    width: 100%;

    @media screen and (max-width: 766px) {
      margin-top: 12px;
      height: 44px;
    }
  }

  &__link {
    color: $yellow-dark;
    text-decoration: underline;
  }
}
