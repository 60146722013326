@use "../../../utils.scss" as *;

.dropdown-links {
  &__sites {
    max-width: 880px;
    width: 100%;
    display: flex;
    gap: 16px;

    @media screen and (max-width: 766px) {
      flex-direction: column;
    }
  }

  &__title {
    margin-bottom: 16px;
    font-weight: 700;

    @media screen and (max-width: 766px) {
      text-align: center;
    }
  }

  &__services {
    padding: 16px 20px;
    width: 100%;
    height: fit-content;
    background: rgba(242, 241, 237, 0.3);
    border-radius: 16px;
    transition: all 0.4s;

    @media screen and (max-width: 766px) {
      margin-bottom: 14px;
      order: 2;
      padding: 0;
      background: none;
      border-radius: 0;
    }

    &-item {
      margin-bottom: 8px;
      padding-top: 8px;
      padding-bottom: 8px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      border-bottom: 1px solid $gray-disabled;

      @media screen and (max-width: 766px) {
        padding: 8px;
        font-size: 14px;
      }

      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
      }

      &:hover {
        color: $yellow-dark;
      }

      &-link {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      &-img {
        display: flex;
        align-items: center;
        gap: 3px;

        &-logo, &-icon {
          background-color: $white;
          border-radius: 10px;
          box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.12);
          flex-shrink: 0;
        }

        &-logo {
          width: 28px;
          height: 28px;
        }

        &-icon {
          padding: 4px;
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}