.project-counters {
  display: flex;

  &__activity,
  &__reviews {
    padding: 3px 8px 3px 4px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid rgba(240, 240, 240, 1);

    @media screen and (max-width: 766px) {
      padding: 6px;
    }

    &-icon {
      width: 22px;
      height: 22px;
      flex-shrink: 0;

      @media screen and (max-width: 766px) {
        width: 16px;
        height: 16px;
      }
    }
  }

  &__activity {
    min-width: 70px;
    border-right: none;
    border-radius: 8px 0 0 8px;

    &.with-border {
      min-width: 110px;
      border: 1px solid rgba(240, 240, 240, 1);
      border-radius: 8px;
    }

    &.test-style {
      justify-content: center;
      line-height: 160%;
      text-transform: uppercase;
    }
  }

  &__reviews {
    min-width: 60px;
    border-left: none;
    border-radius: 0 8px 8px 0;
  }
}
