@use "../../utils.scss" as *;

.footer-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  border-radius: 16px;
  box-shadow: $box-shadow-main;

  @media screen and (max-width: 766px) {
    padding: 32px 24px;
    justify-content: center;
  }

  &__icon {
    padding: 20px;
    width: 140px;
    height: 140px;
    background-color: $white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;

    @media screen and (max-width: 766px) {
      display: none;
    }
  }
}
