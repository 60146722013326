@use "../../../../utils.scss" as *;

.project-property-column {
  position: relative;
  max-width: 190px;
  width: max-content;

  @media screen and (max-width: 766px) {
    max-width: 140px;
  }

  &.finished {
    color: rgba(0, 0, 0.12, 0.5);
  }

  &__title {
    min-height: 22px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    color: rgba(0, 0, 0.12, 0.5);

    &-icon {
      width: 22px;
      height: 22px;

      @media screen and (max-width: 766px) {
        width: 18px;
        height: 18px;
      }
    }

    .faq {
      margin-left: 0;
      width: 22px;
      height: 22px;

      @media screen and (max-width: 766px) {
        .plate {
          top: -140px !important;
          left: auto !important;
          right: 6px !important;
          color: #1e1e1e;
        }
      }
    }
  }

  &__icon {
    margin-right: 4px;
    padding: 4px;
    width: 17px;
    height: 17px;
  }

  &__text {
    margin-top: 16px;
    display: flex;
    align-items: center;

    @media screen and (max-width: 766px) {
      margin-top: 8px;
      font-size: 14px;
    }

    &.status-approved {
      font-weight: 700;
      color: $green;
    }

    &.status-rework,
    &.status-failed {
      font-weight: 700;
      color: $red-failed;
    }

    &.status-new,
    &.status-run,
    &.status-ready,
    &.status-replace {
      font-weight: 700;
    }

    &.status-finished {
      color: rgba(0, 0, 0.12, 0.5);
    }

    &.hidden-text {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .hint {
      padding: 16px;
      top: 60px;
      right: -80px;
      background: $white;

      @media screen and (max-width: 766px) {
        top: -85px;
        left: 50%;
        transform: translateX(-35%);
        z-index: 1;
        padding: 10px;
        max-width: none;
        min-width: 220px;
      }

      &__title {
        margin: 0;
      }

      &__text {
        white-space: break-spaces;
        display: flex;
        flex-direction: column;
        gap: 4px;
      }

      &__button {
        margin-top: 16px;
        font-weight: 400;
        line-height: 140%;
        color: #EEB010;
        text-align: left;
      }
    }
  }

  &__icons {
    display: flex;
    align-items: center;
    gap: 8px;
    mix-blend-mode: luminosity;
  }

  &__timer {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: $pink-dark !important;

    &-icon {
      margin-right: 4px;
      padding: 5px;
      width: 16px;
      height: 16px;
      background: url(~assets/images/timerLeft.svg) center/contain no-repeat;
      background-size: 11px;
      flex-shrink: 0;
    }
  }
}
