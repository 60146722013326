@use "../../../../../../../utils.scss" as *;

.projects-active-report-auction-visibility {
  position: relative;
  padding: 7px 14px;
  max-width: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid $blue-dirty;
  border-radius: 16px;

  @media screen and (max-width: 766px) {
    padding: 12px;
    max-width: none;
    background: $gray;
  }

  &__title {
    margin-top: 6px;
    font-size: 13px;
    color: $light-black;
    text-align: center;

    @media screen and (max-width: 766px) {
      margin-top: 0;
      margin-bottom: 8px;
      color: #ABABAB;
    }
  }
}
