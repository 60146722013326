@use "../../utils.scss" as *;

.avito-upload-file {
  margin-top: 16px;
  display: flex;

  @media screen and (max-width: 766px) {
    margin-top: 8px;
    max-width: none;
    width: 100%;
    justify-content: center;
  }

  &__input {
    margin: 0;
    padding: 0;
    width: 0;
    height: 0;
    line-height: 0;
    opacity: 0;
    overflow: hidden;
  }

  &__success {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 766px) {
      margin-top: 0;
      flex-direction: column;
      justify-content: center;
      gap: 8px;
    }

    &-upload {
      padding: 0 16px;
      max-width: 260px;
      width: 100%;
      height: 54px;

      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      background-color: rgba(87, 192, 88, 0.1);
      border-radius: 16px;

      @media screen and (max-width: 766px) {
        padding: 0 30px;
        height: 40px;
      }

      &-icon {
        width: 16px;
        height: 16px;
        background: url(~assets/images/checkgreen.svg) center no-repeat;
        flex-shrink: 0;
      }

      & p {
        font-weight: 700;
        color: $lightgreen;

        @media screen and (max-width: 766px) {
          font-size: 14px;
          text-align: center;
        }
      }
    }
  }

  &.error {
    border: 2px solid $red;

    &:focus {
      border-color: $red;
    }
  }

  .button-with-icon {
    &.avito-upload-file__add-button {
      max-width: 400px;

      @media screen and (max-width: 766px) {
        height: 40px;
        font-size: 14px;
      }
    }

    &.avito-upload-file__delete-button {
      max-width: 125px;
      color: $red-failed;
      background-color: rgba(234, 67, 53, 0.1);
      border: 2px solid #f7e6e7;
      transition: all 0.5s;

      &:hover {
        background-color: $white;
        border: 2px solid rgba(234, 67, 53, 0.5);
      }

      @media screen and (max-width: 766px) {
        max-width: none;
        height: 40px;
        font-size: 14px;
      }
    }

    &__icon {
      width: 20px;
      height: 17px;
    }
  }
}
