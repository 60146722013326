@use "../../../../../utils.scss" as *;

.referral-withdrawal-modal {
  padding: 32px 24px;
  max-width: 650px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 32px;

  @media screen and (max-width: 766px) {
    margin: auto 0 0;
    padding: 16px 16px 32px;
    gap: 8px;
    border-radius: 16px 16px 0 0;
  }

  .popup {
    &__button-close {
      display: none;
    }

    &__header {
      justify-content: center;

      &-title {
        font-size: 32px;
        line-height: 120%;

        @media screen and (max-width: 766px) {
          font-size: 24px;
        }
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media screen and (max-width: 766px) {
      margin-bottom: 8px;
      gap: 16px;
    }
  }

  &__method {
    display: flex;
    flex-direction: column;
    gap: 8px;

    & p {
      font-size: 14px;
      line-height: 140%;
      text-align: center;
    }

    &-selector {
      display: flex;
      align-items: center;
      gap: 8px;

      @media screen and (max-width: 766px) {
        align-items: stretch;
      }
    }

    &-button {
      padding: 0;
      flex: 1;
      gap: 8px;
      font-size: 14px;
      font-weight: 700;
      background-color: $gray;
      border: none;
      border-radius: 8px;

      @media screen and (max-width: 766px) {
        padding: 12px 8px;
        height: auto;
        flex-direction: column;
        gap: 4px;
        font-weight: 600;
      }

      & span {
        color: #939393;
      }

      &.active {
        background: rgba(238, 176, 16, 0.1);
        border: 1px solid #eeb010;
      }

      .button-with-icon__icon {
        width: 24px;
        height: 24px;

        @media screen and (max-width: 766px) {
          width: 22px;
          height: 22px;
        }
      }
    }
  }

  &__balance {
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(215, 226, 228, 0.3);
    border-radius: 8px;

    @media screen and (max-width: 766px) {
      margin-bottom: 0;
      padding: 8px 16px;
    }

    & p {
      line-height: 140%;

      @media screen and (max-width: 766px) {
        font-size: 14px;
      }

      &:last-child {
        display: flex;
        align-items: stretch;
        gap: 6px;
        font-weight: 700;

        @media screen and (max-width: 766px) {
          align-items: center;
          font-size: 16px;
        }

        &::after {
          content: "";
          display: inline-block;
          width: 16px;
          height: 17px;
          background: url(~assets/images/bonus.svg) center/contain no-repeat;
        }
      }
    }
  }

  &__input {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    &-title {
      font-size: 14px;
      line-height: 140%;

      @media screen and (max-width: 766px) {
        font-size: 12px;
        line-height: 120%;
      }
    }

    .textinput {
      padding: 6px 16px;
      height: 40px;
      font-weight: 700;
      text-align: center;
      background: url(~assets/images/bonus.svg) $gray no-repeat;
      background-size: 24px;
      background-position: right 16px center;
      border-radius: 8px;

      @media screen and (max-width: 766px) {
        height: 54px;
      }

      &::placeholder {
        font-weight: 400;
        color: #939393;
      }
    }

    &-text {
      font-size: 12px;
      line-height: 120%;
      color: $gray-dark;
    }
  }

  &__result {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    &-sum {
      padding: 12px 16px;
      width: 100%;
      font-size: 14px;
      line-height: 140%;
      text-align: center;
      background-color: rgba(238, 176, 16, 0.1);
      border-radius: 8px;

      @media screen and (max-width: 766px) {
        border-radius: 16px;
      }

      & span {
        font-weight: 700;
        line-height: 120%;
      }
    }

    &-deadline {
      font-size: 14px;
      line-height: 120%;
      color: #939393;

      & span {
        font-weight: 700;
      }
    }
  }

  &__consent-container {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-top: 1px solid $blue-dirty;

    @media screen and (max-width: 766px) {
      padding-top: 16px;
      gap: 16px;
    }

    .check-box {
      margin-bottom: 0;
      justify-content: center;
      gap: 16px;

      &__title {
        font-size: 14px;
        line-height: 120%;
      }
    }
  }

  &__link {
    color: $yellow-dark;
    text-decoration: underline;
    text-underline-offset: 0.2em;

    @media screen and (max-width: 766px) {
      font-size: 14px;
      line-height: 120%;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 16px;

    @media screen and (max-width: 766px) {
      flex-direction: column;
      gap: 8px;
    }
  }

  &__button-cancel {
    max-width: 212px;
    width: 100%;
    background-color: $yellow-sub;
    border: 2px solid $yellow-sub;

    @media screen and (max-width: 766px) {
      order: 2;
      max-width: none;
    }
  }
}
