@use "../../utils.scss" as *;

.footer-button {
  padding: 12px 16px;

  color: $white;
  border: 1px solid $white;
  border-radius: 24px;

  font-size: 16px;
  font-weight: 500;
  line-height: 19px;

  @media screen and (max-width: 766px) {
    padding: 6px 8px;

    font-weight: 400;
  }
  
  &_iconed {
    display: flex;
    gap: 10px;

    @media screen and (max-width: 766px) {
      align-items: center;
      gap: 0;
    }
  }

  &__icon {
    display: inline-block;

    width: 20px;
    height: 20px;
    flex-shrink: 0;
  }
}
