.answers {
  margin-top: 72px;
  margin-bottom: 72px;

  @media screen and (max-width: 766px) {
    margin: 0 8px 56px;
  }

  &__title {
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: 800;

    @media screen and (max-width: 766px) {
      padding: 0 8px;
    }
  
  }
  &__plate {
    padding: 30px 60px 60px;
    width: 100%;
    
    @media screen and (max-width: 766px) {
      padding: 12px 24px 24px;
      border-radius: 16px;
    }
  }
}
