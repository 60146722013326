@use "../../../../../../../../utils.scss" as *;

.projects-active-report-auction-visibility-percentage {
  @media screen and (max-width: 766px) {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__container {
    padding: 2px 8px;
    max-width: 175px;
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    gap: 20px;
    background-color: $gray;
    border-radius: 8px;
    box-shadow: $box-shadow-light;
    
    @media screen and (max-width: 766px) {
      white-space: nowrap;
    }
  }

  &__icon {
    width: 29px;
    height: 29px;
    background: url(~assets/images/starts.svg) $yellow-sub center no-repeat;
    background-size: 18px 23px;
    border-radius: 8px;
    flex-shrink: 0;

    @media screen and (max-width: 766px) {
      width: 22px;
      height: 22px;
      background-size: 16px;
      border-radius: 6px;
    }

    &-coin {
      width: 22px;
      height: 22px;
      background: url(~assets/images/coinGray.svg) $yellow-sub center no-repeat;
      border-radius: 6px;
      flex-shrink: 0;
      color: #ABABAB;
    }
  }

  &__text {
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    text-align: center;

    @media screen and (max-width: 766px) {
      font-size: 16px;

      &-coin {
        font-weight: 700;
        color: #ABABAB;
      }
    }

    &.gray {
      color: $gray-dark;
    }
    &.red {
      color: $pink-dark;
    }
    &.orange {
      color: $yellow-dark;
    }
    &.lightgreen {
      color: #aac506;
    }
    &.green {
      color: #73a71d;
    }
    &.linear-gradient-green {
      background: linear-gradient(85.97deg, #73a71d 22.83%, #bfdb93 81.36%);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }
  }

  &__hint {
    width: 24px;
    height: 24px;
    background: url(~assets/images/faq.svg) center/cover no-repeat;
    flex-shrink: 0;
    cursor: pointer;
  }
}

.hint-projects-active-report-auction-visibility-percentage {
  padding: 16px;
  top: 0;
  left: 210px;
  z-index: 11;
  font-size: 14px;
  background: $white;
  box-shadow: $box-shadow-main;

  @media screen and (max-width: 766px) {
    top: -80px;
    left: 50%;
    transform: translateX(-50%);
  }

  .hint__button {
    display: none;
  }

  .hint__strong {
    margin-top: 8px;
    padding: 10px 16px;

    font-style: italic;
    background-color: rgba(238, 176, 16, 0.1);
    border-radius: 24px;

    &-title {
      font-weight: 700;
      color: $yellow-dark;
    }
  }
}
