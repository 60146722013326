@use "../../../utils.scss" as *;

.spin-box-container {
  position: relative;
}

.first-container {
  margin-top: 25px;
}

.spin-box-special {
  padding: 4px 8px 8px;
  position: absolute;
  top: -25px;
  max-width: 150px;
  width: 100%;
  background: $yellow-sub;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  @media screen and (max-width: 766px) {
    max-width: none;
  }

  &__price {
    font-size: 14px;
    font-weight: 700;
    text-align: center;

    & span {
      font-weight: 400;
    }
  }

  .tooltips {
    pointer-events: all;
    cursor: default;

    &__description {
      bottom: 30px;
      max-width: 250px;
      min-width: 250px;
      width: 100%;
      height: fit-content;

      font-weight: 400;
      text-wrap: wrap;
      white-space: normal;
      z-index: 5;

      &::after {
        content: none;
      }

      @media screen and (max-width: 766px) {
        left: 0;
      }
    }
  }
}

.spin-box {
  position: relative;
  max-width: 150px;
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;

  border-radius: 6px;
  background-color: $yellow-sub;

  @media screen and (max-width: 766px) {
    max-width: none;
    height: 29px;
    justify-content: center;
  }

  &__control {
    width: 33px;
    height: 29px;

    @media screen and (max-width: 766px) {
      width: 65px;
    }

    &_enabled {
      cursor: pointer;
    }

    &_down {
      background: url(~assets/images/dec-active.svg) center no-repeat;
      flex-shrink: 0;
    }
    &_up {
      background: url(~assets/images/inc-active.svg) center no-repeat;
      flex-shrink: 0;
    }
    &_disabled {
      background: url(~assets/images/dec.svg) center no-repeat;

      &_up {
        background: url(~assets/images/inc.svg) center no-repeat;
        cursor: default;
      }
    }
  }

  &__counter {
    max-width: 75px;
    width: 100%;
    height: 24px;

    font-size: 14px;
    font-weight: 600;
    line-height: 140%;
    background: $lightgreen;
    border-radius: 4px;
    text-align: center;

    cursor: default;

    @media screen and (max-width: 766px) {
      max-width: 125px;
    }
  }

  .hint-spin-box {
    bottom: 35px;
    right: 0;
    z-index: 9;

    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.8),
      rgba(234, 67, 53, 0.05)
    );
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
    border-radius: 16px;
    font-size: 14px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);

    @media screen and (max-width: 766px) {
      left: 50%;
      transform: translateX(-50%);
    }

    .hint__text {
      & ol {
        margin-top: 4px;
        & li {
          margin-left: 16px;
          display: list-item;
          list-style-type: decimal;

          &::before {
            margin-right: 2px;
          }

          &::marker {
            font-weight: 500;
          }
        }
      }
    }

    .hint__button {
      padding: 8px 0;
      height: 38px;
      background: $light-black;
      color: $white;
    }
  }
}

.spin-box__counter.spin-box__control_disabled {
  background: $yellow-sub;
  color: rgba(171, 171, 171, 1);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
