@use "../../../../utils.scss" as *;

.project-link {
  padding: 4px 16px;
  max-width: 90px;
  width: 100%;
  height: 30px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  background: rgba(249, 249, 251, 0.2);
  border-radius: 16px;
  box-shadow: $box-shadow-light;
  
  pointer-events: all;
  cursor: pointer;
  &-icon {
    padding: 2px;
    width: 22px;
    height: 22px;

    background: url(~assets/images/link.svg) center/contain no-repeat;
    background-size: 18px;
    flex-shrink: 0;
  }
}
