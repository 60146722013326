@use "../../../../../utils.scss" as *;

.projects-active-row {
  &__error-comment {
    margin: 16px 0 0;
    padding: 11px 16px;
    font-weight: 600;
    color: $red-failed;
    background-color: rgba(234, 67, 53, 0.05);
    border-radius: 16px;
  }

  &__error-block {
    margin: 16px 0;
    padding: 11px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 766px) {
      margin: 16px 0 0;
      padding: 0;
      flex-direction: column;
      gap: 8px;
    }

    &_container {
      padding: 24px 44px;
      max-width: 470px;
      width: 100%;
      display: flex;
      align-items: center;
      gap: 24px;
      background-color: rgba(234, 67, 53, 0.05);
      border-radius: 16px;

      @media screen and (max-width: 766px) {
        padding: 24px;
      }
    }

    /* &-buttons {
      display: flex;
      gap: 16px;

      @media screen and (max-width: 766px) {
        padding: 0 8px;
        width: 100%;
        flex-direction: column;
        gap: 8px;
      }

      &-archive {
        min-width: 256px;
        width: 100%;
        height: 54px;
        background-color: $yellow-sub;
        border: 2px solid $yellow-sub;

        @media screen and (max-width: 766px) {
          min-width: auto;
        }
      }

      &-config {
        min-width: 256px;
        width: 100%;

        @media screen and (max-width: 766px) {
          min-width: auto;
        }

        .button-with-icon {
          &__icon {
            width: 22px !important;
            height: 23px !important;
          }
        }
      }
    }

    &_link {
      min-width: 264px;
      width: 100%;

      @media screen and (max-width: 766px) {
        min-width: auto;
      }

      .button {
        width: 100%;
        height: 54px;
        border-radius: 16px;

        @media screen and (max-width: 766px) {
          min-width: auto;
        }
      }
    } */
  }

  &__error-icon {
    width: 31px;
    height: 31px;
    background: url(~assets/images/messageattention.svg) center/contain
      no-repeat;
    flex-shrink: 0;

    @media screen and (max-width: 766px) {
      width: 24px;
      height: 24px;
    }
  }

  &__error-description {
    font-size: 18px;
    font-weight: 600;
    color: $red-failed;

    @media screen and (max-width: 766px) {
      font-size: 16px;
    }

    & span {
      font-size: 16px;
      font-weight: 400;
      color: $black;

      @media screen and (max-width: 766px) {
        font-size: 14px;
      }
    }
  }
}
