@use "../../../../../../../utils.scss" as *;

.projects-active-report-auction-rise {
  margin: 10px 0 16px;
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $blue-dirty;

  @media screen and (max-width: 766px) {
    margin: 24px 0;
    padding: 0;
    flex-direction: column;
    gap: 24px;
    border-top: none;
  }

  &__raise-price {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__continue {
    min-width: 270px;
    background-color: $yellow-sub;
    border: 2px solid $yellow-sub;
  }

  &__update {
    padding: 0 16px;
    min-width: 270px;
  }

  .button {
    padding: 0 16px;
    max-width: 270px;
    width: 100%;
    height: 38px;

    @media screen and (max-width: 766px) {
      max-width: 350px;
      height: 44px;
    }
  }
}