@use "../../../../../utils.scss" as *;

.grid {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  &-title {
    margin-bottom: 16px;

    font-size: 24px;
    font-weight: 700;
    line-height: 140%;
  }
}

.grid-description {
  margin: 0 auto;
  margin-bottom: 16px;
  padding-top: 32px;
  max-width: 450px;
  width: 100%;
  font-size: 20px;
  font-weight: 400;
  font-style: italic;
  color: $gray-dark;
  text-align: center;
  border-top: 2px solid $gray;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  gap: 16px;
}

.grid-item {
}

.grid-img {
  min-width: 300px;
  width: 100%;
  height: 278px;
  border-radius: 8px;
}
