@use "../../../../../utils.scss" as *;

.table-row {
  &-invited,
  &-withdrawing {
    display: flex;
    align-items: center;
    gap: 24px;
    border-bottom: 1px solid $gray-disabled;

    @media screen and (max-width: 766px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
    }
  }

  &-invited {
    .table-row-item {
      &:nth-child(1) {
        flex: 1;
      }

      &:nth-child(2),
      &:nth-child(3) {
        flex: 0.2;

        @media screen and (max-width: 766px) {
          flex: 0.4;
        }
      }

      @media screen and (max-width: 766px) {
        padding: 0;
      }
    }
  }

  &-withdrawing {
    .table-row-item {
      &:nth-child(2) {
        flex: 1;
      }

      &:nth-child(1),
      &:nth-child(3),
      &:nth-child(4) {
        flex: 0.6;
      }

      @media screen and (max-width: 766px) {
        padding: 0;
        flex: 1;
      }
    }
  }

  &-item {
    padding: 16px 24px;

    font-size: 14px;
    line-height: 120%;

    &-bonus {
      display: flex;
      align-items: center;
      gap: 6px;

      &::after {
        content: "";
        width: 16px;
        height: 16px;
        background: url(~assets/images/bonus.svg) center/contain no-repeat;
      }
    }

    @media screen and (max-width: 766px) {
      &__mobile {
        padding-bottom: 16px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        border-bottom: 1px solid #d7e2e4;

        .table-row-item__info {
          &:first-child {
            flex: 1;

            .table-row-item {
              &:nth-child(2) {
                color: #939393;
              }
            }
          }

          &:last-child {
            flex: 0.6;
          }
        }
      }

      &__info {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
    }
  }
}
