@use "../../../../../utils.scss" as *;

.market-step2 {
  padding: 32px 56px;
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 48px;

  &__distribution {
    display: flex;
    flex-direction: column;
    gap: 32px;

    .faqed {
      justify-content: space-between;

      &__children-container {
        display: flex;
        align-items: center;
        gap: 16px;
      }
    }

    &-title {
      font-size: 24px;
      font-weight: 700;
      line-height: 110%;
    }
  }

  &__settings {
    &-container {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }

  &__create-button {
    margin-bottom: 24px;
    width: 100%;
    gap: 2px;

    @media screen and (max-width: 766px) {
      margin-top: 24px;
      margin-bottom: 0;
      padding: 0;
      height: 44px;
      border-radius: 12px;
      background: $gray-disabled;
      border: 1px solid $gray-disabled;
    }

    .button-with-icon {
      &__icon {
        width: 22px;
        height: 22px;
        transform: rotate(180deg);
      }
    }
  }
}
