@use "../../../../../utils.scss" as *;

.market-step1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;

  &__link {
    &_settings {
      padding-bottom: 32px;
      display: flex;
      align-items: center;
      gap: 32px;
      border-bottom: 1px solid $gray-disabled;

      &.without-border {
        padding-bottom: 0;
        border-bottom: none;
      }

      &-screen {
        padding: 12px 24px;
        max-width: 450px;
        width: 100%;

        background-color: $gray;
        border-radius: 16px;

        &-instructions {
          margin-left: 26px;
          padding: 8px 10px 8px 0;
          color: $gray-dark;
          text-decoration: underline;
          text-underline-offset: 0.2em;
        }

        &.error {
          border-radius: 10px;
          border: 2px solid $red;
          &:focus {
            border-color: $red;
          }
        }
      }

      &-link {
        width: 100%;
        display: flex;
        flex-direction: column;

        & p {
          margin-bottom: 16px;
        }
      }
    }
  }

  &__parameters {
    display: flex;
    gap: 45px;

    &_settings {
      max-width: 500px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 35px;

      &-phrase {
        width: 100%;
      }
    }

    .faqed {
      margin-bottom: 16px;
    }
  }

  &__label {
    display: flex;
    align-items: center;

    &::before {
      content: "";
      margin-right: 16px;
      min-width: 8px;
      width: 8px;
      height: 8px;
      display: block;
      background-color: $yellow-main;
      border-radius: 50%;
    }
  }

  &__note {
    margin-top: 8px;
    margin-left: 26px;
    max-width: 390px;
    width: 100%;
    font-size: 15px;
    color: $gray-deep-dark;
  }
}
