@use "../../../utils.scss" as *;

.sort-menu {
  margin-bottom: 24px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__container {
    height: 49px;

    border-radius: 10px;
    padding: 8px 16px;
    width: auto;
    background-color: $gray;
    box-shadow: $box-shadow-light;

    &-block {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}

.button.blog {
  padding: 0 16px;
  height: 33px;

  border-radius: 7px;
  font-size: 12px;

  border: 2px solid $yellow-sub;
  background-color: $yellow-sub;
  transition: 0.3s;
  
  &.active {
    border: 2px solid $yellow-main;
    background-color: $yellow-main;
  }
}
