@use "../../../../../../utils.scss" as *;

.deadline-selector {
  padding: 16px 16px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  background: $white;
  border-radius: 16px;
  box-shadow: $box-shadow-main;

  @media screen and (max-width: 766px) {
    padding: 0;
    gap: 8px;
    box-shadow: none;
  }

  &__title {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 14px;

    &::before {
      content: "";
      width: 5px;
      height: 5px;
      display: block;
      background-color: $yellow-main;
      border-radius: 50%;
    }
  }

  &__choice {
    display: flex;
    justify-content: space-evenly;
    gap: 8px;

    @media screen and (max-width: 766px) {
      width: 100%;
      flex-direction: column;
      gap: 0;

      &.column {
        flex-direction: row;
        gap: 8px;
      }
    }
  }
}
