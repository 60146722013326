@use "../../../utils.scss" as *;

.button {
  padding: 0 48px;
  width: fit-content;
  height: 54px;
  display: block;
  text-align: center;
  background-color: $yellow-main;
  border: 2px solid $yellow-main;
  border-radius: 16px;
  transition: background-color 0.5s;

  @media screen and (max-width: 766px) {
    padding: 0;
    width: 100%;
    height: 44px;
  }

  &:not(:disabled):hover {
    background-color: $white;
  }

  & span {
    font-weight: 700;
  }

  &.outlined {
    background-color: transparent;

    &:not(:disabled):hover {
      background-color: $yellow-main;
    }
  }

  &.small {
    height: 46px;
  }

  &.wide {
    width: 100%;
  }

  &.bold {
    font-weight: 700;

    &:not(:disabled):hover {
      background-color: $white;
    }
  }

  &.for-blog {
    padding: 8px 16px;
    height: 33px;

    border-radius: 7px;
    font-size: 12px;

    border: 2px solid $yellow-sub;
    background-color: $yellow-sub;
    transition: 0.3s;
    
    &.active {
      border: 2px solid $yellow-main;
      background-color: $yellow-main;
    }
  }

  &:disabled {
    background-color: $gray-disabled;
    border-color: $gray-disabled;
    opacity: .6;
    cursor: default;
  }
}
