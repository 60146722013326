@use "../../../../../../../utils.scss" as *;

.projects-active-report-auction-slider {
  max-width: 480px;
  width: 100%;

  &__container {
    display: flex;
    align-items: center;
    gap: 8px;

    @media screen and (max-width: 766px) {
      flex-direction: column;

      & p {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    & span {
      font-size: 14px;
    }
  }

  &__marks {
    margin: 8px auto 0;
    display: flex;
    justify-content: center;
    gap: 24px;

    @media screen and (max-width: 766px) {
      gap: 12px;
    }
  }

  .slider {
    max-width: 400px;
    width: 100%;
  }
}