@use "../../../utils.scss" as *;

.suremodal {
  padding: 32px;
  max-width: 550px;
  border-radius: 32px;

  @media screen and (max-width: 766px) {
    padding: 16px 16px 32px;
    border-radius: 16px 16px 0 0;
  }

  &__text {
    margin-top: 8px;
    margin-bottom: 40px;
    font-size: 24px;
    text-align: center;

    @media screen and (max-width: 766px) {
      margin-top: 16px;
      margin-bottom: 32px;
      font-size: 20px;
    }
  }

  &__buttons {
    display: flex;
    column-gap: 20px;
    justify-content: center;

    & > button {
      padding: 0 20px;
      max-width: 125px;
      width: 100%;
    }
  }

  .popup__button-close {
    width: 32px;
    height: 32px;
    background: url(~assets/images/closeIcon.svg) center/contain no-repeat;
  }
}
