.warning-box {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.07);
  z-index: 11;

  &__plate {
    max-width: 450px;
    width: 100%;
    padding: 32px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__close-icon {
    margin-left: auto;
    width: 32px;
    height: 32px;
    background: center/cover url(~assets/images/closeIcon.svg);
    cursor: pointer;
  }

  &__icon {
    margin-bottom: 20px;
    width: 112px;
    height: 112px;
    background: center/cover url(~assets/images/messageerror.svg);
  }

  &__message {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
  }

  &__support {
    margin-top: 25px;
    height: 54px;
  }
}
