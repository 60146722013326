.faq {
  margin-left: 16px;
  padding: 5px;
  position: relative;
  width: 24px;
  height: 24px;
  background: url(~assets/images/faq.svg) center/contain no-repeat;
  flex-shrink: 0;
  z-index: 5;

  cursor: pointer;

  @media screen and (max-width: 766px) {
    margin-left: 0;
    padding: 7px;
    width: 32px;
    height: 32px;
  }
}

.faq__text {
  font-weight: 400;
  font-size: 14px;
  line-height: 13px;
  pointer-events: none;
}
