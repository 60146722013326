@use "../../../../utils.scss" as *;

.form-step1 {
  font-size: 14px;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    @media screen and (max-width: 766px) {
      gap: 8px;
    }
  }

  &__buttons {
    width: 100%;
    display: flex;
    gap: 8px;

    &-sum {
      max-width: 144px;
      width: 100%;
      height: 30px;
      text-align: center;
      background-color: $yellow-sub;
      border-radius: 16px;

      @media screen and (max-width: 766px) {
        height: 27px;
        font-size: 12px;
      }
    }
  }

  &__result {
    margin-top: 24px;
    padding: 12px 16px;

    text-align: center;
    background-color: rgba(238, 176, 16, 0.1);
    border-radius: 8px;

    @media screen and (max-width: 766px) {
      margin-top: 16px;
      border-radius: 16px;
    }

    &-sum {
      margin-left: 12px;
      font-weight: 700;

      @media screen and (max-width: 766px) {
        margin-left: 0;
        font-size: 14px;
        line-height: 1.4;
      }
    }
  }

  .textinput {
    width: 100%;
    height: 40px;
    border-radius: 8px;

    font-size: 23px;
    font-weight: 700;
    text-align: center;

    @media screen and (max-width: 766px) {
      font-size: 16px;
    }

    &::placeholder {
      font-size: 16px;
      font-weight: 400;

      @media screen and (max-width: 766px) {
        font-size: 14px;
      }
    }

    &__error {
      top: -12px;
      left: 0;
    }
  }
}
