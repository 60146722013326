@use "../utils.scss" as *;

.footer {
  &__wrapper {
    padding: 30px 0 14px;
    display: grid;
    grid-template-columns: 320px 410px 410px;
    justify-content: center;
    gap: 16px;
    background-color: $gray;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    box-shadow: 0px -6px 27px 0px rgba(0, 0, 0, 0.08);

    @media screen and (max-width: 766px) {
      padding: 16px;
      display: flex;
      flex-direction: column;
    }

    &-support {
      background: #ebf6fc;

      @media screen and (max-width: 766px) {
        background: linear-gradient(180deg, #ebf6fc 26.39%, #75a5bf 100%);
      }

      & > div:first-child {
        max-width: 235px;
        width: 100%;

        @media screen and (max-width: 766px) {
          max-width: none;
        }

        p:first-child {
          display: none;

          @media screen and (max-width: 766px) {
            display: block;
          }
        }

        p:nth-child(2) {
          font-weight: 700;
          line-height: 140%;

          @media screen and (max-width: 766px) {
            font-size: 22px;
            font-weight: 800;
            line-height: normal;
            text-wrap: nowrap;
          }
        }

        p:nth-child(3) {
          font-weight: 500;
          line-height: 140%;

          @media screen and (max-width: 766px) {
            font-size: 22px;
            font-weight: 600;
            line-height: normal;
          }
        }

        .footer-button {
          margin-top: 10px;
          padding: 0 14px;
          width: 100%;
          height: 36px;
          background-color: #b1c8d4;
          border: none;
          border-radius: 16px;

          @media screen and (max-width: 766px) {
            margin-top: 16px;
            height: 48px;
          }

          &_iconed {
            justify-content: center;
            align-items: center;

            @media screen and (max-width: 766px) {
              gap: 12px;
            }
          }
        }
      }
    }

    &-news {
      background: #2aabee;

      @media screen and (max-width: 766px) {
        background: linear-gradient(180deg, #2aabee 0%, #2785b6 89%);
      }

      & > div:first-child {
        max-width: 250px;
        width: 100%;

        @media screen and (max-width: 766px) {
          max-width: none;
        }

        p:first-child {
          display: none;

          @media screen and (max-width: 766px) {
            display: block;
            line-height: 140%;
            color: #ebf6fc;
          }
        }

        p:nth-child(2) {
          font-weight: 700;
          line-height: 140%;
          color: #ebf6fc;

          @media screen and (max-width: 766px) {
            font-size: 22px;
            line-height: normal;
            color: #ebf6fc;
          }
        }

        .footer-button {
          margin-top: 10px;
          padding: 0 14px;
          width: 100%;
          height: 36px;
          background-color: #74bce1;
          border: none;
          border-radius: 16px;

          @media screen and (max-width: 766px) {
            margin-top: 16px;
            height: 48px;
            background-color: rgba(166, 175, 181, 0.8);
          }

          &_iconed {
            justify-content: center;
            align-items: center;

            @media screen and (max-width: 766px) {
              gap: 12px;
            }
          }
        }
      }
    }

    &-vc {
      padding: 0 25px;
      grid-column: 2 / 4;
      height: 200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: linear-gradient(145deg, #f2f1ed 50%, #eae8e0 50%);
      transform-origin: center;

      @media screen and (max-width: 766px) {
        margin-bottom: 34px;
        padding: 32px 24px;
        height: auto;
      }

      & > div:first-child {
        max-width: 452px;
        width: 100%;

        @media screen and (max-width: 766px) {
          max-width: none;
        }

        p:first-child {
          font-weight: 400;
          line-height: 140%;

          @media screen and (max-width: 766px) {
            line-height: normal;
          }
        }

        p:nth-child(2) {
          margin-top: 4px;
          font-size: 24px;
          font-weight: 700;
          line-height: 140%;

          @media screen and (max-width: 766px) {
            font-size: 22px;
            line-height: normal;
          }
        }

        .footer-button {
          margin-top: 16px;
          padding: 0 14px;
          width: 100%;
          height: 48px;
          background-color: #c8c5b9;
          border: none;
          border-radius: 16px;
        }
      }

      & > div:last-child {
        padding: 0;
        width: 178px;
        height: 178px;
        background: none;
        background-size: contain;
        background-repeat: no-repeat;

        border: none;
        border-radius: 0;
      }
    }
  }

  &__links {
    &-container {
      max-height: 100px;
      min-height: 100px;

      display: flex;
      justify-content: center;
      align-items: center;
      gap: 24px;

      background-color: $black-dark;

      font-size: 14px;
      font-weight: 500;

      @media screen and (max-width: 766px) {
        max-height: 220px;
        min-height: 220px;
        flex-direction: column;
        gap: 16px;
      }

      &.visible-menu {
        @media screen and (max-width: 766px) {
          margin-bottom: 66px;
        }
      }
    }
  }

  .footer__wrapper-support {
    @media screen and (max-width: 766px) {
      order: 1;
    }
  }

  .footer__wrapper-news {
    @media screen and (max-width: 766px) {
      order: 2;
    }
  }

  .footer-place-logo {
    grid-row: 1 / 4;

    @media screen and (max-width: 766px) {
      order: 4;
    }
  }

  .footer__wrapper-vc {
    @media screen and (max-width: 766px) {
      order: 4;
    }
  }
}
