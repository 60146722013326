@use "../../../../../../utils.scss" as *;

.settings-seo-other {
  &__container {
    padding: 8px 16px;

    display: flex;
    flex-direction: column;
    gap: 40px;

    @media screen and (max-width: 766px) {
      padding: 0;
      gap: 24px;
    }

    &-input {
      .textinput {
        height: 40px;
      }
    }

    &-uploaded {
      @media screen and (max-width: 766px) {
        padding-top: 24px;
        border-top: 1px solid $blue-dirty;
      }

      &_data {
        width: 100%;

        display: flex;
        gap: 24px;

        @media screen and (max-width: 766px) {
          flex-direction: column;
          gap: 0;

          & p {
            font-size: 10px;
            font-weight: 500;
            letter-spacing: 0.1px;
            color: $gray-dark;
          }
        }

        .avito-upload-file {
          margin-top: 22px;
          max-width: 450px;
          width: 100%;

          @media screen and (max-width: 766px) {
            margin-top: 4px;
          }

          &__success {
            flex-direction: column;
            gap: 8px;

            @media screen and (max-width: 766px) {
              margin-bottom: 16px;
              flex-direction: row;
            }

            &-upload {
              max-width: none;

              @media screen and (max-width: 766px) {
                padding: 16px;

                & p {
                  font-size: 14px;
                  font-weight: 700;
                  color: $lightgreen;
                }
              }
            }
          }

          &__delete-button {
            max-width: none !important;

            @media screen and (max-width: 766px) {
              max-width: 100px !important;
            }
          }
        }

        &-comment {
          width: 100%;

          & p {
            margin-bottom: 8px;
            font-size: 12px;
            font-weight: 500;
            color: #c7c7c7;

            @media screen and (max-width: 766px) {
              margin-bottom: 4px;
              font-size: 10px;
              letter-spacing: 0.1px;
            }
          }

          & textarea {
            padding: 16px;
            min-height: 116px;
            border-radius: 8px;

            @media screen and (max-width: 766px) {
              padding: 12px 8px 8px;
              background-color: $gray;
            }
          }
        }
      }
    }
  }

  &__add-button {
    gap: 4px;
    background-color: $yellow-sub;
    border: 2px solid $yellow-sub;

    @media screen and (max-width: 766px) {
      margin-bottom: 16px;
      height: 40px;
      font-size: 14px;
      background: $yellow-sub;
      border: 1px solid $yellow-sub;
    }
  }
}
