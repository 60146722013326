@use "../../../utils.scss" as *;

.carousel {
  &__slides {
    position: relative;
    width: 100%;
    height: 290px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__slide {
    position: absolute;
    height: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 12px;
    transition: all 0.5s ease;

    &-left {
      left: -5%;
      z-index: 1;
      width: 20%;
      height: 90%;
      background-position: right;
      opacity: 0.4;
    }

    &-center {
      max-width: 660px;
      width: 100%;
      z-index: 2;
    }

    &-right {
      right: -5%;
      z-index: 1;
      width: 20%;
      height: 90%;
      background-position: left;
      opacity: 0.4;
    }
  }

  &__controls {
    margin-top: 32px;
    margin-left: 24px;
    margin-right: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__caption {
    max-width: 530px;
    width: 100%;
    font-size: 16px !important;
    font-weight: 400 !important;

    & span {
      font-weight: 700;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 8px;

    .button-with-icon {
      min-width: 116px;
      height: 42px;
      gap: 6px;
      background: #ededed;
      border: none;

      &__icon {
        order: 2;
        width: 13px;
        height: 10px;
        transform: rotate(180deg);
      }
    }
  }

  &__button {
    padding: 10px 15px;

    &-prev {
      width: 42px;
      height: 42px;
      background: url(~assets/images/arrowLeftSmall.svg) #ededed center no-repeat;
      border-radius: 50%;
    }
  }
}