@use "../../../utils.scss" as *;

.slider {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;

  height: 6px;
  border-radius: 66px;

  @media screen and (max-width: 766px) {
    height: 8px;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    cursor: pointer;

    padding: 15px;
    height: 23px;
    width: 23px;

    background-color: var(--slider-color);
    border: 2px solid var(--slider-color);
    border-radius: 50%;

    padding: 8px;
    box-sizing: border-box;

    @media screen and (max-width: 766px) {
      height: 23px;
      width: 23px;
      padding: 10px 0;
    }
  }

  &::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    cursor: pointer;

    padding: 15px;
    height: 23px;
    width: 23px;

    background-color: var(--slider-color);
    border: 2px solid var(--slider-color);
    border-radius: 50%;

    padding: 8px;
    box-sizing: border-box;

    @media screen and (max-width: 766px) {
      height: 23px;
      width: 23px;
      padding: 10px 0;
    }
  }
}
