@use "../../../../../../utils.scss" as *;

.settings-seo-ya {
  &__container {
    padding: 8px 16px;

    display: flex;
    flex-direction: column;
    gap: 24px;

    @media screen and (max-width: 766px) {
      padding: 0;
    }

    &-phrases {
      .formstep__phrase {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 8px;

        &-container {
          margin-bottom: 0 !important;
          padding-right: 16px;
          max-width: 616px;
          width: 100%;
          gap: 8px;

          @media screen and (max-width: 766px) {
            padding-right: 0;
          }

          .textinput {
            padding: 12px 16px;
            height: 44px;
            border-radius: 8px;

            @media screen and (max-width: 766px) {
              padding: 6px 8px;
              height: 40px;
            }
          }
        }

        &-button {
          width: 44px;
          height: 44px;
          border-radius: 8px;

          @media screen and (max-width: 766px) {
            width: 40px;
            height: 40px;
            border-radius: 6px;
            background-color: $yellow-main;
          }
        }

        &-tags {
          gap: 8px;

          @media screen and (max-width: 766px) {
            gap: 4px;
          }
        }
      }

      .phrase__tag {
        padding: 0;
        padding-left: 12px;
        height: 44px;
        border-radius: 8px;

        &-title {
          font-size: 12px;
        }

        &-close {
          width: 36px;
          height: 36px;

          @media screen and (max-width: 766px) {
            background: url(~assets/images/tagclosegray.svg) center no-repeat;
          }
        }
      }

      &_count {
        margin-top: 16px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @media screen and (max-width: 766px) {
          margin-top: 12px;
        }

        .button-with-icon {
          max-width: 175px;
          height: 30px;
          gap: 4px;
          font-size: 12px;
          color: $light-black;
          background: #f8f8f8;
          border: #f8f8f8;

          @media screen and (max-width: 766px) {
            max-width: 160px;
            height: 26px;
          }

          &__icon {
            width: 15px;
            height: 15px;
          }
        }

        & p {
          font-size: 12px;
          color: $gray-dark;

          @media screen and (max-width: 766px) {
            color: #C7C7C7;
            line-height: 140%;
          }
        }
      }
    }

    &-brand {
      padding: 24px 24px 16px;
      position: relative;
      background: #f8f8f8;
      border-radius: 24px;
      box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.08);

      @media screen and (max-width: 766px) {
        padding: 24px 4px 16px;
      }
  
      .formstep__phrase {
        margin-top: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 8px;

        @media screen and (max-width: 766px) {
          margin-bottom: 12px;
        }
  
        &-container {
          margin-bottom: 0 !important;
          padding-right: 16px;
          max-width: 316px;
          width: 100%;
          gap: 8px;

          @media screen and (max-width: 766px) {
            padding-right: 0;
          }
  
          .textinput {
            padding: 0 16px;
            height: 44px;
            background-color: $gray-disabled;
            border-radius: 8px;

            @media screen and (max-width: 766px) {
              padding: 6px 8px;
              height: 40px;
            }
          }
        }
  
        &-button {
          width: 44px;
          height: 44px;
          border-radius: 8px;

          @media screen and (max-width: 766px) {
            width: 40px;
            height: 40px;
            border-radius: 6px;
            background-color: $yellow-main;
          }
        }
  
        &-tags {
          gap: 8px;
        }
      }
  
      .phrase__tag {
        padding: 0;
        padding-left: 12px;
        height: 44px;
        background-color: $gray-disabled;
        border-radius: 8px;
  
        &-title {
          font-size: 12px;
        }
  
        &-close {
          width: 36px;
          height: 36px;

          @media screen and (max-width: 766px) {
            background: url(~assets/images/tagclosegray.svg) center no-repeat;
          }
        }
      }
  
      &_subtitle {
        margin-bottom: 16px;
        font-size: 12px;
        font-weight: 500;
        color: #939393;
        
        @media screen and (max-width: 766px) {
          margin-bottom: 12px;
          font-size: 10px; 
          font-weight: 600;
          color: $gray-dark;       
        }
      }
  
      &_badge {
        padding: 4px 16px;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.12px;
        color: $white;
        background: $lightgreen;
        border-radius: 16px 0px 8px 0px;
      }
    }

    &-city {
      margin-top: 8px;
      padding-top: 32px;
      border-top: 1px solid $blue-dirty;

      @media screen and (max-width: 766px) {
        margin-top: 0;
        padding-top: 24px;    
      }

      .react-dadata__input {
        height: 44px;
      }
    }
  }
}
