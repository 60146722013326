.equipment {
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 16px;

  &__title {
    display: flex;
    align-items: center;
    gap: 12px;

    &-icon {
      width: 40px;
      height: 40px;
    }

    & p:first-child {
      font-weight: 600;
    }

    & p:last-child {
      font-size: 14px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 2px;

    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 24px;

      & p {
        padding: 2px 0;
        font-size: 12px;
        font-weight: 300;
        line-height: 120%;
        color: #939393;
      }

      & p:last-child {
        white-space: nowrap;
      }
    }
  }
}
