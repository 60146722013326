@use "../../../../../utils.scss" as *;

.navigation {
  box-sizing: border-box;

  position: sticky;
  top: 100px;

  max-width: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  background-color: $white;
  border-radius: 8px;
  padding: 12px 12px 24px;

  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 120%;
  }

  &__nav {
    box-sizing: border-box;

    padding: 16px;
    max-width: 272px;
    width: 100%;
    height: auto;
    background-color: $gray;
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    gap: 8px;

    &-link {
      display: flex;
      align-items: center;
      border-radius: 8px;
      padding: 4px 12px;
      max-width: 230px;
      width: 100%;

      font-size: 14px;
      line-height: 120%;
      opacity: 0.5;

      &:hover {
        background-color: $yellow-sub;
        opacity: 1;
      }

      &.active {
        background-color: $yellow-sub;

        font-weight: 600;
        opacity: 1;
      }
    }
  }

  &__share {
    max-width: 275px;
    width: 100%;
    padding: 4px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    &-text {
      max-width: 150px;
      width: 100%;
      font-size: 12px;
      line-height: 110%;
      text-align: center;
      color: $gray-dark;
    }

    &-container {
      padding: 8px;
      border-radius: 24px;
      display: flex;
      gap: 8px;

      background-color: $gray;

      &-button {
        &-vk {
          width: 24px;
          height: 24px;
          background: url(~assets/images/vk.svg);
        }
        &-wa {
          width: 24px;
          height: 24px;
          background: url(~assets/images/wa.svg);
        }
        &-tg {
          width: 24px;
          height: 24px;
          background: url(~assets/images/tg.svg);
        }
      }
    }
  }
}
