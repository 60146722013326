@use "../../utils.scss" as *;

@keyframes loading {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.header {
  &__inner {
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;

    display: flex;
    align-items: center;
    column-gap: 4px;

    &.only-logo {
      justify-content: center;
    }
  }

  &__logo {
    margin-right: 50px;
    width: 158px;
    height: 48px;
    background: url(~assets/images/logo.svg) center/contain no-repeat;

    &.only-logo {
      margin-right: 0;
    }
  }

  &__user {
    display: flex;
    align-items: center;
    column-gap: 4px;
    font-size: 14px;
    white-space: nowrap;
    cursor: pointer;

    &::before {
      content: "";
      width: 32px;
      height: 32px;
      background: url(~assets/images/header/lkIcon.svg) center/contain no-repeat;
      background-size: 22px;
      flex-shrink: 0;
    }

    .skeleton {
      width: 100px;
      height: 12px;
      background: linear-gradient(
          to right,
          rgba(143, 142, 141, 0.75) 0%,
          rgba(237, 235, 233, 0.75) 50%,
          rgba(143, 142, 141, 0.75) 100%
        )
        0px 0px / 100% 100% rgba(243, 242, 241, 0.5);
      background-size: 200% 200%;
      animation: loading 2s ease infinite;
      border-radius: 8px;
    }
  }

  &__auth {
    &-container {
      display: flex;
      align-items: center;
      column-gap: 8px;
      margin-left: auto;
    }

    &-signup {
      max-width: 140px;
      width: 100%;
      padding: 0 24px;
      height: 40px;
      border-radius: 10px;
    }

    &-signin {
      max-width: 100px;
      width: 100%;
      padding: 0 16px;
      height: 40px;
      gap: 8px;
      background-color: $yellow-sub;
      border: 2px solid $yellow-sub;
      border-radius: 10px;

      .button-with-icon {
        &__icon {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  &__notifications {
    height: 32px;
    gap: 4px;
    font-size: 14px;
    background: none;
    border: none;

    .button-with-icon {
      &__icon {
        width: 32px;
        height: 32px;
        background-size: 22px !important;
      }
    }
  }

  &__link {
    position: relative;
    padding: 4px 16px;
    display: flex;
    align-items: center;
    font-size: 14px;

    &.active {
      background-color: $gray;
      border-radius: 24px;
    }

    &-icon {
      margin-right: 4px;
      width: 28px;
      height: 28px;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &__support {
    margin-right: 8px;
    display: flex;
    align-items: center;
    column-gap: 4px;
    font-size: 14px;
    cursor: pointer;

    &::before {
      content: "";
      width: 32px;
      height: 32px;
      background: url(~assets/images/header/supportIcon.svg) center/contain
        no-repeat;
      background-size: 23px;
      flex-shrink: 0;
    }
  }
}
