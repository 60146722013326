@use "../../../../utils.scss" as *;

.project-setup {
  margin-top: 8px;
  padding-left: 20px;
  display: flex;
  gap: 10px;

  &__vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    &-icon {
      width: 24px;
      height: 22px;
      background: url(~assets/images/cogicongray.svg) center no-repeat;
      flex-shrink: 0;
    }

    &-line {
      width: 2px;
      height: 100%;
      border-left: 2px solid #e6e6e6;
    }
  }

  &__container {
    padding: 18px 24px;
    width: 100%;
  }

  &__title {
    margin-bottom: 16px;
    font-weight: 700;
  }

  &__config {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;

    .preloader {
      max-width: 410px;
      min-width: 410px;
      width: 100%;
      max-height: 185px;
      min-height: 185px;
      height: 100%;

      &.other-size {
        max-width: 590px;
        min-width: 590px;
        width: 100%;
        max-height: 290px;
        min-height: 290px;
        height: 100%;
      }
    }
  }

  &__settings {
    padding: 8px;
    flex: 1;

    &-list-rules {
      padding: 24px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      background: $gray;
      border-radius: 8px;

      & p {
        margin-bottom: 4px;
        font-weight: 700;
        line-height: 120%;
        text-align: center;
      }

      & li {
        padding: 0 24px;
        font-size: 14px;
        line-height: 120%;
        color: $lightgreen;
        align-items: center;

        &::before {
          content: "";
          margin-top: 0;
          width: 16px;
          height: 16px;
          background: url(~assets/images/checkgreen.svg) center/contain
            no-repeat;
          flex-shrink: 0;
        }
      }
    }
  }

  &__buttons {
    margin-bottom: 12px;
    padding-bottom: 12px;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 8px;
    border-bottom: 1px solid $blue-dirty;

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }

    &.other-indent {
      margin-bottom: 24px;
      padding-bottom: 24px;
    }
  }

  &__button {
    &-setting-up {
      max-width: 300px;
      height: 38px;
      font-size: 14px;
      background-color: $yellow-sub;
      border: 2px solid $yellow-sub;
      border-radius: 8px;

      &.other-position {
        position: absolute;
        bottom: 7px;
        left: 8px;
        max-width: 526px;
        background-color: $yellow-main;
        border: 2px solid $yellow-main;

        &:hover {
          background-color: $white;
        }

        &.other-width {
          max-width: 575px;
        }
      }
    }

    &-cancel {
      padding: 0;
      max-width: 200px;
      width: 100%;
      height: 44px;
      background-color: $gray;
      border: 2px solid $gray;
      border-radius: 8px;
    }

    &-save {
      padding: 0;
      width: 100%;
      height: 44px;
      border-radius: 8px;
    }
  }

  .avito-upload-file {
    display: contents;
  }
}
