@use "../../../../utils.scss" as *;

.info {
  width: 100%;

  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  color: $gray-dark;

  & p {
    font-size: 14px;
    line-height: 120%;
  }
}
