@use "./utils.scss" as *;

.app-with-footer {
  display: flex;
  flex-direction: column;

  min-height: calc(100dvh - $headerheight);

  &__content {
    flex-grow: 1;
  }
}
