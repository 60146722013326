@use "../../utils.scss" as *;

.main {
  margin: 0 auto;
  max-width: 1044px;
  width: 100%;

  &__header {
    margin-bottom: 16px;
    padding: 32px 24px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;

    @media screen and (max-width: 766px) {
      margin-bottom: 0;
      padding: 32px 16px;
      flex-direction: column;
      gap: 18px;
    }

    &-description {
      &-title {
        position: relative;
        margin-bottom: 16px;

        h1,
        p {
          display: inline;
          font-size: 34px;
          font-weight: 700;
          line-height: 120%;

          @media screen and (max-width: 766px) {
            margin-bottom: 12px;
            font-size: 24px;
            line-height: 120%;
          }
        }
      }

      &-text {
        font-size: 14px;
        line-height: 140%;
        color: #121212;

        @media screen and (max-width: 766px) {
          font-size: 12px;
          line-height: 120%;
        }
      }

      &-highlighted {
        position: absolute;
        top: 0;
        left: -15px;
        z-index: -1;
        width: 350px;
        height: 40px;
        background-color: $yellow-main;
        border-radius: 20px;

        @media screen and (max-width: 766px) {
          left: -10px;
          width: 248px;
          height: 30px;
          border-radius: 16px;
        }
      }
    }

    .intro-performers {
      padding: 40px 12px 30px;
      max-width: 400px;
      gap: 6px;

      @media screen and (max-width: 766px) {
        padding: 20px 32px;
        min-height: auto;
        flex-direction: row;
        border-radius: 32px;

        &__people {
          height: auto;

          &_description {
            margin-left: 4px;
            flex: 1;

            &-title,
            & span,
            &-text {
              font-size: 12px;
              line-height: 120%;
              text-align: center;
            }

            &-text {
              margin-top: 0;
            }
          }
        }
      }
    }
  }

  &__container {
    margin-top: 72px;
    margin-bottom: 72px;
    display: flex;
    flex-direction: column;
    gap: 72px;

    @media screen and (max-width: 766px) {
      margin-top: 56px;
      margin-bottom: 56px;
      gap: 56px;
    }
  }
}
