.page-error {
  margin: 90px auto;
  max-width: 370px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 766px) {
    max-width: 250px;
    margin-top: 70px;
  }

  &__title {
    font-size: 160px;
    font-weight: 800;
    line-height: 191px;
    letter-spacing: 11px;

    @media screen and (max-width: 766px) {
      font-size: 108px;
      line-height: 129px;
    }
  }

  &__subtitle {
    line-height: 19px;
    text-align: center;
  }

  .button {
    margin-top: 40px;
    width: 100%;
    height: 46px;
    border-radius: 10px;
  }
}
