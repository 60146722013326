@use "../../../../utils.scss" as *;

.main__services {
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: $white;
  border-radius: 24px;

  @media screen and (max-width: 766px) {
    margin: 0 8px;
    padding: 8px 8px 16px;
    gap: 16px;
    border-radius: 16px;
  }

  &_platforms {
    padding: 16px 32px;
    display: flex;
    align-items: center;
    gap: 18px;
    background: linear-gradient(
      88deg,
      rgba(101, 226, 235, 0.2) 0.77%,
      rgba(241, 238, 67, 0.2) 99.33%
    );
    border-radius: 16px;

    @media screen and (max-width: 766px) {
      position: relative;
      padding: 0;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      background: $white;
    }

    &-market {
      min-height: 103px;
      flex: 1;
      display: flex;
      justify-content: space-around;
      align-items: center;
      gap: 16px;

      @media screen and (max-width: 766px) {
        padding: 12px 8px;
        width: 100%;
        min-height: 106px;
        justify-content: flex-start;
        gap: 6px;
        background: linear-gradient(
          88deg,
          rgba(101, 226, 235, 0.2) 0.77%,
          rgba(241, 238, 67, 0.2) 99.33%
        );
        border-radius: 12px;
        -webkit-mask-image: linear-gradient(
          to right,
          black 96%,
          transparent 100%
        );
        mask-image: linear-gradient(to right, black 96%, transparent 100%);

        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    &-items {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;
      cursor: pointer;

      @media screen and (max-width: 766px) {
        min-width: 66px;
      }
    }

    &-item-container {
      position: relative;
      max-width: 68px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;
    }

    &-logo {
      position: relative;
      width: 52px;
      height: 52px;
      display: flex;
      justify-content: center;
      align-items: center;
      -webkit-filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.12));
        -moz-filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.12));
      filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.12));

      @media screen and (max-width: 766px) {
        width: 42px;
        height: 42px;
      }

      &-disabled {
        margin-bottom: 5px;
        width: 34px;
        height: 34px;
        opacity: .7;

        @media screen and (max-width: 766px) {
          width: 30px;
          height: 30px;
        }
      }
    }

    &-title {
      font-size: 11px;
      font-weight: 500;
      color: #4f4f4f;
      text-align: center;

      &-active {
        font-weight: 700;
      }
    }

    &-content {      
      margin: 0 auto;
      text-align: center;

      font-size: 16px;
      @media screen and (max-width: 766px) {
        font-size: 14px;
      }
      font-weight: 600;

      & i {
        display: block;
        padding: 8px 8px;
        margin: 8px -8px;
        background-color: $yellow-main;
        border-radius: 8px;
        font-size: 120%;
      }

      &-more {
        font-size: 12px;
        font-weight: 300;
      }
    }
  }
}
