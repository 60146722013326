@use "../../../../../../utils.scss" as *;

.avito-step2 {
  &__notices-container {
    padding-left: 18px;
    padding-right: 18px;

    font-size: 14px;
    line-height: 140%;
    color: $gray-dark;

    @media screen and (max-width: 766px) {
      display: none;
    }

    & li {
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        min-width: 5px !important;
        width: 5px !important;
        height: 5px !important;
        background-color: $gray-dark;
      }
    }
  }
}
