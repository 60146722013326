@use "../../../../../utils.scss" as *;

.market-step1-mobile {
  &__link_settings {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &-screen {
      &.error {
        border-radius: 10px;
        border: 2px solid $red;
        &:focus {
          border-color: $red;
        }
      }
    }

    .faqed {
      margin-bottom: 8px;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    font-size: 14px;

    &::before {
      content: "";
      display: block;
      background-color: $yellow-main;
      min-width: 8px;
      width: 8px;
      height: 8px;
      margin-right: 16px;
      border-radius: 50%;
    }
  }

  &__note {
    margin-top: 8px;
    margin-left: 26px;
    margin-bottom: 8px;

    font-size: 12px;
    color: $gray-dark;

    & span {
      font-weight: 700;
    }
  }
}
