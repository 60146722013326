@use "../../utils.scss" as *;

.referral {
  margin-top: 40px;

  @media screen and (max-width: 766px) {
    margin-top: 0;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    gap: 32px;

    @media screen and (max-width: 766px) {
      gap: 0;
    }
  }

  &__information {
    &-container {
      display: flex;
      gap: 16px;

      @media screen and (max-width: 766px) {
        margin-bottom: 16px;
        flex-direction: column;
        gap: 8px;
      }
    }

    &-steps {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;

      @media screen and (max-width: 766px) {
        padding: 0 16px;
      }

      .how-it-works-steps {
        padding: 16px 24px 24px 24px;
        border-radius: 32px;

        @media screen and (max-width: 766px) {
          padding: 16px;
          border-radius: 16px;
        }

        &:not(.third-step) {
          flex: 1;
          height: auto;
        }
      }
    }
  }

  &__button-open {
    margin: 16px 16px 24px;
    padding: 24px 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: $white;
    border-radius: 16px;
    box-shadow: $box-shadow-main;

    &-text {
      padding: 0 12px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 700;
      cursor: pointer;
      transition: all 0.3s;

      &::after {
        content: "";
        width: 15px;
        height: 6px;
        background: url(~assets/images/accordion.svg) center/contain no-repeat;
        transition: all 0.3s;
      }

      &.other-color {
        color: #ababab;

        &::after {
          background: url(~assets/images/accordiongray.svg) center/contain
            no-repeat;
          transform: rotate(180deg);
        }
      }
    }
  }

  &__disclaimer {
    padding: 16px;
    font-size: 10px;
    line-height: 120%;
    color: #939393;
  }

  @media screen and (max-width: 766px) {
    .answers {
      margin: 56px 16px 64px;
    }
  }
}
