@use "../../../../../../../utils.scss" as *;

.bots-calculator {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__container {
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background:
      linear-gradient(
        257deg,
        rgba(255, 255, 255, 0) 1.1%,
        rgba(255, 255, 255, 0.8) 38.95%,
        rgba(255, 255, 255, 0) 98.19%
      ),
      #e3f5f8;
    border-radius: 16px;

    @media screen and (max-width: 766px) {
      padding: 16px;
    }

    &-top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: 766px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
      }
    }
  }

  &__info {
    font-weight: 700;

    &-text {
      margin-bottom: 4px;
      font-size: 14px;
    }

    &-sum {
      font-size: 32px;
      line-height: 110%;

      @include hold-height-if-empty;

      @media screen and (max-width: 766px) {
        font-size: 24px;
      }
    }
  }

  &__price {
    &-equipment {
      padding: 4px 8px;
      font-size: 13px;
      font-style: italic;
      line-height: 120%;
      color: #477e47;
      background:
        linear-gradient(
          257deg,
          rgba(255, 255, 255, 0) 1%,
          rgba(255, 255, 255, 0.6) 39%,
          rgba(255, 255, 255, 0) 98%
        ),
        rgba(172, 236, 165, 0.3);
      border-radius: 16px;

      @media screen and (max-width: 766px) {
        width: 100%;
        font-size: 12px;
        text-align: center;
      }

      & span {
        font-weight: 500;
      }

      &.paid {
        padding: 6px 8px;
        text-align: center;

        @media screen and (max-width: 766px) {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          gap: 16px;
        }

        & p {
          margin-bottom: 4px;

          @media screen and (max-width: 766px) {
            margin-bottom: 0;
          }

          &:last-child {
            margin-bottom: 0;
            color: #4f4f4f;
          }
        }
      }

      &-paid {
        display: flex;
        align-items: center;
        gap: 4px;

        @media screen and (max-width: 766px) {
          flex-direction: column;
        }

        & div {
          padding: 3px 4px 2px;
          font-weight: 600;
          line-height: 140%;
          color: rgba(66, 180, 89, 1);
          display: flex;
          align-items: center;
          gap: 4px;
          background:
            linear-gradient(
              0deg,
              rgba(161, 235, 169, 0.4) 0%,
              rgba(161, 235, 169, 0.4) 100%
            ),
            #fff;
          border-radius: 8px;

          @media screen and (max-width: 766px) {
            min-width: 120px;
            justify-content: center;
          }
        }
      }
    }

    &-total {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 700;
    }
  }

  &__extend-button {
    padding: 0;
    height: auto;
    font-size: 12px;
    font-weight: 500;
    line-height: 120%;
    color: #57c058;
    text-decoration-line: underline;
    text-underline-position: from-font;
    background-color: inherit;
    border: none;

    &:hover {
      background-color: inherit !important;
      opacity: 0.7;
    }
  }

  &__budget {
    padding: 12px 14px;
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid #d7e2e4;
    border-radius: 16px;

    &-icon {
      width: 22px;
      height: 22px;
      flex-shrink: 0;

      @media screen and (max-width: 766px) {
        width: 18px;
        height: 18px;
      }
    }

    .budget-slider__children-container {
      & p {
        font-size: 18px;
        font-weight: 600;
        color: #4f4f4f;
      }
    }

    .budget-slider__slider {
      &-container {
        margin-top: 0;
        padding: 0;
        border: none;
      }
    }
  }

  &__checked {
    display: flex;
    justify-content: center;

    .check-box__title {
      font-size: 13px;
      font-weight: 600;
    }

    .faq {
      margin-left: 10px;
    }
  }
}
