.projects-drafts-abstract-row {
  position: relative;
  margin-bottom: 10px;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  background: #FDFCF4;
  border-radius: 12px;

  @media screen and (max-width: 766px) {
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
}
