@use "../../../utils.scss" as *;

.article {
  margin: 32px auto 0;
  max-width: $screenminwidth;
  width: 100%;

  &__container {
    margin-top: 64px;
    margin-bottom: 74px;

    display: flex;
    gap: 15px;
  }
}

.text-block {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__img2 {
    margin-bottom: 24px;

    display: flex;
    gap: 10px;
  }
}

.text-block__definition {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.img {
  max-width: 343px;
  width: 100%;
  height: 360px;
  border-radius: 8px;
}