$smallduration: 0.3s;
$headerheight: 64px;

$screenmaxwidth: 1400px;
$screenminwidth: 1170px;

$tabletscreenmaxwidth: 1169px;
$tabletscreenminwidth: 765px;

$mobilescreenmaxwidth: 764px;
$mobilescreenminwidth: 320px;

$white: #ffffff;
$black: #1e1e1e;
$black-dark: #303030;
$light-black: #4F4F4F;
$black-disabled: #707070;

$orange: #F3901C;
$orange-light: #FEB700;

$yellow-dark: #EEB010;
$yellow-main: #FFE14B;
$yellow-sub: #F2F1ED;
$yellow-light: #fff5cb;
$yellow-blond: #FFFBE6;

$green: #37ac38;
$green-success: #01BE02;
$lightgreen: #57C058;

$blue-light: #f1f9fd;
$blue-dirty: #d7e2e4;

$gray: #f9f9fb;
$gray-light: #FDFDFD;
$gray-deep-dark: #9e9e9e;
$gray-toggler: #cecece;
$gray-dark: #ABABAB;
$gray-disabled: #F0F0F0;

$red: #c84933;
$red-light: #fc3f1d;
$red-failed: #EA4335;
$pink-dark: #ff7373;

$box-shadow-main: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
$box-shadow-light: 0 4px 8px 0 rgba(0, 0, 0, 0.04);
$box-shadow-hover: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
$box-shadow-popup: 0 21px 42px -17px rgba(0, 47, 67, 0.15);
$box-shadow-places: 0 11px 23px -9px rgba(0, 47, 67, 0.15);
$box-shadow-activity-mobile: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);

@mixin hold-height-if-empty {
  &:empty::before {
    content: '';
    display: inline-block;
  }
}
