@use "../../../../utils.scss" as *;

.form-step {
  &__container {
    margin-bottom: 16px;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    border-bottom: 1px solid #d7e2e4;

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border: none;
    }

    & label {
      color: $black;
      cursor: default;
    }

    .textinput {
      padding: 0 16px;

      &__container {
        margin-top: 12px;

        @media screen and (max-width: 766px) {
          margin-top: 8px;
        }
      }
    }

    .react-dadata {
      &__container {
        margin-top: 12px;

        @media screen and (max-width: 766px) {
          margin-top: 8px;
        }
      }

      &__input {
        padding: 0 16px;
      }
    }
  }

  &__title {
    font-size: 14px;

    @media screen and (max-width: 766px) {
      font-size: 12px;
    }
  }
}
