@use "../../../../utils.scss" as *;

.market-step1-plate {
  padding: 32px 40px;
  width: 100%;

  @media screen and (max-width: 766px) {
    padding: 32px 8px;
    border-radius: 16px;
  }
}

.banner-third-step {
  margin-top: 16px;

  @media screen and (max-width: 766px) {
    margin-top: 0;
  }
}
