.text-block {
  &__title {
    font-size: 16px;
    font-weight: 700;

    margin-bottom: 8px; 

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__span {
    font-weight: 400;
  }
}
