@use "../../../../../utils.scss" as *;

.project-title {
  padding: 5px 8px 5px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  background: rgba(249, 249, 251, 0.2);
  border-radius: 8px;
  box-shadow: $box-shadow-light;
  cursor: pointer;

  @media screen and (max-width: 766px) {
    padding: 8px;
  }

  &__icon {
    margin-left: 16px;
    margin-right: 16px;
    width: 12px;
    height: 12px;
    flex-shrink: 0;
    transition: transform 0.5s;
    cursor: pointer;

    &.expanded {
      transform: rotate(180deg);
    }
  }

  &__container {
    max-width: 265px;
    min-width: 265px;
    width: 100%;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    background: rgba(249, 249, 251, 0.20);
    border-radius: 8px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);

    @media screen and (max-width: 766px) {
      padding: 3px 4px;
      max-width: 190px;
      min-width: 190px;
      justify-content: space-between;
      gap: 4px;
      font-size: 14px;
    }

    p:first-child {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  
    p:last-child {
      flex-shrink: 0;
    }
  }
}
