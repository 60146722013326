@use "../../../../utils.scss" as *;

.form-step3 {
  &__title {
    font-weight: 700;

    @media screen and (max-width: 766px) {
      text-align: center;
    }
  }

  &__checkboxes {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .check-box {
      margin-bottom: 0;

      @media screen and (max-width: 766px) {
        &__mark {
          width: 16px;
          height: 16px;
        }

        &__title {
          font-size: 14px;
        }
      }
    }
  }
}
