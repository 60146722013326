@use "../../../utils.scss" as *;

.banner {
  padding: 0 24px;
  display: flex;
  align-items: center;
  gap: 72px;
  background: linear-gradient(
      89deg,
      rgba(115, 144, 242, 0.14) -0.82%,
      rgba(115, 144, 242, 0.14) -0.81%,
      rgba(115, 144, 242, 0.1) 45.92%,
      rgba(115, 144, 242, 0.14) 60.58%
    ),
    $gray;
  border-radius: 24px;

  @media screen and (max-width: 766px) {
    padding: 8px 8px 16px;
    flex-direction: column;
    gap: 0;
  }

  &.other-position {
    flex-direction: row-reverse;
  }

  &.banner-third-step {
    padding: 0 32px;
    gap: 50px;
    background: linear-gradient(
        89deg,
        rgba(254, 212, 0, 0.14) -0.82%,
        rgba(254, 212, 0, 0.14) -0.81%,
        rgba(254, 212, 0, 0.1) 45.92%,
        rgba(254, 212, 0, 0.14) 100%
      ),
      $gray;

    @media screen and (max-width: 766px) {
      padding: 8px 8px 16px;
      gap: 0;
    }
  }

  &.banner-budget {
    margin-bottom: 16px;
    padding: 16px 10px 16px 24px;
    gap: 8px;

    @media screen and (max-width: 766px) {
      display: none;
    }
  }

  &.banner-modal {
    margin-bottom: 16px;
    padding: 12px 8px 12px 16px;
    gap: 8px;
  }

  &.banner-project-left,
  &.banner-project-right {
    padding: 0;
    flex: 1;
    gap: 8px;
    border-radius: 16px;
  }

  &.banner-project-right {
    background: linear-gradient(
        0deg,
        rgba(29, 147, 210, 0.14) 0%,
        rgba(93, 196, 239, 0.14) 100%
      ),
      #f9f9fb;
  }

  &__image {
    width: 100%;
    height: auto;
    object-fit: contain;

    &.banner-first-step {
      margin: 0 52px;
      max-width: 256px;
      min-height: 181px;

      @media screen and (max-width: 766px) {
        margin: 0;
        max-width: 344px;
        min-height: 106px;
      }
    }

    &.banner-second-step {
      max-width: 360px;
      min-height: 200px;

      @media screen and (max-width: 766px) {
        max-width: 344px;
        min-height: 106px;
      }
    }

    &.banner-third-step {
      max-width: 635px;
      min-height: 251px;

      @media screen and (max-width: 766px) {
        max-width: 344px;
        min-height: 106px;
      }
    }

    &.banner-budget {
      max-width: 112px;
      height: 71px;

      @media screen and (max-width: 766px) {
        display: none;
      }
    }

    &.banner-modal {
      max-width: 84px;
      height: 56px;

      @media screen and (max-width: 766px) {
        width: 67px;
        height: 67px;
      }
    }

    &.banner-project-left,
    &.banner-project-right {
      max-width: 150px;
      min-height: 150px;

      @media screen and (max-width: 766px) {
        display: none;
      }
    }
  }

  &__container {
    padding: 24px;
    flex: 1;
    border-radius: 16px;

    &.banner-budget {
      padding: 16px;

      @media screen and (max-width: 766px) {
        display: none;
      }
    }

    &.banner-modal {
      padding: 8px 12px;
      border-radius: 12px;
    }

    &.banner-project-left {
      margin: 16px 24px 16px 0;
      padding: 16px;
    }

    &.banner-project-right {
      margin: 16px 0 16px 24px;
      padding: 16px;
    }
  }
}
