@use "../../../../../../utils.scss" as *;

.main__action-link {
  position: relative;
  padding: 14px 18px 16px;
  max-height: 95px;
  min-height: 95px;
  display: flex;
  align-items: center;
  background: $white;
  border: 1px solid $gray-disabled;
  border-radius: 24px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
  overflow: hidden;

  @media screen and (max-width: 766px) {
    padding: 16px 10px;
    max-height: 110px;
    min-height: 110px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 8px;
    border-radius: 16px;
  }

  &:hover {
    background: #FCFCFC;
    border: 1px solid #FFE14B;
  }

  &.animated {
    .main__action-link-icon {
      opacity: .1;
      filter: brightness(0);
    }

    .main__action-link-label,
    .main__action-link-sublabel {
      color: rgba(30, 30, 30, 0.1);
    }
  }

  .badge {
    position: absolute;
    top: 0;
    right: 0;
    padding: 3px 32px 4px 16px;
    height: 16px;
    font-size: 9px;
    font-style: italic;
    color: $white;
    text-transform: uppercase;
    border-radius: 0px 0px 0px 7px;

    &__new {
      background: linear-gradient(87deg, #de43b1 0.65%, #ef6193 85.14%);
    }

    @media screen and (max-width: 766px) {
      padding: 2px 24px 4px 16px;
    }
  }

  &-info {
    display: flex;
    align-items: center;
    gap: 12px;

    @media screen and (max-width: 766px) {
      gap: 8px;
    }
  }

  &-icon {
    width: 46px;
    height: 46px;
    flex-shrink: 0;

    @media screen and (max-width: 766px) {
      width: 36px;
      height: 36px;
    }
  }

  &-label {
    font-weight: 600;
    line-height: 120%;

    @media screen and (max-width: 766px) {
      font-size: 12px;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &-sublabel {
    font-size: 12px;
    font-weight: 300;
    line-height: 120%;

    @media screen and (max-width: 766px) {
      font-size: 10px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @media screen and (max-width: 766px) {
    &-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 4px;
    }
  }
}
