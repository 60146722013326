.test-package-notice {
  padding: 18px 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: linear-gradient(
      257deg,
      rgba(255, 255, 255, 0) 1.1%,
      rgba(255, 255, 255, 0.8) 38.95%,
      rgba(255, 255, 255, 0) 98.19%
    ),
    #f7f2dd;
  border-radius: 24px;

  @media screen and (max-width: 766px) {
    margin-top: -24px;
    margin-bottom: 16px;
    padding: 16px;
    gap: 4px;
    border-radius: 20px;
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 6px;

    &-icon {
      padding: 10px 10px 10px 0;
      width: 50px;
      height: 50px;
      flex-shrink: 0;
    }

    & p {
      font-size: 22px;
      font-weight: 700;
      line-height: 120%;

      @media screen and (max-width: 766px) {
        font-size: 18px;
      }
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    gap: 8px;

    & p {
      font-size: 14px;
      line-height: 120%;

      &:last-child {
        & span {
          font-style: normal;
        }
      }

      & span {
        font-style: italic;
        font-weight: 700;
      }
    }
  }
}
