@use "../../../utils.scss" as *;

.projects-archive {
  &-row {
    margin-bottom: 16px;

    &:first-child {
      margin-top: 24px;
    }

    &:last-child {
      margin-bottom: 0;
      padding-top: 0;
    }

    &:not(:first-child) {
      padding-top: 0;
    }
  }

  & > div:first-child {
    .projects-archive__row {
      margin-top: 0;
    }
  }

  &__row {
    padding-bottom: 16px;
    margin-top: 30px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    border-bottom: 1px solid $blue-dirty;

    &-header {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &-title {
      font-size: 16px;
      font-weight: 700;
      color: $gray-deep-dark;
    }

    &-accordion-icon {
      margin-right: 16px;

      width: 12px;
      height: 12px;

      background: url(~assets/images/arrowDownGray.svg) center/contain
        no-repeat;
      flex-shrink: 0;
      transition: transform 0.2s;

      &.expanded {
        transform: rotate(180deg);
      }
    }

    &-reports {
      height: 0;
      overflow: hidden;

      &.expanded {
        height: auto;
      }
    }
  }

  &__no-results {
    margin-top: 24px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }
}
