@use "../../../../utils.scss" as *;

.other-articles {
  background-color: $white;

  &__container {
    height: 160px;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__info {
    padding: 8px 16px 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &-button {
      padding: 0 16px;
      max-width: max-content;
      height: 30px;
      gap: 8px;
      font-size: 12px;
      line-height: 120%;
      background-color: $yellow_sub;
      border: 2px solid $yellow_sub;

      .button-with-icon__icon {
        order: 2;
        width: 20px;
        height: 20px;
      }
    }
  }

  &__type {
    padding: 7px 12px;

    width: fit-content;
    height: 30px;

    border-radius: 7px;
    background-color: $gray;

    font-size: 12px;
    line-height: 140%;
    color: $gray-dark;
  }

  &__title {
    height: 90px;

    font-size: 24px;
    font-weight: 800;
    line-height: 120%;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
}
