.bot-columns {
  width: 100%;
  min-height: 160px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 52px;

  @media screen and (max-width: 766px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px 8px;
  }

  .project-property-column__key-phrases {
    cursor: pointer;
  }
}