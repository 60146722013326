@use "../../utils.scss" as *;

.budget-slider {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;

  &__children-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__slider {
    &-container {
      padding: 12px 14px;
      border: 1px solid $blue-dirty;
      border-radius: 16px;

      @media screen and (max-width: 766px) {
        margin-top: 6px;
        padding: 0;
        border: none;
      }
    }

    &-info {
      font-size: 14px;
      font-weight: 600;
      line-height: 120%;
      text-align: center;

      @media screen and (max-width: 766px) {
        margin-top: 4px;
        margin-bottom: 8px;
        font-size: 12px;
        line-height: 110%;
      }
    }
  }

  &__create-button {
    @media screen and (max-width: 766px) {
      margin-bottom: 0;
      padding: 0;
      max-width: 200px;
      width: 100%;
      height: 34px;
      color: $black;
    }
  }

  .slider {
    margin: 12px 0 14px;
    width: 100%;

    @media screen and (max-width: 766px) {
      margin: 0 0 10px;
    }
  }

  &__limits-container {
    font-size: 14px;
    color: $gray-dark;
    display: flex;
    justify-content: space-between;
  }

  &__limit {
    &::after {
      content: "\00A0 ₽";
    }

    &.without-rub {
      &::after {
        content: "";
      }
    }
  }
}
