@use "../../../../../../utils.scss" as *;

.projects-active-report-row {
  &__container {
    margin-left: 10px;
    padding: 8px 0 8px 10px;
    border-left: 2px solid #E6E6E6;

    @media screen and (max-width: 766px) {
      margin-left: 0;
      padding: 0;
      border: none;
    }

    &.opacity {
      opacity: .4;

      @media screen and (max-width: 766px) {
        opacity: .6;
    }
    }
  }

  &__chains {
    padding: 16px 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 8px;
    background-color: $gray;
    border-radius: 16px;

    @media screen and (max-width: 766px) {
      padding: 16px 8px;
      flex-wrap: nowrap;
      gap: 2px;
      -webkit-overflow-scrolling: touch;
      overflow-x: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;
    }

    &-item {
      padding: 8px 18px;
      width: fit-content;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.5);
      background: $white;
      border-radius: 16px;

      @media screen and (max-width: 766px) {
        width: max-content;
        height: auto;
        text-wrap: nowrap;
      }

      &.with-border {
        border: 1px solid $yellow-main;
      }

      &-img {
        width: 15px;
        height: 15px;

        @media screen and (max-width: 766px) {
          width: 16px;
          height: 16px;
        }
  
      }
    }
    
    &-dash {
      height: 3px;
      width: 16px;
      background: url(~assets/images/dash.svg) center no-repeat;
      background-size: 16px 3px;
    }
  }
}