@use "../../../../utils.scss" as *;

.main__about {
  position: relative;
  height: 542px;
  overflow: hidden;

  @media screen and (max-width: 766px) {
    height: auto;
    display: flex;
    flex-direction: column;
  }

  &-title {
    padding: 48px 0 0 24px;
    font-size: 40px;
    font-weight: 600;

    @media screen and (max-width: 766px) {
      padding: 0;
      padding-bottom: 32px;
      height: auto;
      font-size: 32px;
      text-align: center;
      background-color: $gray;
    }

    & span {
      color: #feb700;
    }
  }

  &-text {
    position: absolute;
    right: 88px;
    bottom: 56px;
    max-width: 560px;
    width: 100%;
    font-size: 20px;
    line-height: 140%;

    @media screen and (max-width: 766px) {
      position: static;
      padding: 32px 16px 0;
      font-size: 16px;
      background-color: $gray;
    }

    & > p:first-child {
      margin-bottom: 16px;

      @media screen and (max-width: 766px) {
        margin-bottom: 24px;
      }
    }

    & span {
      font-weight: 700;
    }
  }

  &_bg {
    position: absolute;
    inset: 0;
    z-index: -1;

    width: 100%;
    height: 100%;
    background: radial-gradient(160% 60% at 48% 49%, #dedede 0%, #efefef 100%);
    border-radius: 48px;

    @media screen and (max-width: 766px) {
      position: static;
      min-height: 268px;
      border-radius: 24px 24px 0 0;
      overflow: hidden;
    }

    &-image {
      position: absolute;
      top: 15%;
      left: 25px;
      width: 100%;
      height: auto;
      object-fit: cover;
      transition: transform 0.1s linear;

      @media screen and (max-width: 766px) {
        top: 22%;
        left: 0;
        height: 100%;
      }
    }

    &-decore1 {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 380px;
      height: 208px;
      background-color: $gray;
      border-radius: 48px 0 48px 0;

      @media screen and (max-width: 766px) {
        height: 0;
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 48px;
        height: 48px;
        background-image: url(~assets/images/skruglenie-up.svg);
        background-size: cover;

        @media screen and (max-width: 766px) {
          content: none;
        }
      }

      &::before {
        top: 0;
        left: 100%;
      }

      &::after {
        top: 100%;
        left: 0;
      }
    }

    &-decore2 {
      position: absolute;
      width: 712px;
      height: 276px;
      right: 0;
      bottom: 0;
      z-index: 0;
      background-color: $gray;
      border-radius: 48px 0 48px 0;

      @media screen and (max-width: 766px) {
        height: 268px;
        border-radius: 0;
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 48px;
        height: 48px;
        background-image: url(~assets/images/skruglenie-up.svg);
        background-size: cover;
        transform: rotate(-180deg);

        @media screen and (max-width: 766px) {
          content: none;
        }
      }

      &::before {
        right: 100%;
        bottom: -1px;
      }

      &::after {
        top: -48px;
        right: -1px;
      }
    }
  }
}
