@use "../../../../utils.scss" as *;

.report-modal {
  &__answers-container {
    background: rgba(254, 212, 0, 0.05);
    border-radius: 8px;
    border: 1px solid $yellow-main;
    box-shadow: $box-shadow-main;

    p {
      color: $black;
    }

    &-empty {
      background: rgba(234, 67, 53, 0.05);
      border: 1px solid #EA4335
    }
  }

  &-empty-answer {
    color: $pink-dark !important;
    font-weight: 700;
    text-align: center;
  }
}


