@use "../../../utils.scss" as *;

.intro-performers {
  padding: 24px 40px;
  max-width: 460px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 48px;
  box-shadow: $box-shadow-main;
  z-index: 1;

  @media screen and (max-width: 766px) {
    padding: 16px;
    min-width: 169px;
    min-height: 170px;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 24px;
  }

  &__bots {
    &-img {
      max-width: 171px;
      height: 54px;
      object-fit: contain;

      @media screen and (max-width: 766px) {
        max-width: 90px;
        height: 28px;
      }
    }

    &_description {
      text-align: center;

      @media screen and (max-width: 766px) {
        text-align: left;
      }

      &-title {
        font-weight: 700;
        line-height: 120%;

        @media screen and (max-width: 766px) {
          font-size: 22px;
          line-height: 70%;

          & span {
            font-size: 16px;
          }
        }
      }

      &-text {
        line-height: 120%;

        @media screen and (max-width: 766px) {
          margin-top: 6px;
          font-size: 11px;
          font-weight: 300;
          color: #939393;
        }
      }
    }
  }

  &__people {
    &-img {
      max-width: 171px;
      height: 54px;
      object-fit: contain;

      @media screen and (max-width: 766px) {
        max-width: 90px;
        height: 28px;
      }
    }

    @media screen and (max-width: 766px) {
      width: 89px;
      height: 56px;
    }

    &_description {
      margin-left: auto;

      @media screen and (max-width: 766px) {
        margin-left: 0;
      }

      &-title {
        font-weight: 700;
        line-height: 120%;
        text-align: center;

        @media screen and (max-width: 766px) {
          font-size: 24px;
          line-height: 70%;
          text-align: left;

          & span {
            font-size: 16px;
          }
        }
      }

      &-text {
        line-height: 120%;
        text-align: center;

        @media screen and (max-width: 766px) {
          margin-top: 6px;
          font-size: 12px;
          font-weight: 300;
          color: #939393;
          text-align: left;
        }
      }
    }
  }
}
