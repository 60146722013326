.projects-archive-abstract-row {
  padding: 5px 16px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #EDF5ED;
  border-radius: 12px;

  @media screen and (max-width: 766px) {
    flex-direction: column;
    gap: 12px;
  }
}

