@use "../../../utils.scss" as *;

@keyframes heartPulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes backgroundScroll {
  0% {
    background-position: center 0%;
  }
  50% {
    background-position: center 100%;
  }
  100% {
    background-position: center 0%;
  }
}

.intro-illustration {
  position: relative;
  z-index: 1;
  max-width: 463px;
  width: 100%;
  min-height: 200px;
  background: url(~assets/images/dashboard/intro/illustration/bg.svg) $white
    center/contain no-repeat;
  border-radius: 45px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;

  @media screen and (max-width: 766px) {
    margin: 0 auto;
    max-width: 180px;
    width: 100%;
    min-height: 92px;
    height: 100%;
    border-radius: 12px;
  }

  &.other-background {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 4;
      width: 100%;
      height: 100%;
      background: url(~assets/images/dashboard/intro/illustration/bg-for-views.svg)
        $white center/cover no-repeat;
      filter: blur(2.5px);
      animation: backgroundScroll 10s linear infinite;
    }
  }

  &__slider {
    position: absolute;
    top: 55px;
    left: 85px;
    width: 180px;
    height: 135px;
    overflow: hidden;

    @media screen and (max-width: 766px) {
      top: 28px;
      left: 19%;
      width: 70px;
      height: 52px;
    }
  }

  &__slides {
    position: absolute;
    top: 10px;
    left: -240px;
    display: flex;
    transform: translateX(0);

    @media screen and (max-width: 766px) {
      top: 5px;
      left: -130%;
    }

    &.with-transition {
      transition: transform 2s ease-in-out;
    }

    &-item {
      min-width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      div {
        width: 110px;
        height: 110px;

        @media screen and (max-width: 766px) {
          width: 42px;
          height: 42px;
        }
      }
    }
  }

  &__heart {
    position: absolute;
    top: 57px;
    right: 88px;
    z-index: 2;

    @media screen and (max-width: 766px) {
      top: 28%;
      right: 22%;
    }

    &-hovered,
    &-clicked,
    &-default,
    &-done {
      width: 40px;
      height: 40px;
      transition: all 0.5s ease-in-out;

      @media screen and (max-width: 766px) {
        width: 20px;
        height: 20px;
      }
    }

    &-hovered {
      background: url(~assets/images/dashboard/intro/illustration/like-hover.svg)
        center/contain no-repeat;
      transition-delay: 0.2s;
    }

    &-clicked {
      background: url(~assets/images/dashboard/intro/illustration/like-pressed.svg)
        center/contain no-repeat;
      animation: heartPulse 0.4s ease-in-out;
    }

    &-default {
      background: url(~assets/images/dashboard/intro/illustration/like-disabled.svg)
        center/contain no-repeat;
    }

    &-done {
      background: url(~assets/images/dashboard/intro/illustration/like-hover.svg)
        center/contain no-repeat;
    }
  }

  &__cursor {
    position: absolute;
    z-index: 3;
    width: 70px;
    height: 69px;
    background: url(~assets/images/dashboard/intro/illustration/cursor.svg)
      center/contain no-repeat;
    transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);

    @media screen and (max-width: 766px) {
      width: 27px;
      height: 27px;
    }

    &.cursor-start {
      right: 29px;
      bottom: 39px;

      @media screen and (max-width: 766px) {
        right: 12px;
        bottom: 12px;
      }
    }

    &.cursor-heart {
      right: 38px;
      bottom: 56px;

      @media screen and (max-width: 766px) {
        right: 20px;
        bottom: 28px;
      }
    }

    &.cursor-done {
      right: 29px;
      bottom: 39px;
      transition-delay: 0.3s;

      @media screen and (max-width: 766px) {
        right: 12px;
        bottom: 12px;
      }
    }
  }

  &__overlay {
    width: 100%;
    height: 100%;

    &-icon {
      position: absolute;
      top: 64px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 5;
      width: 150px;
      height: 80px;
      background: url(~assets/images/dashboard/intro/info-eye.svg)
        center/contain no-repeat;
      flex-shrink: 0;

      @media screen and (max-width: 766px) {
        top: 24px;
        width: 73px;
        height: 49px;
      }
    }
  }
}
