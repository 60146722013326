@use "../../../utils.scss" as *;

.accordion {
  overflow: hidden;

  &__title {
    padding: 24px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;

    @media screen and (max-width: 766px) {
      padding-top: 12px;
      padding-bottom: 12px;
      font-size: 14px;
    }

    &::after {
      content: '';
      background: url(~assets/images/accordion.svg) center/contain no-repeat;
      width: 20px;
      height: 8px;
      flex-shrink: 0;
    }
  }

  &__text {
    height: 0;
    box-sizing: content-box;
    transition: all 0.5s;
  
    border-bottom: 1px solid $blue-dirty;

    font-size: 14px;
  }
}

.accordion_expanded {
  & .accordion__title::after {
    transform: rotate3d(1, 0, 0, 180deg);
    transition: all 0.5s;
  }
}
