@use "../../utils.scss" as *;

.activity-row {
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  background-color: rgba(249, 249, 251, 0.4);

  @media screen and (max-width: 766px) {
    padding: 12px 4px;
    flex-direction: column;
    gap: 8px;
    background-color: $white;
    border: 2px solid $gray;
    border-radius: 16px;
    box-shadow: $box-shadow-activity-mobile;
  }

  &__wrapper {
    margin-bottom: 4px;

    @media screen and (max-width: 766px) {
      margin-bottom: 8px;
    }

    &:first-child {
      min-height: 52px;
    }

    .faqed {
      align-items: center;
      margin: 20px 0;

      @media screen and (max-width: 766px) {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }

  &__title {
    padding: 0;
    max-width: 308px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    white-space: pre-wrap;

    @media screen and (max-width: 766px) {
      padding: 0 4px;
      max-width: none;
      font-size: 12px;
    }

    & span {
      font-size: 14px;
      color: $gray-dark;

      @media screen and (max-width: 766px) {
        font-size: 12px;
      }
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }

  &__fields {
    position: relative;
    max-width: 450px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &-label {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: 766px) {
        gap: 16px;
      }

      &-title {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.12px;
        color: $gray-dark;
      }

      &-button {
        position: relative;
        padding: 6px 16px;
        height: 30px;
        display: flex;
        align-items: center;
        gap: 4px;
        background: linear-gradient(
          90deg,
          rgba(212, 214, 255, 0.8) 0%,
          rgba(255, 195, 242, 0.8) 100%
        );
        border-radius: 8px;
        overflow: hidden;
        transition: opacity 0.5s;

        @media screen and (max-width: 766px) {
          padding: 8px;
        }

        &:hover {
          opacity: .7;
        }

        &-icon {
          z-index: 1;
          width: 15px;
          height: 15px;
          flex-shrink: 0;
          mix-blend-mode: color-burn;
        }

        &-text {
          z-index: 1;
          font-size: 12px;
          line-height: 140%;
          mix-blend-mode: color-burn;

          @media screen and (max-width: 766px) {
            font-size: 10px;
            line-height: normal;
            text-align: center;
          }
        }
      }
    }

    .textinput {
      padding: 12px;

      @media screen and (max-width: 766px) {
        background-color: $white;
        border-radius: 8px;
      }

      &::placeholder {
        font-size: 12px;
        opacity: 0.45;
      }
    }

    .hint-review-notification {
      left: 0;
      bottom: 140px;
      z-index: 9;
      font-size: 14px;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(234, 67, 53, 0.05));
      -webkit-backdrop-filter: blur(12px);
      backdrop-filter: blur(12px);
      border-radius: 16px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);

      .hint__button {
        padding: 8px 0;
        height: 38px;
        background: #4F4F4F;
        color: #ffffff;
      }
    }
  }
}

.activity-row_disabled {
  .activity-row__title {
    border-color: transparent;
  }
}

.textarea {
  padding: 12px 8px;
  width: 100%;
  min-height: 130px;
  outline: $yellow-main;
  background-color: $gray;
  border: 1px solid $yellow-main;
  border-radius: 8px;
  overflow: hidden;

  &::placeholder {
    font-family: "SF";
    font-size: 12px;
    opacity: 0.65;
  }

  @media screen and (max-width: 766px) {
    background-color: $white;
    border-radius: 8px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media screen and (max-width: 766px) {
      font-size: 12px;
    }

    .check-box {
      margin-bottom: 8px;
      padding: 8px 24px 12px 16px;
      max-width: 450px;
      width: 100%;

      border-radius: 8px;
      background: linear-gradient(
          45deg,
          rgba(254, 212, 0, 0.05) 0%,
          rgba(254, 212, 0, 0.05) 0.01%,
          rgba(254, 212, 0, 0.05) 46.35%,
          rgba(254, 212, 0, 0.05) 100%
        ),
        #f9f9fb;

      @media screen and (max-width: 766px) {
        justify-content: center;
      }

      &__mark {
        border: 2px solid $yellow-main;
      }

      &__title {
        &-container {
          margin-bottom: 5px;
          display: flex;
          align-items: center;
          gap: 4px;

          @media screen and (max-width: 766px) {
            margin-bottom: 0;
          }
        }

        &-warranty {
          &-icon {
            padding: 4px;
            width: 22px;
            height: 22px;
            background: url(~assets/images/warranty.svg) center no-repeat;
            background-size: 18px;
            flex-shrink: 0;
          }

          &-text {
            margin-left: 24px;
            font-size: 14px;

            @media screen and (max-width: 766px) {
              display: none;
            }
          }
        }
      }
    }
  }

  &__error-message {
    font-size: 14px;
    color: $red;

    @media screen and (max-width: 766px) {
      font-size: 12px;
    }
  }

  &.error {
    border: 2px solid $red;
    &:focus {
      border-color: $red;
    }
  }
}
