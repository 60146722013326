.project-settings-mobile {
  position: fixed;
  left: 0;
  bottom: 0;
  margin: auto 0 0;
  padding: 36px 16px 44px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 16px 16px 0 0;
  box-shadow: none;

  &__title {
    font-weight: 700;
  }

  .popup__header {
    margin-bottom: 8px;
    width: 100%;
    justify-content: center;

    &-title {
      font-size: 18px;
    }
  }

  .popup__button-close {
    display: none;
  }

  .button-with-icon {
    height: 40px;
    justify-content: flex-start;
    gap: 8px;
    background-color: inherit;
    border: none;

    &__icon {
      width: 32px;
      height: 32px;
      background-color: rgba(242, 241, 237, 0.3) !important;
      background-size: 22px !important;
      border-radius: 8px;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
    }
  }
}
