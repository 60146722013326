@use "../../../../utils.scss" as *;

.info-section {
  margin-bottom: 16px;
  display: flex;

  &__title {
    margin-bottom: 8px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 8px;

    @media screen and (max-width: 766px) {
      font-size: 14px;
    }

    &::before {
      content: "";
      display: block;
      width: 5px;
      height: 5px;
      background-color: $yellow-main;
      border-radius: 50%;
    }
  }

  &__text {
    @media screen and (max-width: 766px) {
      font-size: 14px;
      line-height: 140%;
    }
  }
}

.comment-section {
  flex-direction: column;
}

.timer-section {
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 766px) {
    flex-direction: column-reverse;
    gap: 16px;
  }
}
