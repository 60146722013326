@use "../../../utils.scss" as *;

.dropdown-links {
  &__logos {
    padding: 16px;
    width: 100%;
    height: fit-content;
    background: $gray;
    border-radius: 16px;
    transition: all 0.4s;

    @media screen and (max-width: 766px) {
      padding: 0;
      max-width: none;
      border-radius: 0;
    }

    &_header {
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: 766px) {
        padding: 8px 8px 0;
      }

      &-title {
        font-weight: 700;

        @media screen and (max-width: 766px) {
          text-align: center;
        }
      }

      &-buttons {
        padding: 4px;
        width: fit-content;
        display: flex;
        gap: 8px;
        background-color: $gray-disabled;
        border-radius: 8px;
      }

      &-button-row {
        width: 28px;
        height: 28px;
        background: url(~assets/images/header/menu/displayRow.svg) center
          no-repeat;
        border-radius: 8px;

        &.active {
          background: url(~assets/images/header/menu/displayRowActive.svg)
            #e0e0df center no-repeat;
        }
      }

      &-button-column {
        width: 28px;
        height: 28px;
        background: url(~assets/images/header/menu/displayColumn.svg) center
          no-repeat;
        border-radius: 8px;

        &.active {
          background: url(~assets/images/header/menu/displayColumnActive.svg)
            #e0e0df center no-repeat;
        }
      }

      &-button-grid {
        width: 28px;
        height: 28px;
        background: url(~assets/images/header/menu/displayGrid.svg) center
          no-repeat;
        border-radius: 8px;

        &.active {
          background: url(~assets/images/header/menu/displayGridActive.svg)
            #e0e0df center no-repeat;
        }
      }
    }

    &-items {
      display: flex;
      column-gap: 8px;
      row-gap: 14px;

      &.format {
        &-row {
          @media screen and (max-width: 766px) {
            padding-bottom: 12px;
            flex-wrap: nowrap;
            -webkit-mask-image: linear-gradient(
              to right,
              transparent 0%,
              black 5%,
              black 95%,
              transparent 100%
            );
            mask-image: linear-gradient(
              to right,
              transparent 0%,
              black 5%,
              black 95%,
              transparent 100%
            );
            -webkit-overflow-scrolling: touch;
            overflow: auto;
            scrollbar-width: none;
            -ms-overflow-style: none;

            &::-webkit-scrollbar {
              width: 0;
              height: 0;
              display: none;
            }

            &:last-child {
              padding-left: 5px;
              padding-right: 5px;
            }
          }
        }

        &-grid {
          padding-left: 8px;
          padding-right: 8px;
          flex-wrap: wrap;
        }

        &-column {
          padding-left: 8px;
          padding-right: 8px;
          flex-direction: column;
        }
      }
    }

    &-link {
      width: 54px;
      height: 54px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-grid {
        width: 54px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &-column {
        margin-bottom: 8px;
        padding-bottom: 8px;
        display: flex;
        align-items: center;
        gap: 8px;
        border-bottom: 1px solid $gray-disabled;
      }
    }

    &-item {
      width: 40px;
      height: 40px;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 8px;
      box-shadow: 0px 5px 9px 0px rgba(0, 0, 0, 0.12);
      flex-shrink: 0;

      &-grid {
        width: 40px;
        height: 40px;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 8px;
        box-shadow: 0px 5px 9px 0px rgba(0, 0, 0, 0.12);
        flex-shrink: 0;
      }

      &-column {
        width: 24px;
        height: 24px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 8px;
        box-shadow: 0px 5px 9px 0px rgba(0, 0, 0, 0.12);
      }
    }

    &-title {
      margin-top: 4px;
      font-size: 8px;
      align-items: center;
      text-align: center;

      &-grid {
        margin-top: 4px;
        font-size: 8px;
        text-align: center;
      }

      &-column {
        font-size: 12px;
      }
    }

    &_result-search {
      margin: 0 auto;
      color: $yellow-dark;
    }
  }

  &__market-group {
    &_title {
      margin-bottom: 8px;
      width: 100%;
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 9px;
      font-weight: 700;
      color: $gray-dark;
      text-wrap: nowrap;
      white-space: nowrap;

      & span {
        width: 100%;
        height: 1px;
        border-top: 1px solid $blue-dirty;
      }
    }

    &_format {
      &-row {
        display: flex;
      }

      &-grid {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        @media screen and (max-width: 766px) {
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
