@use "../../utils.scss" as *;

.notification-line {
  &__failed {
    background: #ffedc0;

    &-container {
      padding: 4px 0;
      display: flex;
      align-items: center;

      @media screen and (max-width: 766px) {
        padding: 22px 12px 8px;
        flex-direction: column;
        gap: 14px;
      }
    }

    &-message {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      line-height: 120%;
      color: #ec860e;

      @media screen and (max-width: 766px) {
        padding: 0 8px;
        text-align: center;
      }

      &::before {
        content: "";
        width: 18px;
        height: 18px;
        background: url(~assets/images/attentionNotification.svg) center
          no-repeat;

        @media screen and (max-width: 766px) {
          background: none;
        }
      }
    }

    &-button-close {
      width: 24px;
      height: 24px;
      background: url(~assets/images/closeNotification.svg) center/18px
        no-repeat;
      border: none;
      flex-shrink: 0;

      &:not(:disabled):hover {
        background-color: #ffedc0;
      }
    }
  }

  &__update {
    background: #def4de;

    &-container {
      padding: 6px 0;
      display: flex;
      align-items: center;

      @media screen and (max-width: 766px) {
        padding: 8px 20px 14px;
        flex-direction: column;
        gap: 24px;
      }
    }

    &-message {
      flex: 1;
      display: flex;
      justify-content: center;
      gap: 16px;

      @media screen and (max-width: 766px) {
        flex-direction: column;
        align-items: center;
        gap: 24px;
      }

      &-title {
        padding: 4px 8px;
        max-width: max-content;
        font-size: 13px;
        font-weight: 600;
        line-height: 120%;
        color: #f9f9fb;
        background: #8ad68a;
        border-radius: 8px;
      }

      &-text {
        color: #57c058;

        @media screen and (max-width: 766px) {
          padding: 0 8px;
          text-align: center;
        }
      }
    }

    &-buttons {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &-button-do,
    &-button-close {
      padding: 0 16px;
      max-width: max-content;
      height: 32px;
      border-radius: 8px;

      @media screen and (max-width: 766px) {
        height: 44px;
        border-radius: 16px;
      }
    }

    &-button-do {
      color: $white;
      background: #57c058;
      border: 2px solid #57c058;

      @media screen and (max-width: 766px) {
        order: 2;
      }

      &:hover {
        color: $black;
      }
    }

    &-button-close {
      color: #57c058;
      background: #c1e0c2;
      border: 2px solid #c1e0c2;

      @media screen and (max-width: 766px) {
        order: 1;
      }
    }
  }
}
