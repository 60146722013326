@use "../../utils.scss" as *;

.header-mobile {
  &__inner {
    &.only-logo {
      padding-top: 8px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__container {
    &-up {
      position: relative;
      z-index: 10;
      padding: 12px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: $box-shadow-main;

      &_buttons {
        display: flex;
        gap: 8px;

        &-signup {
          padding: 0 14px;
          width: 100%;
          height: 32px;
          font-size: 14px;
          border-radius: 10px;
        }
      }

      &_button {
        height: 32px;
        gap: 4px;
        font-size: 12px;
        background-color: $yellow-sub;
        border: 2px solid $yellow-sub;
        border-radius: 8px;

        &.button-support {
          padding: 4px 8px 4px 6px;
          max-width: 116px;

          .button-with-icon {
            &__icon {
              width: 14px;
              height: 14px;
            }
          }
        }

        &.button-bot {
          padding: 6px 8px;
          border-radius: 10px;

          .button-with-icon {
            &__icon {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }

    &-down {
      position: fixed;
      left: 0;
      bottom: 0;
      z-index: 10;

      padding: 4px 8px 8px;
      width: 100%;
      height: 66px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      background-color: $white;
      box-shadow: 0px -2px 8px 0px #0000001f;

      &.together {
        justify-content: space-evenly;
      }
    }

    &-buttons {
      padding-top: 8px;
      display: flex;
      justify-content: space-between;

      & a {
        display: contents;
      }
    }
  }

  &__logo {
    width: 112px;
    height: 20px;
    background: url(~assets/images/logo-mobile.svg) center/contain no-repeat;
  }

  &__link {
    padding: 4px 8px;
    width: 64px;
    height: 54px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    font-size: 11px;

    &:hover {
      background-color: $yellow-sub;
      border-radius: 16px;
    }

    &.active {
      background-color: $yellow-sub;
      border-radius: 16px;
    }

    &-icon {
      width: 28px;
      height: 28px;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &__button {
    &-signin {
      margin-right: 8px;
      padding: 0;
      max-width: 160px;
      width: 100%;
      height: 40px;
      background-color: $yellow-sub;
      border: 2px solid $yellow-sub;
      border-radius: 10px;

      &:hover {
        background-color: $white;
      }
    }
  }
}
