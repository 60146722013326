@use "../../../../../../../utils.scss" as *;

.projects-active-report-auction-cancel {
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;

  &__warn {
    padding: 8px 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    background: rgba(255, 168, 67, 0.05);
    border-radius: 40px;

    &-icon {
      width: 31px;
      height: 31px;
      background: url(~assets/images/messagewarn.svg) center no-repeat;
      flex-shrink: 0;
    }

    & p {
      font-weight: 700;
      color: $orange;
    }
  }

  &__button {
    max-width: 355px;
    height: 40px;
    gap: 6px;
    font-weight: 700;
    color: $red-failed;
    background-color: rgba(234, 67, 53, 0.05);
    border: 2px solid rgb(247 238 234);

    &:hover {
      background-color: $white;
      border: 2px solid rgba(234, 67, 53, 0.5);
    }
  }
}