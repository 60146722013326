@use "../../../utils.scss" as *;

.intro-animation {
  padding: 20px 40px;
  position: relative;
  z-index: 1;
  max-width: 460px;
  width: 100%;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 48px;
  box-shadow: $box-shadow-main;
  overflow: hidden;

  @media screen and (max-width: 766px) {
    padding: 16px 12px;
    min-width: 169px;
    min-height: 170px;
    flex: 1;
    border-radius: 24px;
  }

  &__info {
    padding: 6px 24px;
    display: flex;
    align-items: center;
    gap: 16px;
    background: rgba(242, 241, 237, 0.6);
    border-radius: 16px;

    @media screen and (max-width: 766px) {
      padding: 8px;
      flex-direction: column;
      align-items: start;
      gap: 0;
    }

    & p {
      font-size: 25px;
      font-weight: 700;
      line-height: 120%;

      @media screen and (max-width: 766px) {
        font-size: 18px;
        line-height: normal;
      }
    }

    & span {
      font-size: 14px;

      @media screen and (max-width: 766px) {
        font-size: 12px;
        font-weight: 300;
        line-height: 120%;
      }
    }
  }

  &__lottie {
    position: absolute;
    top: 0;
    left: -100px;
    right: 0;
    bottom: 0;
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(6) scaleX(-1);

    @media screen and (max-width: 766px) {
      left: 45%;
    }

    > div {
      width: 100%;
      height: 100%;
    }
  }
}
