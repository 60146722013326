@use "../../../../../utils.scss" as *;

.projects-active-row {
  &__wait-info {
    position: relative;
    z-index: 1;
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 14px 32px;
    height: 200px;
    background-color: $gray;
    border-radius: 8px;

    @media screen and (max-width: 766px) {
      padding: 8px;
      height: 260px;
    }

    &:first-child {
      margin-top: 16px;
    }

    &.dependent-height {
      height: auto;
    }

    &-icon {
      position: absolute;
      top: 25%;
      left: 32px;
      z-index: -1;
      height: 83px;
      width: 83px;
      background: url(~assets/images/wait-tasks.svg) center/contain no-repeat;

      @media screen and (max-width: 766px) {
        top: 20%;
        left: 8px;
      }
    }

    &-lines-container {
      margin-left: 48px;

      @media screen and (max-width: 766px) {
        margin-left: 16px;
        font-size: 12px;
      }
    }

    &-first-line {
      font-weight: 700;
     
      @media screen and (max-width: 766px) {
        font-size: 16px;
        font-weight: 600;
        line-height: 140%;
      }
    }
  }
}
