@use "../../../utils.scss" as *;

.textinput {
  padding: 0 28px;
  width: 100%;
  height: 54px;

  outline: $yellow-main;
  background-color: $gray;
  border: 2px solid $gray;
  border-radius: 10px;
  transition: border-color 0.3s;
  touch-action: pan-y;

  @media screen and (max-width: 766px) {
    padding: 0 16px;
    height: 42px;
    font-size: 14px;
  }

  &:focus {
    border: 2px solid $yellow-main;
  }

  &::placeholder {
    opacity: 0.35;
    color: inherit;

    @media screen and (max-width: 766px) {
      font-size: 14px;
    }
  }

  &__container {
    position: relative;
    width: 100%;
  }

  &__error {
    position: absolute;
    top: -15px;
    left: 26px;
    color: $red;
    font-size: 12px;
    line-height: 14px;
    font-weight: 300;
  
    & + .textinput {
      border: 2px solid $red;
      &:focus { border-color: $red }
    }
  }
}

.textinput__container.nohighlite {
  .textinput {
    &:focus {
      border: 2px solid transparent;
    }
  }
}

.textinput.questions {
  height: 33px;
  padding: 8px 16px;
  font-size: 14px;

  &::placeholder {
    font-size: 14px;
  }
}

.textinput__error.questions {
  top: -15px;
  left: 0;
}