@use "../../../../utils.scss" as *;

.referral__link {
  padding: 32px;
  width: 100%;

  @media screen and (max-width: 766px) {
    margin: 0 16px;
    padding: 24px 16px;
    width: auto;
    border-radius: 16px;
  }

  &-description {
    margin-bottom: 24px;
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-bottom: 1px solid $blue-dirty;

    @media screen and (max-width: 766px) {
      margin-bottom: 16px;
      padding-bottom: 16px;
      border-bottom: none;
    }
  }

  &-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 120%;
  }

  @media screen and (max-width: 766px) {
    &-subtitle {
      font-size: 14px;
      line-height: 120%;
    }
  }

  &-container {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &-item {
    padding: 12px 16px;
    flex: 1 0;
    font-size: 14px;
    line-height: 120%;
    color: $gray-dark;
    background-color: $yellow-sub;
    border-radius: 8px;

    @include hold-height-if-empty;

    @media screen and (max-width: 766px) {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &-icon {
    width: 44px;
    height: 44px;
    background: url(~assets/images/copy.svg) $yellow-sub center no-repeat;
    border-radius: 8px;
    flex-shrink: 0;
    cursor: pointer;
  }
}
