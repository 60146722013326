.time {
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
  justify-self: end;

  padding: 4px 6px;
  max-width: 100px;
  width: 100%;
  height: 30px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);

  &-img {
    width: 18px;
    height: 18px;
    background: url(~assets/images/time.svg) center no-repeat;
  }

  &-text {
    font-size: 14px;
    font-weight: 700;
    line-height: 140%;
  }
}
