@use "../../../../../../../../utils.scss" as *;

.mark {
  padding: 4px 16px;
  font-size: 14px;
  color: $gray-dark;
  border-radius: 16px;

  @media screen and (max-width: 766px) {
    padding: 4px;
  }

  &.active {
    font-weight: 700;
    color: $black;
    background-color: $white;
    box-shadow: $box-shadow-main;
  }
}
