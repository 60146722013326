@use "../../../../../utils.scss" as *;

.footnote {
	max-width: 700px;
	width: 100%;

	padding: 32px 24px;
	border-radius: 8px;
	background-color: $yellow-sub;

	&-text {
		font-size: 20px;
		font-style: italic;
		line-height: 140%;
		text-align: center;

    & span {
      font-weight: 700;
    }
	}
}