@use "../../../utils.scss" as *;

.tooltips {
  position: relative;
  pointer-events: none;

  &__description {
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 1;
    padding: 5px 10px;
    font-size: 14px;
    text-align: left;
    text-wrap: nowrap;
    white-space: nowrap;
    background-color: $white;
    border-radius: 16px;
    box-shadow: $box-shadow-main;

    @media screen and (max-width: 766px) {
      left: 75%;
      text-wrap: wrap;
      white-space: normal;
    }

    &::after { 
      content: '';
      position: absolute;
      top: 36%;
      left: -8px;
      transform: translateX(-50%);
      width: 13px;
      height: 9px;
      background: url(~assets/images/triangle.svg) center no-repeat;
      transform: rotate(90deg);

      @media screen and (max-width: 766px) {
        top: 16px;
      }
    }
  }
}
