.project-full-screenshot {
  width: 80vw;
  height: 70vh;

  & img {
    width: 100%;
    height: 100%;
    display: block;
    -o-object-fit: contain;
    object-fit: contain;
  }
}