@use "../../utils.scss" as *;

@keyframes loading {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.bot-modal {
  padding: 32px 40px;
  max-width: 574px;

  @media screen and (max-width: 766px) {
    margin: 0;
    margin-top: auto;
    padding: 16px 8px 32px;
    border-radius: 16px 16px 0px 0px;
  }

  .popup {
    @media screen and (max-width: 766px) {
      &__button-close {
        display: none;
      }

      &__header-title {
        padding-left: 8px;
        padding-right: 8px;
        text-align: left;
      }
    }
  }

  &__container {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media screen and (max-width: 766px) {
      gap: 24px;
    }
  }

  &__info {
    &_points {
      &-top {
        padding: 10px;
        background: rgba(238, 176, 16, 0.05);
        border-radius: 24px;

        @media screen and (max-width: 766px) {
          padding: 14px 10px;
          background: linear-gradient(
              0deg,
              rgba(238, 176, 16, 0.05) 0%,
              rgba(238, 176, 16, 0.05) 100%
            ),
            #f9f9fb;
        }

        .info-point {
          border-bottom: 2px solid #f5edd5;

          &:last-child {
            border-bottom: none;
          }

          &__icon {
            width: 32px;
            height: 32px;
          }
        }
      }

      &-title {
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 500;
        color: #939393;

        @media screen and (max-width: 766px) {
          padding-left: 8px;
          padding-right: 8px;
          font-size: 12px;
          line-height: 100%;
          letter-spacing: 0.12px;
        }
      }

      &-bottom {
        padding: 16px 10px;
        background: $gray;
        border-radius: 24px;

        @media screen and (max-width: 766px) {
          padding: 14px 10px;
        }

        .info-point {
          &__icon {
            width: 30px;
            height: 24px;
          }
        }
      }

      &-item {
        padding-bottom: 12px;
        display: flex;
        align-items: center;
        gap: 8px;
        border-bottom: 2px solid #f0f0f0;

        @media screen and (max-width: 766px) {
          flex-wrap: wrap;
        }

        &-icon {
          width: 30px;
          height: 24px;
          background: url(~assets/images/botIcon/number-1.svg) center/contain no-repeat;
        }

        @media screen and (max-width: 766px) {
          &-text {
            font-size: 14px;
          }
        }

        &-code {
          margin-left: 8px;
          padding: 10px 16px;
          max-width: 280px;
          width: 100%;
          height: 52px;
          display: flex;
          align-items: center;
          background: #f9f9fb;
          border: 1px solid #fed400;
          border-radius: 29px;

          @media screen and (max-width: 766px) {
            margin-left: 0;
            max-width: none;
          }

          &-text {
            min-width: 200px;
            flex: 1 0 0;
            font-size: 24px;
            font-weight: 700;
            line-height: 120%;
            text-align: center;
          }

          &-icon {
            width: 32px;
            height: 32px;
            background: url(~assets/images/copyYellow.svg) center no-repeat;
            flex-shrink: 0;
            cursor: pointer;
          }
        }
      }
    }
  }

  &__buttons {
    border-top: 1px solid $gray-disabled;
  }

  &__button-bot {
    margin-top: 16px;
    gap: 10px;
    font-weight: 700;
    line-height: 140%;
    color: $white;
    background: linear-gradient(0deg, #1d93d2 0%, #5dc4ef 100%), #38b0e3;
    border: none;

    @media screen and (max-width: 766px) {
      height: 48px;
    }

    &:hover {
      opacity: 0.7;
    }

    .button-with-icon {
      &__icon {
        width: 28px;
        height: 28px;
      }
    }
  }
}
