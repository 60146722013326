@use "../../../../utils.scss" as *;

.project-property-column-reviews {
  max-width: 225px;

  &.finished {
    color: rgba(0, 0, 0.12, 0.5);
  }

  &__title {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
  }

  &__icon {
    margin-right: 4px;
    padding: 4px;
    width: 24px;
    height: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 17px;
    flex-shrink: 0;
  }

  &__description {
    margin-top: 16px;
    font-size: 14px;
    display: flex;
    cursor: pointer;

    @media screen and (max-width: 766px) {
      margin-top: 8px;
      flex-direction: column;
      gap: 8px;
    }

    &.status-approved {
      font-weight: 700;
      color: $green;
    }

    &.status-rework,
    &.status-failed {
      font-weight: 700;
      color: $red-failed;
    }

    &.status-new,
    &.status-run,
    &.status-ready,
    &.status-replace {
      font-weight: 700;
    }
  }

  &__text {
    display: flex;
    align-items: center;
  }

  &__request {
    position: absolute;
    top: -10px;
    left: 12%;
    transform: translateX(-12%);
    z-index: 11;

    padding: 16px;
    max-width: 290px;
    min-width: 290px;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 8px;
    box-shadow: $box-shadow-main;

    background: $white;
    cursor: auto;

    &-title {
      margin-bottom: 6px;
      font-weight: 700;
    }
  }

  &__warranty {
    position: relative;

    margin-left: 8px;
    padding: 2px 8px;
    width: 100px;
    height: 32px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;

    border-radius: 28px;
    cursor: pointer;

    @media screen and (max-width: 766px) {
      margin-left: 0;
    }

    &.end {
      background-color: $gray-disabled;
    }
    &.one-day {
      background: rgba(234, 67, 53, 0.05);
    }
    &.two-days {
      background: rgba(254, 212, 0, 0.05);
    }
    &.more {
      background: rgba(87, 192, 88, 0.1);
    }
    &.no-warranty {
      background-color: $gray-disabled;
      cursor: default;
    }

    &-icon {
      padding: 4px;
      width: 28px;
      height: 28px;
      background-size: 20px;
      flex-shrink: 0;

      &.end {
        background: url(~assets/images/warranty/warranty-end.svg) center
          no-repeat;
      }
      &.one-day {
        background: url(~assets/images/warranty/warranty-one-day.svg) center
          no-repeat;
      }
      &.two-days {
        background: url(~assets/images/warranty/warranty-two-days.svg) center
          no-repeat;
      }
      &.more {
        background: url(~assets/images/warranty/warranty-more.svg) center
          no-repeat;
      }
      &.no-warranty {
        background: url(~assets/images/warranty/no-warranty.svg) center
          no-repeat;
      }
    }

    &-text {
      &.end {
        font-weight: 700;
        color: $gray-dark;
      }
      &.one-day {
        font-weight: 700;
        color: $red-failed;
      }
      &.two-days {
        font-weight: 700;
        color: $yellow-dark;
      }
      &.more {
        font-weight: 700;
        color: $lightgreen;
      }
      &.no-warranty {
        color: $gray-dark;
      }
    }
  }

  .hint-project-property-column-reviews {
    top: 0;
    left: 200px;
    z-index: 11;

    font-size: 14px;
    background: $white;

    .hint__button {
      height: 42px;
      background-color: $yellow-sub;
    }
  }
}
