.first-article {
  grid-column: 1/3;

  &__container {
    padding: 16px 16px 26px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__background {
    display: grid;
    grid-template-columns: 500px 1fr;
    grid-template-rows: 154px 154px;
  }

  &__type {
    padding: 7px 12px;

    width: fit-content;
    height: 30px;

    border-radius: 7px;
    background-color: #f9f9fb;

    font-size: 12px;
    line-height: 140%;
    color: #ababab;
  }

  &__title {
    grid-area: 2/1;
    max-width: 464px;
    width: 100%;

    align-self: end;

    @media screen and (max-width: 766px) {
      display: flex;
      flex-direction: column;
      max-width: min-content;
    }

    &-text {
      margin-bottom: 8px;

      font-size: 24px;
      font-weight: 800;
      line-height: 120%;
      color: #fff;
    }

    &-subtext {
      font-size: 14px;
      line-height: 140%;
      color: #fff;

      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
  }
}
