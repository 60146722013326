@use "../../utils.scss" as *;

.warranty-popup {
  max-width: 515px;

  &__info {
    padding-bottom: 30px;
    max-height: 315px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    mask-image: linear-gradient(to bottom, black 80%, transparent 100%);
    overflow: scroll;

    @media screen and (max-width: 766px) {
      gap: 10px;
    }

    &_points {
      padding: 24px 16px;
      background-color: $gray;
      border-radius: 24px;
    }

    &-list {
      line-height: 140%;

      @media screen and (max-width: 766px) {
        margin-bottom: 15px;
      }

      & li {
        margin-bottom: 6px;
        margin-left: 20px;
        display: list-item;
        list-style-type: decimal;

        @media screen and (max-width: 766px) {
          margin-bottom: 4px;
          font-size: 14px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &::before {
          content: none;
          margin-right: 0 !important;
        }
      }
    }
  }

  &__consent-container {
    padding-top: 16px;
    border-top: 2px solid $gray-disabled;

    @media screen and (max-width: 766px) {
      .check-box {
        font-size: 14px;
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  &__support {
    max-width: 200px;
    width: 100%;

    @media screen and (max-width: 766px) {
      font-size: 14px;
    }

    .button {
      padding-left: 40px;
      padding-right: 40px;
      background-color: $yellow-sub;
      border: 2px solid $yellow-sub;
    }
  }

  .button {
    width: 100%;
    height: 54px;

    @media screen and (max-width: 766px) {
      padding: 0;
      height: 40px;
      font-size: 14px;
    }
  }

  .popup__header {
    margin-bottom: 16px;
    justify-content: center;
  }
}
