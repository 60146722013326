@use "../../../../utils.scss" as *;

.main__advantages {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: 766px) {
    gap: 8px;
  }

  &-dino {
    position: absolute;
    top: -114px;
    left: 170px;
    z-index: -1;

    @media screen and (max-width: 766px) {
      top: -84px;
      left: auto;
      right: 48px;
      width: 120px;
      height: 112px;
      transform: rotateY(180deg);
    }
  }

  & p {
    font-size: 32px;
    font-weight: 700;
    line-height: 100%;
  }

  &_small {
    position: relative;
    display: flex;
    align-items: center;
    gap: 16px;

    @media screen and (max-width: 766px) {
      flex-direction: column;
      gap: 8px;
    }

    &-left,
    &-right {
      max-width: 514px;
      width: 100%;
      min-height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $white;
      border-radius: 24px;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
    }

    &-left {
      padding: 30px;

      @media screen and (max-width: 766px) {
        padding: 40px 24px;
        max-width: none;
        display: flex;
        flex-direction: column;
        gap: 50px;
        text-align: start;
      }
    }

    &-right {
      padding: 20px;
      gap: 24px;

      @media screen and (max-width: 766px) {
        padding: 40px 22px;
        max-width: none;
      }

      &-icon {
        width: 58px;
        height: 58px;
        background: url(~assets/images/main/no-bots.svg) center/contain
          no-repeat;
        flex-shrink: 0;

        @media screen and (max-width: 766px) {
          width: 99px;
          height: 99px;
        }
      }
    }
  }

  &_medium {
    padding: 80px 32px;
    max-width: 1044px;
    width: 100%;
    min-height: 220px;
    background: url(~assets/images/main/bg-with-receipt.svg) center/cover
      no-repeat;
    border-radius: 24px;
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.16);

    @media screen and (max-width: 766px) {
      padding: 40px 24px;
      min-height: 289px;
      background: url(~assets/images/main/bg-with-receipt-mob.svg) center/cover
      no-repeat;
    }

    & p {
      margin-bottom: 8px;
    }

    @media screen and (max-width: 766px) {
      & span {
        display: block;
        max-width: 190px;
        width: 100%;
      }
    }
  }

  &_big {
    padding: 46px 32px 40px;
    display: flex;
    flex-direction: column;
    gap: 42px;
    background: #f4f4f4;
    border-radius: 24px;
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.16);
    overflow: hidden;

    @media screen and (max-width: 766px) {
      padding: 40px 24px;
    }

    &-description {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: 766px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 64px;
      }

      &-text {
        font-size: 16px !important;
        font-weight: 400 !important;
        line-height: 140% !important;
      }
    }
  }
}
