@use "../../../../../../../utils.scss" as *;

.humans-calculator {
  padding: 16px 32px 32px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: $white;
  border-radius: 16px;
  box-shadow: $box-shadow-main;

  @media screen and (max-width: 766px) {
    padding: 16px;
    border-radius: 16px 16px 0 0;
    box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.12);
  }

  &__wrapper {
    padding: 16px 24px;
    background: $gray;
    border-radius: 16px;

    @media screen and (max-width: 766px) {
      padding: 12px 16px;
    }

    .budget-slider__children-container {
      flex-direction: row;
    }
  }

  &__title {
    font-weight: 700;
  }

  &__price {
    font-size: 32px;
    font-weight: 700;
    line-height: 110%;

    @media screen and (max-width: 766px) {
      font-size: 22px;
    }
  }

  &__info {
    &-actions,
    &-reviews {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 14px;
      line-height: 120%;

      &-icon {
        width: 22px;
        height: 22px;

        @media screen and (max-width: 766px) {
          width: 18px;
          height: 18px;
        }
      }
    }

    &-reviews {
      margin-top: 8px;

      &.hidden {
        visibility: hidden;
      }
    }
  }
}
