@use "../../../utils.scss" as *;

.paymodal {
  padding: 32px 24px;
  border-radius: 35px;
  max-width: 420px;
  transition: all 0.4s;

  @media screen and (max-width: 766px) {
    margin-left: 16px;
    margin-right: 16px;
    padding: 18px;
    border-radius: 16px;
  }
  
  &__text {
    font-size: 14px;
    line-height: 140%;

    @media screen and (max-width: 766px) {
      line-height: normal;
    }
  }

  &__input-container {
    margin-top: 8px;
    margin-bottom: 8px;

    @media screen and (max-width: 766px) {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }

  &__subtext {
    margin-bottom: 16px;
    font-size: 14px;
    color: $gray-dark;

    @media screen and (max-width: 766px) {
      margin-bottom: 8px;
      line-height: normal;
    }
  }

  &__buttons-container {
    margin-bottom: 12px;
    padding-bottom: 25px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $gray-disabled;

    @media screen and (max-width: 766px) {
      margin-bottom: 16px;
      flex-wrap: wrap;
      gap: 6px;
    }
  }

  &__sum {
    padding: 5px 8px;
    height: 30px;

    font-size: 14px;
    border-radius: 16px;
    background-color: $yellow-sub;

    @media screen and (max-width: 766px) {
      max-width: 70px;
      font-size: 12px;
      border-radius: 8px;
    }
  }

  &__buttons {
    margin-top: 16px;
    display: flex;
    column-gap: 8px;

    & > button {
      padding: 0 20px;

      @media screen and (max-width: 766px) {
        height: 38px;
      }
    }
  }

  &__checkbox-container {
    @media screen and (max-width: 766px) {
      font-size: 13px;

      .check-box__mark {
        width: 20px;
        height: 20px;
      }
    }

    &.disable {
      pointer-events: none;
      opacity: .4;
    }
  }

  &__warning {
    margin-top: 8px;
    font-size: 13px;
    color: $gray-dark;
  }

  .faqed {
    margin-top: 14px;
    justify-content: space-between;
    align-items: center;
  }

  .textinput__container {
    border-radius: 8px;
    background-color: $gray;
  }

  .textinput {
    padding: 8px 16px;
    height: 40px;
    background: url(~assets/images/rub.svg) no-repeat;
    background-position: right 16px center;
    border-radius: 8px;
  }

  .textinput__error {
    top: -14px;
    left: 0;
  }
}
