@use "../../../../../utils.scss" as *;

.project-setup {
  &__links {
    margin-bottom: 20px;
    padding: 7px 8px 7px 18px;
    max-width: 620px;
    display: flex;
    align-items: center;
    gap: 20px;
    background-color: $gray;
    border-radius: 10px;

    &-input {
      min-width: 0;
      width: 100%;
      height: 30px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-link {
      color: $gray-dark;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-icon {
      margin-left: auto;
      width: 30px;
      height: 30px;
      background: url(~assets/images/copy.svg) $yellow-sub center no-repeat;
      border-radius: 8px;
      flex-shrink: 0;
      cursor: pointer;
    }
  }
}