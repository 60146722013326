@use "../../../../../utils.scss" as *;

.property-row {
  &-invited,
  &-withdrawing {
    display: flex;
    align-items: center;
    gap: 24px;
    background: $gray-disabled;
    border-radius: 16px 16px 0 0;
  }

  &-invited {
    .property-row-item {
      &:nth-child(1) {
        flex: 1;
      }

      &:nth-child(2),
      &:nth-child(3) {
        flex: 0.2;
      }
    }
  }

  &-withdrawing {
    .property-row-item {
      &:nth-child(2) {
        flex: 1;
      }

      &:nth-child(1),
      &:nth-child(3),
      &:nth-child(4) {
        flex: 0.6;
      }
    }
  }

  &-item {
    padding: 16px 24px;
    font-size: 14px;
    line-height: 120%;
  }
}
