@use "../../../../../../utils.scss" as *;

.mode-selection {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (max-width: 766px) {
    padding-bottom: 24px;
    gap: 16px;
    border-bottom: 2px solid $gray-disabled;
  }

  &__title {
    font-weight: 700;

    @media screen and (max-width: 766px) {
      text-align: center;
    }
  }

  &__container {
    padding: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
    border: 2px solid $gray;
    border-radius: 36px;

    @media screen and (max-width: 766px) {
      height: auto;
      flex-wrap: wrap;
      justify-content: center;
      border: none;
    }

    &.other-colors {
      background: #fff3b4;
      border: none;

      @media screen and (max-width: 766px) {
        border-radius: 24px;
      }
    }
  }

  &__wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 56px;

    .badge {
      position: absolute;
      top: 1px;
      right: 0;
      z-index: 1;
      padding: 3px 16px;
      height: 16px;
      font-size: 9px;
      font-style: italic;
      color: $white;
      text-transform: uppercase;
      background: linear-gradient(88deg, #0ea7d8 0.77%, #5bc6cc 99.33%);
      border-radius: 0 0 0 7px;

      @media screen and (max-width: 766px) {
        padding: 2px 24px 4px 16px;
      }
    }

    &.has-price {
      position: relative;

      &::after {
        content: "от 1.25 ₽";
        white-space: nowrap;
        position: absolute;
        top: 22px;
        right: 40px;
        font-size: 10px;
        font-style: italic;
        font-weight: 600;
        color: rgba(30, 30, 30, 0.3);

        @media screen and (max-width: 766px) {
          top: 14px;
          right: 90px;
          font-size: 10px;
        }
      }

      .button-with-icon {
        padding-right: 26px;
      }
    }
  }

  &__button {
    width: 100%;
    height: 54px;
    font-size: 18px;
    background-color: $gray;
    border-radius: 56px;

    @media screen and (max-width: 766px) {
      height: 40px;
      font-size: 14px;
    }

    &.other-colors {
      background-color: #fff3b4;
    }

    &.active {
      font-weight: 700;
      background-color: #fff7ce;
      border: none;

      &.other-colors {
        background-color: #fffef9;
        border: 1px solid #ffe14b;
        box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.08);
      }
    }

    & span {
      font-style: italic;
      font-weight: 600;
      color: rgba(30, 30, 30, 0.3);
    }
  }

  &__description {
    padding: 8px;
    font-size: 12px;
    line-height: 120%;
    color: #939393;

    @media screen and (max-width: 766px) {
      padding: 0;
      text-align: center;
    }
  }

  .button-with-icon {
    gap: 8px;

    @media screen and (max-width: 766px) {
      padding-left: 4px;
      padding-right: 4px;
      gap: 4px;
    }

    &__icon {
      width: 16px;
      height: 16px;

      @media screen and (max-width: 766px) {
        width: 12px;
        height: 12px;
      }
    }
  }
}
