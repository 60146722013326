@use "../../../../utils.scss" as *;

.projects-drafts-row {
  @media screen and (max-width: 766px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__title {
    &-container {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 8px;

      
      @media screen and (max-width: 766px) {
        justify-content: space-between;
        gap: 4px;
      }
    }
  }

  &__button-edit {
    padding: 4px;
    width: 22px;
    height: 21px;
    border-radius: 8px;
    background: rgba(242, 241, 237, 0.3);
    box-shadow: $box-shadow-light;
  }

  &__button-mobile-config {
    padding: 11px 18px;
    border-radius: 8px;
    background: rgba(242, 241, 237, 0.3);
    box-shadow: 0px 8.646px 17.292px 0px rgba(0, 0, 0, 0.04);
  }

  &__info {
    display: flex;
    align-items: center;
    gap: 16px;

    @media screen and (max-width: 766px) {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-items: center;
    }

    &-status {
      padding: 4px 10px;
      min-width: 170px;
      width: 100%;
      height: 30px;
      font-weight: 700;
      color: #fed400;
      background-color: $yellow-blond;
      border-radius: 42px;
      text-align: center;

      @media screen and (max-width: 766px) {
        min-width: 160px;
        width: max-content;
      }
    }
  }

  &__buttons {
    margin-left: auto;
    max-width: 230px;
    width: 100%;
    display: flex;
    gap: 8px;

    @media screen and (max-width: 766px) {
      max-width: none;
    }
  }

  &__wrapper {
    width: 40px;
    height: 40px;
    background-color: rgba(234, 67, 53, 0.05);
    border-radius: 37px;

    @media screen and (max-width: 766px) {
      border-radius: 8px;
    }

    &-delete-icon {
      padding: 10px;
      width: 18px;
      height: 18px;
      flex-shrink: 0;

      @media screen and (max-width: 766px) {
        width: 20px;
        height: 20px;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .tooltips {
    font-weight: 400;
    pointer-events: all;
  }

  .button {
    padding: 0 8px;
    max-width: 190px;
    width: 100%;
    height: 40px;
    border-radius: 16px;

    @media screen and (max-width: 766px) {
      max-width: none;
    }
  }
}
