.intro-points-animation {
  display: flex;
  flex-direction: column;
  -webkit-mask-image: linear-gradient(to right, black 70%, transparent 100%);
  mask-image: linear-gradient(to right, black 70%, transparent 100%);
  overflow-x: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }

  &__track {
    display: flex;

    &-step {
      display: flex;
      flex-direction: column;

      &:last-child {
        padding-right: 40px;
      }

      &.passed {
        opacity: 1;
      }
    }
  }

  &__icons {
    margin-bottom: 14px;
    display: flex;
    align-items: center;

    &-plus {
      width: 20px;
      height: 26px;
      background: url(~assets/images/plusInCircleGray.svg) center no-repeat;

      &.active, &.passed {
        width: 26px;
        height: 26px;
        background: url(~assets/images/plusInCircleYellow.svg) center/contain
          no-repeat;
      }
    }

    &-line {
      width: 174px;
      border: none;
      border-bottom: 1px solid #E4E4E4;

      &.active, &.passed {
        border-bottom: 1px solid #FFE14B;

      }
    }
  }

  &__advice-content {
    padding-left: 8px;
    font-size: 14px;
    line-height: 120%;
  }
}
