@use "../../../../utils.scss" as *;

.projects-plate {
  padding: 50px 0;

  @media screen and (max-width: 766px) {
    padding: 24px 0;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-title {
      margin-bottom: 8px;
      font-size: 24px;
      font-weight: 700;
      color: rgba(30, 30, 30, 0.5);

      @media screen and (max-width: 766px) {
        font-size: 18px;
      }
    }

    &-comment {
      font-weight: 500;
      color: rgba(30, 30, 30, 0.5);

      @media screen and (max-width: 766px) {
        font-size: 14px;
        text-align: center;
      }
    }

    .button-with-icon {
      margin-top: 24px;
      max-width: 250px;

      @media screen and (max-width: 766px) {
        margin-top: 16px;
        max-width: none;
      }

      &__icon {
        width: 18px;
        height: 18px;
      }
    }
  }
}
