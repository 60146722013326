@use "../../../../../utils.scss" as *;

.amount-item {
  padding: 12px 16px;
  flex: 1;
  background-color: $gray;
  border-radius: 16px;

  @media screen and (max-width: 766px) {
    padding: 12px;

    &:first-of-type {
      width: 100px;
    }
  }

  .faqed {
    justify-content: space-between;
    align-items: flex-start;
    font-size: 14px;
    line-height: 120%;

    @media screen and (max-width: 766px) {
      font-size: 12px;
      gap: 6px;
    }

    .faq {
      margin-left: 0;
      background: url(~assets/images/faqEmpty.svg) center/contain no-repeat;

      @media screen and (max-width: 766px) {
        width: 24px;
        height: 24px;
      }
    }
  }

  & p {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 18px;
    font-weight: 700;
    line-height: 120%;

    @include hold-height-if-empty;

    &::after {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url(~assets/images/bonus.svg) center/contain no-repeat;
    }

    @media screen and (max-width: 766px) {
      font-size: 16px;
    }
  }
}
