@use "../../../../../../utils.scss" as *;

.key-phrases {
  padding: 40px 24px;
  max-width: 520px;

  @media screen and (max-width: 766px) {
    padding: 18px;
    border-radius: 16px 16px 0 0;
  }

  &__container {
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 8px 16px;
    max-height: 520px;
    font-size: 14px;
    line-height: 140%;
    background: $gray;
    border-radius: 8px;
    overflow: auto;

    @media screen and (max-width: 766px) {
      max-height: 300px;
    }
  }

  &__buttons {
    padding-top: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-top: 1px solid $gray-disabled;

    @media screen and (max-width: 766px) {
      flex-direction: column-reverse;
    }
  }

  &__button-cancel {
    width: 100%;
    background-color: $yellow-sub;
    border: 2px solid $yellow-sub;
  }

  .popup__header {
    flex-direction: column;

    &-subtitle {
      font-size: 24px;
      color: #939393;
    }
  }
}
