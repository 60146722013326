@use "../../utils.scss" as *;

.breadcrumbs {
  margin: 40px auto;
  max-width: 1170px;

  @media screen and (max-width: 766px) {
    display: none;
  }
}

.breadcrumbs > * {
  display: inline-block;
  margin-right: 10px;
}

.breadcrumbs .crumb::after {
  content: '>';
  margin-left: 10px;
}

.breadcrumbs .crumb:last-child::after {
  display: none;
}

.crumb a {
  color: $yellow-main;
  text-decoration: underline;
}