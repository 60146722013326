@use "../../../../../../utils.scss" as *;

.projects-active-report-row {
  &__warn-info {
    margin-top: 4px;
    padding: 24px 32px;
    position: relative;
    height: 131px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(234, 67, 53, 0.05);
    border-radius: 16px;

    @media screen and (max-width: 766px) {
      margin-top: 16px;
      padding: 16px 8px;
      height: auto;
      flex-direction: column;
      gap: 16px;
    }

    &-icon {
      position: absolute;
      top: 20px;
      left: 10px;
      height: 80px;
      width: 80px;
      background: url(~assets/images/warn-for-reports.svg) center/contain
        no-repeat;

      @media screen and (max-width: 766px) {
        top: 25px;
      }
    }

    &-lines-container {
      padding-left: 24px;
      font-size: 18px;
      line-height: 140%;

      @media screen and (max-width: 766px) {
        padding-left: 32px;
        font-size: 12px;
        line-height: 120%;
      }
    }

    &-line {
      &-first {
        font-weight: 600;
        color: $pink-dark;

        @media screen and (max-width: 766px) {
          font-size: 16px;
          line-height: 120%;
        }
      }
    }

    &-timer {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 32px;
      font-weight: 700;
      color: $pink-dark;

      @media screen and (max-width: 766px) {
        gap: 4px;
        font-size: 24px;
      }

      & span {
        font-size: 14px;
      }
    }
  }
}
